<template>
  <div class="system-user-container">
    <el-row :gutter="20">
      <el-col :span="12">
        <DesHeader :title="t('我方渠道')" />
        <ChannelList
          :info="info"
          :params="{ ...params, channelType: 'selfChannel' }"
          :ref-menu-id="refMenuId"
        />
      </el-col>
      <el-col :span="12">
        <DesHeader :title="t('竞对渠道')" />
        <RivalChannelList
          :info="info"
          :params="{ ...params, channelType: 'rivalChannel' }"
          :ref-menu-id="refMenuId"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { defineComponent, onBeforeMount, computed, ref } from 'vue'
  import ChannelList from '@/views/marketing/productChannelList.vue' // 渠道管理
  import RivalChannelList from '@/views/marketing/productChannelRiavlList.vue' // 竞对渠道管理

  import { t } from '@/utils/i18n'
  import {
    PRODUCT_SOURCE_COLOR,
    PRODUCT_SOURCE,
  } from '@/config/variable.config'
  import { fillUrl, downloadIamge } from '@/common/js/utils'
  import { productDesTable } from '@/utils/desTableConfig'
  import { saveAs } from 'file-saver'
  import JSZip from 'jszip'

  const MODULE = 'product' // 模块
  export default defineComponent({
    name: 'ProductDetailBase',
    components: {
      // ProductPriceStrategyList,
      ChannelList,
      RivalChannelList,
    },
    props: {
      params: { type: Object, default: () => {} },
      info: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    setup(props) {
      const refProductPriceStrategyList = ref(null)
      const productInfo = computed(() => {
        return props.params.product || props.info
      })

      // 添加价格策略
      const btnConfig = {
        addProductPricePolicy: {
          type: 'editBox',
        },
        list: ['addProductPricePolicy'],
      }
      const desTableConfig = computed(() => {
        return productDesTable('', props?.params?.type || '')
      })
      // 价格配置
      const desTablePriceConfig = computed(() => {
        return productDesTable('', 'price')
      })

      const priceStrategyParams = computed(() => {
        return {
          from: 'productList',
          refIdKey: 'product_id',
          searchKey: 'product_id',
          typeVal: 1,
        }
      })

      const extraFieldParams = computed(() => {
        return {
          module: MODULE,
        }
      })
      onBeforeMount(() => {})

      const handleDowloadBatch = () => {
        const urlArr = productInfo.value?.slider_image?.split(',')
        const zip = new JSZip()
        const promises = []
        for (let i = 0; i < urlArr.length; i++) {
          const item = urlArr[i]
          if (item) {
            const promise = fetch(item)
              .then((response) => {
                if (!response.ok) {
                  throw new Error('Network response was not ok')
                }
                // 读取响应内容并返回一个 Promise，其中包含响应内容的文本数据

                // return response.arrayBuffer()
                return response.blob()
              })
              .then((data) => {
                const nameType = item.split('.').pop()
                zip.file(`${i}.${nameType}`, data, { binary: true })
              })
            promises.push(promise)
          }
        }
        Promise.all(promises).then(() => {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `${productInfo.value.title}-${t('图集')}`)
          })
        })
      }

      // 导出图片
      const handleDowload = (type) => {
        if (type === 'single') {
          const result = fillUrl(productInfo.value.image)
          console.log('result :>> ', result)
          downloadIamge(result, `${productInfo.value.title}-${t('封面图')}`)
        } else if (type === 'batch') {
          handleDowloadBatch()
        }
      }

      const showCountryText = (data, result = []) => {
        if (data?.parent_info) {
          result.push(data.title)
          showCountryText(data.parent_info, result)
        } else {
          result.push(data?.title)
        }
        return result.join('/')
      }
      // 目标市场
      const showTargetAreaText = (data, result = []) => {
        result = data?.map((item) => {
          return showCountryText(item)
        })
        return result
      }

      const handleHeaderAction = (action, selectionRow, drawerInfo) => {
        console.log('action :>> ', action)
        console.log('selectionRow :>> ', selectionRow)
        console.log('drawerInfo :>> ', drawerInfo)
        if (action.code === 'addProductPricePolicy') {
          console.log(
            'refProductPriceStrategyList :>> ',
            refProductPriceStrategyList
          )
          refProductPriceStrategyList.value.tableRef.jwtTableRef.handleEdit(
            { ...props.info, mode: 1 },
            btnConfig.addProductPricePolicy
          )
        }
      }
      return {
        refProductPriceStrategyList,
        PRODUCT_SOURCE_COLOR,
        PRODUCT_SOURCE,
        productInfo,
        desTableConfig,
        desTablePriceConfig,
        priceStrategyParams,
        extraFieldParams,
        btnConfig,
        fillUrl,
        handleDowload,
        showTargetAreaText,
        t,
        handleHeaderAction,
      }
    },
  })
</script>
<style lang="scss" scoped>
  .system-user-container {
    :deep() {
      .content-wrapper {
        img {
          max-width: 80%;
          max-height: 500px;
          text-align: center;
        }
      }
    }
  }
</style>
