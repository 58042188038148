<!--
 * @Author: WGL
 * @Date: 2024-04-02 11:19:18
 * @LastEditors: WGL
 * @LastEditTime: 2024-09-24 14:29:33
 * @Description:
-->
<template>
  <div
    class="fx mind-node-item"
    :class="[node.children && node.children.length ? 'has-children' : '']"
    :style="currentNodeStyleVar"
  >
    <div
      ref="mindNodeParent"
      class="mind-node-item-parent-wrapper"
      :style="mindNodeParentStyle"
    >
      <div ref="mindNodeText" class="mind-node-item-text">
        {{ node.text }}
      </div>
      <div v-if="node.id === 2" class="mind-node-item-other-children">
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
        <div>88888888</div>
      </div>
      <div v-else-if="node.id === 10" class="mind-node-item-other-children">
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
      </div>
      <div v-else-if="node.id === 8" class="mind-node-item-other-children">
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
        <div>77777777</div>
      </div>
      <div v-else class="mind-node-item-other-children">66666</div>
    </div>
    <div
      v-if="node.children && node.children.length"
      class="mind-node-item-children-wrapper fxm"
      :style="mindNodeChildStyle"
    >
      <template v-if="node.children && node.children.length">
        <div class="mind-node-item-children-line"></div>
        <div ref="childrenItem" class="mind-node-item-children-item">
          <MindMapNode
            v-for="nodeList in node.children"
            :key="nodeList.id"
            :node="nodeList"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, computed } from 'vue'
  // 脑图节点
  export default defineComponent({
    name: 'MindMapNode',
    props: {
      node: { type: Object, default: () => ({}) },
    },
    setup(props) {
      const childrenItem = ref(null)
      const mindNodeParent = ref(null)
      const mindNodeText = ref(null)

      // 父节点高度
      const mindNodeParentHeight = computed(() => {
        if (mindNodeParent.value) {
          return mindNodeParent.value.getBoundingClientRect().height
        }
        return 0
      })
      // 文字节点高度
      const mindNodeTextHeight = computed(() => {
        if (mindNodeText.value) {
          return mindNodeText.value.getBoundingClientRect().height
        }
        return 0
      })
      // 子节点高度
      const childrenItemHeight = computed(() => {
        if (childrenItem.value) {
          return childrenItem.value.getBoundingClientRect().height
        }
        return 0
      })
      // 父节点样式
      const mindNodeParentStyle = computed(() => {
        if (childrenItem.value && mindNodeParent.value && mindNodeText.value) {
          if (mindNodeParentHeight.value > childrenItemHeight.value) {
            return {
              marginTop: `${mindNodeParentHeight.value}px`,
            }
          } else {
            return {
              transform: `translateY(${
                (childrenItemHeight.value - mindNodeTextHeight.value - 20) / 2
              }px)`,
              marginBottom: `${
                (childrenItemHeight.value - mindNodeTextHeight.value - 20) / 2
              }px`,
            }
          }
        }
        return {}
      })

      // 子节点样式
      const mindNodeChildStyle = computed(() => {
        if (childrenItem.value && mindNodeParent.value && mindNodeText.value) {
          if (mindNodeParentHeight.value > childrenItemHeight.value) {
            return {
              // transform: `translateY(-${
              //   (mindNodeParentHeight.value +
              //     childrenItemHeight / 2 -
              //     childrenItemHeight -
              //     mindNodeTextHeight -
              //     20) /
              //   2
              // }px)`,
              transform: `translateY(${(mindNodeTextHeight.value + 20) / 2}px)`,
            }
          }
        }
        return {}
      })

      const currentNodeStyleVar = computed(() => {
        let firstParentNodeHeight = null
        if (mindNodeParentHeight.value > childrenItemHeight.value) {
          if (props.node.children && props.node.children.length) {
            // firstParentNodeHeight = `${
            //   mindNodeParentHeight.value - mindNodeText.value / 2
            // }px`
          } else {
            firstParentNodeHeight = `${
              mindNodeParentHeight.value - mindNodeText.value / 2
            }px`
          }
        } else {
          firstParentNodeHeight = `${
            mindNodeParentHeight.value - mindNodeText.value / 2
          }px`
        }
        return {
          '--first-parent-node-height': firstParentNodeHeight,
          '--last-parent-node-height': '',
        }
      })
      console.log('mindNodeParentHeight', mindNodeParentHeight)
      return {
        childrenItem,
        mindNodeText,
        mindNodeParent,
        mindNodeParentHeight,
        mindNodeChildStyle,
        mindNodeTextHeight,
        childrenItemHeight,
        mindNodeParentStyle,
        currentNodeStyleVar,
      }
    },
  })
</script>

<style scoped lang="scss">
  $space-left: 20px;
  $space-right: 100px;
  .mind-node-item {
    position: relative;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   height: calc(100% + 10px);
    //   width: 1px;
    //   top: 28px;
    //   background-color: var(--el-color-primary);
    // }
    // padding-top: 50%;
    &-text {
      position: relative;
      width: 200px;
      padding: 8px 10px;
      border-radius: 4px;
      background-color: var(--el-color-primary-light-5);
      margin: 10px $space-left;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -$space-left;
        width: $space-left;
        height: 1px;
        transform: translateY(-50%);
        background-color: var(--el-color-primary);
        // border-top: 0.5px solid var(--el-color-primary);
      }
    }
    &-parent {
      &-wrapper {
        position: relative;
        // &::before {
        //   content: '';
        //   position: absolute;
        //   left: 0;
        //   height: calc(100% + 10px);
        //   width: 1px;
        //   // top: 28px;
        //   background-color: var(--el-color-primary);
        // }
      }
    }
    &-children {
      position: relative;
      &-wrapper {
      }
      &-line {
        width: $space-right;
        height: 1px;
        background-color: var(--el-color-primary);
      }
      &-item {
        .mind-node-item {
          position: relative;
          &.has-children {
            &:last-child {
              // &::before {
              //   content: '';
              //   left: 0;
              //   top: calc(-50%);
              // }
            }
            &:first-child {
              &::before {
                content: '';
                left: 0;
                bottom: -10px;
                // height: 50%;
                height: 0;
              }
            }
          }
          &::before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            width: 1px;
            background-color: var(--el-color-primary);
          }
          &:first-child {
            &::before {
              content: '';
              left: 0;
              bottom: 0px;
              height: var(--first-parent-node-height);
              height: 0;
            }
          }
          &:last-child {
            &::before {
              content: '';
              left: 0;
              top: calc(-50% - 10px);
              height: 0;
            }
          }
        }
        // &:last-child {
        //   background-color: red;
        // }
      }
      .mind-node-item {
        &::before {
          content: '';
          position: absolute;
          height: 100%;
          top: 0%;
          width: 1px;
          background-color: var(--el-color-primary);
          // transform: translateY(-50%);
          // border-left: 1px solid var(--el-color-primary);
        }
        &:first-child {
          &::before {
            height: 50%;
            top: 50%;
          }
        }
        &:last-child {
          &::before {
            height: 50%;
            top: 0%;
          }
        }
      }
    }
    &-other-children {
      padding-left: 50px;
    }
  }
</style>
