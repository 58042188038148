<!--
 * @Author: WGL
 * @Date: 2023-06-01 10:27:28
 * @LastEditors: WGL
 * @LastEditTime: 2024-06-03 11:43:02
 * @Description: 
-->
<template>
  <div class="system-user-container">
    <base-table :config="tableConfig" :ref-menu-id="refMenuId">
      <!-- 库存 -->
      <template #num="{ scope }">
        <div class="fxmc">
          <span>{{ scope.num }}</span>
          <div v-if="params.searchKey">
            (
            <span>{{ scope.product_info.stock || '-' }}</span>
            /
            <span class="cd">{{ scope.product_info.alarm_stock || '-' }}</span>
            )
          </div>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
  import { defineComponent, reactive } from 'vue'
  import { t } from '@/utils/i18n'

  const MODULE = 'repository-product' // 模块
  // const MODULE_REPOSITORY_RECEIPT = 'repository-receipt' // 模块

  export default defineComponent({
    name: 'ProductRepository',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
      params: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    setup(props) {
      const { from, searchKey, valKey } = props?.params || {}

      // 表格数据
      const tableColumns = [
        { label: 'ID', prop: 'id', width: 85 },
        { label: '图片', prop: 'product_info.image', width: 100, isImg: true },
        {
          label: '产品',
          prop: 'product_info.title',
          width: 180,
          class: from === 'product' ? '' : 'cp cb tdu',
          publicDrawerConfig:
            from === 'product'
              ? null
              : {
                  openName: 'productDetail',
                  api: {
                    valueKey: 'product_id',
                  },
                },
        },
        { label: '仓库', prop: 'repository_info.title', width: 150 },
        {
          label: '供应商',
          prop: 'supplier_info.title',
          minWidth: 180,
          valFun: (row) => `${row?.supplier_info?.title}`,
        },
        {
          label: '库存',
          prop: 'num',
          width: 150,
          sortable: 'custom',
          tips: t('库存提示'), // 当前库存量(总库存/预警库存)
        },
        {
          label: '产品编码',
          prop: 'product_info.code',
          width: 150,
        },
        { label: 'OEM编码', prop: 'product_info.oem_code', width: 150 },
        // {
        //   width: 150,
        //    label: '操作',
        // isOperate: true,
        //   fixed: 'right',
        //   action: {
        //     edit: {
        //       type: 'editBox',
        //     },
        //     list: [''],
        //   },
        // },
      ]
      let tableConfig = reactive({
        headerActions: {
          // 添加入库单
          addRepositoryReceipt: {
            type: 'editBox',
          },
          // list: ['addRepositoryReceipt'],
          list: [''],
        },
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          [searchKey]: props?.info?.[valKey] || '',
        },
        tableDeleteParams: {},
        tableButtonNum: 3,
        tableSearch: {
          formAttrs: {
            inline: true,
          },
          formList: [
            {
              type: 'cascaderServer',
              key: 'repository_id',
              value: ['repository'].includes(from) ? props?.info?.id : '',
              labelAttrs: {
                label: '仓库',
              },
              formAttrs: {
                disabled: from === 'repository',
                placeholder: '请选择仓库',
                clearable: true,
                filterable: true,
                multiple: false,
                showAllLevels: true,
                listApi: '/repository/tree',
                exParams: {
                  sort: 'title',
                  order: 'asc',
                },
                props: {
                  label: 'title',
                  value: 'id',
                  checkStrictly: true,
                  multiple: false,
                },
              },
            },
            {
              type: 'selectServer',
              key: 'product_id',
              value: ['product'].includes(from) ? props?.info?.id : '',
              labelAttrs: {
                label: '产品',
              },
              formAttrs: {
                disabled: from === 'product',
                listApi: `/product/index`,
                valueType: 'number',
                searchKey: 'search',
                multiple: false,
                placeholder: '支持产品名称/OEM码/编码搜索',
              },
            },
            {
              type: 'selectServer',
              key: 'supplier_id',
              value: '',
              labelAttrs: {
                label: '供应商',
              },
              formAttrs: {
                listApi: `/supplier/index`,
                valueType: 'number',
                searchKey: 'search',
                multiple: false,
                placeholder: '请选择供应商',
              },
            },
            {
              type: 'submit',
              submit: {
                title: '搜索',
                attrs: {
                  type: 'primary',
                },
              },
              reset: {
                title: '重置',
              },
            },
          ],
        },
        editBox: {
          title: '入库',
          dialog: {
            width: '500px',
            top: '15vh',
            appendToBody: true,
            destroyOnClose: true,
            lockScroll: true,
          },
          form: {
            api: {
              add: `/repository-log/add`,
              detail: `/${MODULE}/detail`,
              update: `/${MODULE}/edit`,
            },
            formListKey: true,
            formAttrs: {
              labelPosition: 'left',
              labelWidth: '100px',
            },
            formList: [
              {
                type: 'slot',
                key: 'pm',
                value: 1,
              },
              {
                type: 'selectServer',
                key: 'product_id',
                value: from === 'product' ? props?.info?.id : '',
                labelAttrs: {
                  label: '产品',
                },
                formAttrs: {
                  disabled: from === 'product',
                  listApi: `/product/index`,
                  valueType: 'number',
                  multiple: false,
                  placeholder: '支持产品名称/OEM码/编码搜索',
                },
              },
              {
                type: 'cascaderServer',
                key: 'repository_id',
                value: from === 'repository' ? props?.info?.id : '',
                labelAttrs: {
                  label: '仓库',
                },
                formAttrs: {
                  class: 'w',
                  disabled: from === 'repository',
                  placeholder: '请选择仓库',
                  clearable: true,
                  filterable: true,
                  multiple: false,
                  'show-all-levels': true,
                  listApi: '/repository/tree',
                  exParams: {
                    sort: 'title',
                    order: 'asc',
                  },
                  props: {
                    label: 'title',
                    value: 'id',
                    checkStrictly: true,
                    multiple: false,
                  },
                },
              },
              {
                type: 'inputNumber',
                key: 'num',
                value: 0,
                labelAttrs: {
                  label: '数量',
                },
                formAttrs: {
                  class: 'w',
                  clearable: true,
                  min: 0,
                  max: 999999,
                  step: 1,
                  style: 'width:220px',
                },
              },
              {
                type: 'input',
                key: 'admin_remark',
                value: '',
                labelAttrs: {
                  label: '备注',
                },
                formAttrs: {
                  type: 'text',
                  clearable: true,
                  disabled: false,
                },
              },
              {
                type: 'tips',
                key: '',
                value: '*添加后请到出入库单列表审核',
                labelAttrs: {
                  label: '',
                },
                formAttrs: {
                  class: 'cy',
                },
              },
            ],
          },
          footer: [
            {
              action: 'cancel',
              title: '取消',
              attrs: {},
            },
            {
              action: 'confirm',
              title: '保存',
              attrs: {
                type: 'primary',
              },
            },
          ],
        },
      })

      return {
        tableConfig,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
