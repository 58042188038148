<!--
 * @Author: WGL
 * @Date: 2023-06-21 09:23:33
 * @LastEditors: WGL
 * @LastEditTime: 2024-10-28 11:44:14
 * @Description: 
-->
<template>
  {{ stockText }}
</template>

<script>
  // 基础产品库存数量组件
  import { computed, defineComponent } from 'vue'

  export default defineComponent({
    name: 'BaseStock',
    components: {},
    props: {
      info: { type: Object, default: () => {} },
    },
    setup(props) {
      const stockText = computed(() => {
        const productInfo = props?.info?.product_info || props?.info
        const stockNum = +productInfo?.stock || 0
        const freezeNum = productInfo?.dynamic_info?.freeze_num || 0
        const unitTetx = productInfo?.unit || '-'
        return `${stockNum}-${freezeNum}=${stockNum - freezeNum} (${unitTetx})`
      })
      return {
        stockText,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
