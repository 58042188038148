<!--
 * @Author: WGL
 * @Date: 2023-11-17 15:55:58
 * @LastEditors: WGL
 * @LastEditTime: 2023-11-17 16:13:10
 * @Description: 
-->
<template>
  <div class="fv">
    <des-header :title="t('综述信息')" />
    <jwt-editor
      v-model="storyInfo"
      class="ex mh0"
      :exclude-keys="excludeKeys"
      height=""
      :toolbar-keys="toolbarKeys"
      :upload-url="uploadRichTextUrl"
    />
    <div>
      <el-button
        class="mt10"
        size="default"
        type="primary"
        @click="handleSubmit"
      >
        {{ t('保存') }}
      </el-button>
    </div>
  </div>
</template>

<script>
  import { defineComponent, reactive, onBeforeMount, toRefs } from 'vue'
  import request from '@/utils/request'
  import { uploadRichTextUrl } from '@/config/index'
  import { t } from '@/utils/i18n'

  // 表格数据

  export default defineComponent({
    name: 'FollowUpSummary',
    components: {},
    props: {
      info: { require: true, type: Object, default: () => {} },
      params: { type: Object, default: () => {} },
    },
    setup(props) {
      console.log('props.params :>> ', props.params)
      const state = reactive({
        storyInfo: props.info.story, // 模块对应的概述
        toolbarKeys: [],
        excludeKeys: [
          'blockquote',
          'group-more-style',
          'fontFamily',
          'lineHeight',
          'todo',
          'emotion',
          'uploadAttachment',
          'group-video',
          'insertTable',
          'codeBlock',
          'divider',
          'undo',
          'redo',
        ],
      })

      // 保存概述
      const handleSubmit = () => {
        request({
          url: `/${props.params.module}/edit`,
          method: 'post',
          data: {
            id: props?.info?.id,
            story: state.storyInfo,
          },
        })
      }

      onBeforeMount(async () => {})
      return {
        ...toRefs(state),
        handleSubmit,
        uploadRichTextUrl,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped></style>
