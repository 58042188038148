<!--
 * @Author: WGL
 * @Date: 2023-05-16 17:10:08
 * @LastEditors: WGL
 * @LastEditTime: 2024-09-24 13:56:46
 * @Description:
-->
<template>
  <el-drawer
    v-model="visible"
    destroy-on-close
    direction="ltr"
    :modal="showModal"
    :modal-class="[showModal ? '' : 'add-product-modal-class']"
    :show-close="true"
    :size="showModal ? '38%' : '100%'"
    :with-header="false"
    @close="handleClose"
  >
    <div
      class="system-user-container rel"
      :class="[showModal ? '' : 'pr15 pt15']"
    >
      <!-- 防止右侧输入框不能聚焦 -->
      <el-input
        ref="hideValRef"
        v-model="hideVal"
        class="hideValRef"
        clearable
        placeholder=""
        size="small"
      />

      <base-table
        ref="refTable"
        :config="tableConfig"
        table-height="72vh"
        @header-action="handleHeaderAction"
        @search-result="handleSearchResult"
        @table-action="handleTableAction"
      >
        <template #table-before>
          <div v-if="showPredictPrice">
            <span>{{ t('预设报价') }}：</span>
            <span class="cd b">
              {{ CURRENCY_UNIT[customerPredictPrice.currency]
              }}{{ customerPredictPrice.price }}
            </span>
          </div>
        </template>
        <template #operation="{ scope }">
          <el-button
            :disabled="info?.audit_status === 10"
            link
            type="primary"
            @click="handleUseProductPirce(scope)"
          >
            {{ t('复用价格') }}
          </el-button>
        </template>
      </base-table>
      <el-button
        v-if="!showModal"
        class="add-product-close-btn"
        :icon="Close"
        link
        @click.stop="handleCloseDrawer"
      />
    </div>
  </el-drawer>
</template>

<script>
  import {
    defineComponent,
    reactive,
    ref,
    toRefs,
    onMounted,
    computed,
  } from 'vue'
  import { quoteDetailEdit } from '@/api/quote'
  import { orderDetailEdit } from '@/api/order'
  import { CURRENCY_UNIT, ROLE_VISIBLE_RANGE } from '@/config/variable.config'
  import { Close } from '@element-plus/icons-vue'
  import { t } from '@/utils/i18n'
  import { customerProductList } from '@/api/customer'
  import { objToArr } from '@/utils/index'

  export default defineComponent({
    name: 'QuoteHistoryDrawer',
    components: {},
    props: {
      info: { require: true, type: Object, default: () => {} },
      showModal: { type: Boolean, default: false }, // 是否显示遮罩层
    },
    emits: ['use-price', 'close', 'batch-add'],
    setup(props, { emit }) {
      console.log('props :>> QuoteHistoryDrawer', props)
      const refTable = ref(null)
      const hideValRef = ref(null)
      let MODULE = 'sale-order-detail'
      const isPurchaseHis = props.info?.showType === 'purchase'
      if (isPurchaseHis) {
        MODULE = 'purchase-order-detail'
      }
      const state = reactive({
        selectList: [],
        visible: false,
        init: true,
        hideVal: '',
        searchResult: {
          customer_id: props?.info?.customer_id || '',
        },
        customerPredictPrice: {},
      })

      const showPredictPrice = computed(() => {
        return state.searchResult.customer_id && state.customerPredictPrice?.id
      })

      console.log('showPredictPrice', showPredictPrice)
      // 表格数据
      const tableColumns = [
        {
          label: '订单Id',
          prop: 'order_info.code',
          width: 180,
        },
        // {
        //   label: '订单标题',
        //   prop: 'order_info.title',
        // },
        {
          hide: isPurchaseHis,
          label: '客户名',
          prop: 'order_info.customer_info.title',
          minWidth: 150,
        },
        {
          hide: !isPurchaseHis,
          label: '供应商',
          prop: 'order_info.supplier_info.title',
          minWidth: 150,
        },
        {
          label: '单价',
          prop: 'price',
          sortable: true,
          width: 120,
          valFun: (row) => {
            return `${CURRENCY_UNIT[row.currency]}${row.price}`
          },
        },
        {
          label: '数量',
          prop: 'num',
          width: 80,
        },
        {
          label: '业务员',
          prop: 'order_info.owner.name',
          width: 80,
        },
        {
          label: '时间',
          prop: isPurchaseHis
            ? 'order_info.create_time'
            : 'order_info.order_time',
          dateTimeFormat: 'YYYY-MM-DD',
          width: 110,
          fixed: 'right',
        },
        {
          hide: props.info?.from === 'productList',
          width: 80,
          label: '操作',
          prop: 'operation',
          fixed: 'right',
        },
      ]

      const handleSearchForm = () => {
        let defaultConfig = {
          formAttrs: {
            inline: true,
          },
          beforeReq: (data) => {
            // if (data.customType) {
            //   data.customer_id = props?.info?.customer_id || ''
            // } else {
            //   data.customer_id = ''
            // }
            // delete data.customType
            return data
          },
          formList: [
            {
              type:
                props.info?.from === 'productList' ? 'selectServer' : 'radio',
              key: 'customer_id',
              value: props?.info?.customer_id || '',
              labelAttrs: {
                label: '客户',
              },
              formAttrs: {
                listApi: `/customer/index`,
                class: props.info?.from === 'productList' ? 'w150' : 'w',
                valueType: 'number',
                multiple: false,
                placeholder: '请选择客户',
              },
              isButton: true,
              options: [
                {
                  title: t('当前客户'),
                  attrs: { label: props?.info?.customer_id || '' },
                  events: {},
                },
                { title: t('所有客户'), attrs: { label: '' }, events: {} },
              ],
            },
            {
              type: 'select',
              key: 'visible',
              value: 10,
              labelAttrs: {
                label: '范围',
              },
              formAttrs: {
                class: '',
                searchKey: 'title',
                placeholder: '请选择数据范围',
              },
              condition: (model) => {
                return !model.customer_id
              },
              options: objToArr(ROLE_VISIBLE_RANGE),
            },
            // {
            //   type: 'radio',
            //   key: 'customer_id',
            //   value: '',
            //   formAttrs: {
            //     placeholder: '请选择出入库类型',
            //     clearable: true,
            //   },
            //   isButton: true,
            //   options: [
            //     {
            //       title: t('当前客户'),
            //       attrs: { label: props?.info?.customer_id || '' },
            //       events: {},
            //     },
            //     { title: t('所有客户'), attrs: { label: '' }, events: {} },
            //   ],
            // },
            {
              type: 'datePickerRange',
              key: 'date',
              startKey: 'create_time|>=',
              endKey: 'create_time|<=',
              value: [],
              labelAttrs: {
                label: '时间范围',
              },
              formAttrs: {
                class: 'w220',
                type: 'daterange',
                placeholder: '请选择开始日期',
                format: 'YYYY-MM-DD',
                startPlaceholder: '开始日期',
                endPlaceholder: '结束日期',
                // clearable: true,
              },
            },
            {
              type: 'inputNumber',
              key: 'price|>=',
              labelAttrs: {
                label: '价格范围',
              },
              formAttrs: {
                class: 'w120',
                placeholder: '价格范围',
                clearable: true,
              },
            },
            {
              type: 'inputNumber',
              key: 'price|<=',
              labelAttrs: {
                label: '~',
              },
              formAttrs: {
                class: 'w120',
                placeholder: '价格范围',
                clearable: true,
              },
            },
            {
              type: 'submit',
              submit: {
                title: '搜索',
                attrs: {
                  type: 'primary',
                },
              },
              reset: {
                title: '重置',
              },
              layoutAttrs: {
                span: 8,
              },
            },
          ],
        }
        if (isPurchaseHis) {
          defaultConfig.formList.splice(0, 2)
        }
        return defaultConfig
      }
      let tableConfig = reactive({
        headerActions: {
          list: [''],
        },
        tableColumn: tableColumns,
        tableAttr: {
          'row-key': 'id',
        },
        tableEvents: {
          // select: handleTableSelect,
        },
        tableListApi: `/${MODULE}/index`,
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          product_id: props?.info?.product_id || '',
          customer_id: props?.info?.customer_id || '',
        },
        tableDeleteParams: {},
        tableButtonNum: 3,
        useField: false,
        tableSearch: handleSearchForm(),
      })

      const handleTableAction = (action, row) => {
        console.log('action :>> ', action)
        console.log('row :>> ', row)
        // if (action.code === 'AddProduct') {
        //   handleAddProductReq(row.id)
        // }
      }

      const handleHeaderAction = (action) => {
        console.log('action :>> ', action)
        // if (action.code === 'AddProduct') {
        //   const productIds = []
        //   refTable.value.jwtTableRef.selectionRow.forEach((item) => {
        //     if (!item.added) {
        //       productIds.push(item.id)
        //     }
        //   })
        //   if (productIds.length) {
        //     addProduct(productIds)
        //   }
        // }
      }

      // 复用价格
      const handleUseProductPirce = (row) => {
        console.log('row :>> ', row)
        if (props.info?.showType) {
          props.info?.usePrice(row.price)
          // emit('use-price', row.price)
          return
        }
        const params = {
          id: props?.info?.id,
          price: row.price,
        }
        if (props.info.moduleFrom === 'orderList') {
          orderDetailEdit(params).then(() => {
            emit('use-price')
            inputFocus()
          })
        } else if (props.info.moduleFrom === 'quoteList') {
          quoteDetailEdit(params).then(() => {
            emit('use-price')
            inputFocus()
          })
        }
      }

      const showDrawer = () => {
        state.visible = true
      }

      const handleCloseDrawer = () => {
        state.visible = false
      }

      const handleClose = () => {
        emit('close')
      }

      const inputFocus = () => {
        console.log('hideValRef.value :>> ', hideValRef.value)
        hideValRef?.value?.focus()
        setTimeout(() => {
          hideValRef?.value?.blur()
        }, 100)
      }

      /**
       * 获取客户预设价格
       */
      const handleGetCustomerPredictPrice = () => {
        if (!props?.info?.customer_id) return
        const params = {
          product_id: props?.info?.product_id || '',
          customer_id: props?.info?.customer_id || '',
        }
        customerProductList(params).then((res) => {
          console.log('res handleGetCustomerPredictPrice', res)
          state.customerPredictPrice = res.data[0] || {}
        })
      }

      const handleSearchResult = (data) => {
        console.log('handleSearchResult data', data)
        state.searchResult = data
      }

      onMounted(() => {
        setTimeout(() => {
          inputFocus()
          handleGetCustomerPredictPrice()
        }, 500)
      })
      return {
        refTable,
        hideValRef,
        CURRENCY_UNIT,
        Close,
        tableConfig,
        showPredictPrice,
        ...toRefs(state),
        handleTableAction,
        handleHeaderAction,
        showDrawer,
        handleCloseDrawer,
        handleClose,
        handleUseProductPirce,
        t,
        handleSearchResult,
      }
    },
  })
</script>

<style lang="scss">
  .hideValRef {
    position: absolute !important;
    top: -100px;
    left: 0;
    width: 10px;
    opacity: 0;
  }
</style>
