<template>
  <div class="system-user-container">
    <base-table
      ref="tableRef"
      :config="tableConfig"
      :ref-menu-id="refMenuId"
      :table-height="tableHeight"
      @header-action="handleHeaderAction"
    />
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
    onBeforeMount,
    ref,
    toRefs,
    computed,
  } from 'vue'
  import { t } from '@/utils/i18n'
  import { productRivalChannelAddDialog } from '@/utils/dialogBoxConfig'
  import { commonSearchConfig } from '@/utils/headerSearchConfig'
  import { CURRENCY_UNIT } from '@/config/variable.config'

  import request from '@/utils/request'

  const MODULE = 'enemy-channel-product' // 模块

  export default defineComponent({
    name: 'ProductRivalChannelList',
    props: {
      params: { type: Object, default: () => {} },
      info: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    emits: [],
    setup(props) {
      console.log('props :>> ProductChannelList', props)
      const tableRef = ref(null)
      const refAlbbPublishProduct = ref(null)
      const isAliType = 3
      const state = reactive({
        showDialog: false,
        openData: {},
        formModel: {
          shop_id: '',
          category_id: '',
        },
      })
      const tableHeight = computed(() => {
        return props.params?.channelType ? 'auto' : '75vh'
      })
      // 表格数据
      const tableColumns = [
        // { type: 'selection', width: 50, fixed: 'left' },
        { label: 'ID', prop: 'id', width: 85, sortable: 'custome' },
        {
          hide: props.params?.channelType,
          label: '关联产品',
          prop: 'product_info.title',
          width: 240,
          class: 'cp cb tdu',
          publicDrawerConfig: {
            openName: 'productDetail',
            api: {
              valueKey: 'product_id',
            },
          },
        },
        {
          label: '渠道类型',
          prop: 'enemy_channel_info.channel_type_info.title',
          width: 130,
        },
        {
          label: '渠道',
          prop: 'enemy_channel_info.title',
          width: 130,
        },
        // {
        //   label: '店铺/账号名称',
        //   prop: 'shop_channel.title',
        //   minWidth: 130,
        // },
        {
          label: '链接地址',
          prop: 'url',
          isLink: true,
          editType: 'link',
          minWidth: 240,
        },
        {
          label: '售价',
          prop: 'price',
          class: 'cd b',
          width: 120,
          valFun: (row) => {
            return `${CURRENCY_UNIT[row.currency]}${row.price}`
          },
        },
        {
          label: '售出产品数',
          prop: 'number',
          width: 130,
        },
        // { label: '第三方商品ID', prop: 'third_product_id', width: 130 },
        { label: '备注', prop: 'admin_remark', width: 180 },
        { label: '创建人', prop: 'creator.name', width: 150 },
        { label: '创建时间', prop: 'create_time', width: 110 },
        {
          width: 150,
           label: '操作',
            isOperate: true,
          fixed: 'right',
          action: {
            edit: {
              type: 'editBox',
            },
            // 绑定阿里巴巴产品
            bindProduct: {
              type: 'editBox',
              editBoxName: 'bindProduct',
              condition: (row) => {
                return (
                  row.type === 1 &&
                  row.sub_type === isAliType &&
                  !row.third_product_id
                )
              },
            },
            list: ['edit', 'delete'],
          },
        },
      ]
      let tableConfig = reactive({
        searchBtnInline: !props?.params?.from,
        headerActions: {
          add: {
            type: 'editBox',
          },
          list: [props.params?.channelType ? 'add' : ''],
        },
        useField: false,
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          [props?.params?.searchKey || '']: props?.info?.id || '',
        },
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableFileImport: {
          refreshTable: true,
          action: `/${MODULE}/import`,
        },
        tableExportPathKey: 'http_path',
        tableDeleteParams: {},
        tableButtonNum: 3,
        showPagination: !props?.params?.from,
        tableSearch: props?.params?.from
          ? null
          : commonSearchConfig('rivalChannel'),
        editBox: productRivalChannelAddDialog(
          MODULE,
          props.params?.channelType || '',
          props.info,
          props.params
        ),
      })

      const handleHeaderAction = (action) => {
        console.log('action', action)
        // setTimeout(() => {
        //   store.commit('sys/setOpenPageDrawerName', 'productDetail')
        // }, 1000)
      }

      onBeforeMount(async () => {})
      return {
        ...toRefs(state),
        tableConfig,
        tableRef,
        MODULE,
        request,
        isAliType,
        tableHeight,
        refAlbbPublishProduct,
        handleHeaderAction,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .system-user-container {
    height: 100%;
    :deep(.input-number) {
      .el-input__inner {
        padding-right: 0;
      }
    }
  }
</style>
