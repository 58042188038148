<!--
 * @Author: WGL
 * @Date: 2023-06-15 13:42:15
 * @LastEditors: WGL
 * @LastEditTime: 2024-04-11 11:40:55
 * @Description: 
-->
<template>
  <div class="system-user-container">
    <base-table
      ref="tableRef"
      :config="tableConfig"
      :ref-menu-id="refMenuId"
      table-height="72vh"
      @get-detail-success="getDetailSuccess"
      @header-action="handleHeaderAction"
    >
      <!-- 包含的产品 -->
      <template #product_list="{ scope }">
        <div>
          {{ scope.product_list?.map((item) => item.title)?.join('/') || '-' }}
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
  import { defineComponent, reactive, onBeforeMount, ref } from 'vue'
  import {
    NORMAL_STATUS,
    CURRENCY_UNIT,
    PRODUCT_UNIT,
  } from '@/config/variable.config'
  import { t } from '@/utils/i18n'
  import { productPackageAddDialog } from '@/utils/dialogBoxConfig'
  import { commonSearchConfig } from '@/utils/headerSearchConfig'

  import request from '@/utils/request'
  const MODULE = 'product-package' // 模块
  // 表格数据
  const tableColumns = [
    // { type: 'selection', width: 50, fixed: 'left' },
    { label: 'ID', prop: 'id', width: 85, sortable: 'custome' },
    {
      label: '名称',
      prop: 'title',
      width: 300,
      class: 'cp cb tdu',
      publicDrawerConfig: {
        openName: 'productPackageDetail',
      },
    },
    { label: '包含产品', prop: 'product_list', minWidth: 130 },
    { label: '创建人', prop: 'creator.name', width: 130 },
    { label: '创建时间', prop: 'create_time', width: 180 },
    {
      width: 150,
      label: '操作',
      isOperate: true,
      fixed: 'right',
      action: {
        edit: {
          type: 'editBox',
        },
        list: ['edit', 'delete'],
      },
    },
  ]

  export default defineComponent({
    name: 'ProductPackageList',
    props: {
      params: { type: Object, default: () => {} },
      info: { type: Object, default: () => {} },
      refMenuId: { type: [Number, String], default: '' }, // 关联的菜单ID
    },
    emits: [],
    setup(props) {
      console.log('props :>> ProductPackageList', props)
      const tableRef = ref(null)

      let tableConfig = reactive({
        searchBtnInline: true,
        headerActions: {
          add: {
            type: 'editBox',
          },
          list: ['add'],
        },
        tableColumn: tableColumns,
        tableListApi: `/${MODULE}/index`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          [props?.params?.searchKey || '']: props?.info?.id || '',
        },
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableFileImport: {
          refreshTable: true,
          action: `/${MODULE}/import`,
        },
        tableExportPathKey: 'http_path',
        tableDeleteParams: {},
        tableButtonNum: 3,
        tableSearch: commonSearchConfig('baseList'),
        editBox: productPackageAddDialog(
          MODULE,
          props.params?.from || '',
          props.info,
          props.params
        ),
      })

      const handleHeaderAction = (action) => {
        console.log('action', action)
        // setTimeout(() => {
        //   store.commit('sys/setOpenPageDrawerName', 'productDetail')
        // }, 1000)
      }

      onBeforeMount(async () => {})
      return {
        tableConfig,
        NORMAL_STATUS,
        tableRef,
        PRODUCT_UNIT,
        MODULE,
        CURRENCY_UNIT,
        request,
        handleHeaderAction,
        t,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .system-user-container {
    height: 100%;
    :deep(.input-number) {
      .el-input__inner {
        padding-right: 0;
      }
    }
  }
</style>
