<!--
 * @Author: WGL
 * @Date: 2023-11-17 10:08:58
 * @LastEditors: WGL
 * @LastEditTime: 2023-11-23 10:04:16
 * @Description: 
-->
<template>
  <div>
    <vab-chart
      class="trend-echart wi"
      :init-options="initOptions"
      :option="option"
      theme="vab-echarts-theme"
    />
  </div>
</template>

<script>
  import {
    defineComponent,
    computed,
    reactive,
    toRefs,
    watchEffect,
    onUnmounted,
  } from 'vue'
  import VabChart from '@/extra/VabChart'
  import { formatNumber } from '@/utils/index'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'ProductSupplierPie',
    components: { VabChart },
    props: {
      title: { type: String, default: '原产国' },
      data: { type: Array, default: () => [] },
      type: { type: String, default: 'count' },
      params: { type: Object, default: () => {} },
    },
    setup(props) {
      const state = reactive({
        currentMonth: 0,
        timer: null,
        animationTime: 4000,
      })

      const listData = computed(() => {
        const type = props.type || 'count'
        return (
          props.data?.map((item) => {
            item.val = item.title
            item.currentVal =
              type != 'amount' ? item[type] : (+item[type])?.toFixed(2)
            return item
          }) || []
        )
      })
      // 配置项
      const option = computed(() => {
        const obj = {
          count: t('采购次数'),
          num: t('采购数量'),
          amount: t('采购金额'),
        }
        const type = props.type || 'count'
        const currentPieTitle = obj[type]
        let textData = listData?.value?.reduce((a, b) => {
          return a + +b.currentVal
        }, 0)
        if (props.type) {
          textData = type != 'amount' ? textData : +textData?.toFixed(2)
        }
        let options = {
          title: [
            // {
            //   text: props.title,
            //   left: 'center',
            //   textStyle: {
            //     fontSize: 16,
            //   },
            // },
            {
              text: currentPieTitle,
              // left: '44.5%',
              left: 'center',
              top: '40%',
            },
            {
              text: textData,
              left: 'center',
              top: '48%',
              textStyle: {
                color: '#ff4d4f',
                fontSize: 24,
                fontWeight: 'bold',
              },
            },
          ],
          tooltip: {
            trigger: 'item',
          },
          // legend: {
          //   top: '5%',
          //   left: 'center',
          // },
          series: [
            {
              name: currentPieTitle,
              startAngle: 140,
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: true,
                formatter(param) {
                  // correct the percentage
                  return param.name + ' (' + param.percent + '%)'
                },
              },
              data: [],
            },
          ],
        }
        handleEchartData(options)
        return options
      })

      const handleEchartData = (options) => {
        // const flag = true
        // if (flag) return
        if (listData.value?.length === 0) return
        let seriesData = []
        listData.value.map((item) => {
          seriesData.push({
            name: item.val,
            value: item.currentVal,
          })
        })

        options.series[0].data = seriesData || []
        console.log('state.option :>> ', options)
      }

      const handleClearTimer = () => {
        clearInterval(state.timer)
      }

      watchEffect(() => {})
      const change = () => {
        const num = 1200000000
        console.log('formatNumber :>> ', formatNumber(num))
      }
      onUnmounted(() => {
        handleClearTimer()
      })
      return {
        initOptions: {
          renderer: 'svg',
        },
        option,
        ...toRefs(state),
        change,
      }
    },
  })
</script>
