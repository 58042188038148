<template>
  <el-dialog
    v-model="dialogVisible"
    append-to-body
    center
    destroy-on-close
    :title="dialogTitle"
    width="60%"
  >
    <el-form
      ref="formRef"
      v-loading="ajaxLoading"
      label-width="80px"
      :model="formModel"
      :rules="formRules"
    >
      <el-form-item :label="t('应用渠道')" prop="channel_ids">
        <jwt-select-server
          :ref="setItemRef"
          v-model="formModel.channel_ids"
          :request="request"
          v-bind="{
            clearable: true,
            filterable: true,
            class: 'w',
            listApi: `/product-channel/index`,
            searchKey: 'title',
            placeholder: t('请选择渠道'),
            multiple: true,
            exParams: {
              product_id: openData.id,
            },
            exLabelKeyFun: (item) => {
              return `${item.channel_info?.title}`
            },
          }"
        />
      </el-form-item>
      <el-form-item v-if="!isSigleImg" :label="t('图片使用')">
        <el-radio-group v-model="formModel.cover">
          <el-radio :label="1">{{ t('覆盖') }}</el-radio>
          <el-radio :label="0">{{ t('追加') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="t('图片')">
        <div class="w">
          <el-image
            v-for="(item, index) in imageList"
            :key="index"
            class="wh150 mr10 mb10 r10px bor"
            fit="fill"
            :initial-index="index"
            :lazy="true"
            :preview-src-list="imageList"
            preview-teleported
            :src="item"
          />
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span>
        <el-button @click="dialogVisible = false">{{ t('取消') }}</el-button>
        <el-button v-if="!isSigleImg" @click="dialogVisible = false">
          {{ t('继续') }}
        </el-button>
        <el-button type="primary" @click="handleSubmit">
          {{ t('保存') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    reactive,
    toRefs,
    computed,
    nextTick,
    ref,
  } from 'vue'
  import { t } from '@/utils/i18n'
  import request from '@/utils/request'
  import { uploadBase64 } from '@/config/index'
  import { postRequest } from '@/api/api'
  export default defineComponent({
    emits: ['success'],
    setup(props, { emit }) {
      const formRef = ref(null)
      const state = reactive({
        dialogVisible: false,
        ajaxLoading: false,
        formModel: {
          cover: 1,
        },
        formRules: {
          channel_ids: [
            { required: true, message: '请选择渠道', trigger: 'change' },
          ],
        },
        openData: {},
        imageType: '',
        imageList: [],
      })

      const isSigleImg = computed(() => {
        return state.imageType === 'single'
      })
      const dialogTitle = computed(() => {
        return t('选择应用渠道')
      })

      const closeDialogVisible = () => {
        state.dialogVisible = false
        formRef.value.resetFields()
        state.imageList = []
      }

      const showDialogVisible = ({ openData, imageType, data }) => {
        state.openData = openData
        state.imageType = imageType
        if (imageType === 'single') {
          state.imageList = [data]
        } else {
          state.imageList.push(data)
        }
        nextTick(() => {
          state.dialogVisible = true
        })
      }

      const handleUploadBase64 = async (data) => {
        return postRequest(uploadBase64, { filedata: data })
      }

      const handleGetAllImgUrl = async () => {
        const result = []
        for (let i = 0; i < state.imageList.length; i++) {
          const base64 = state.imageList[i]
          const data = await handleUploadBase64(base64)
          data.relative_path && result.push(data.relative_path)
        }
        return result
      }
      const handleSubmitReq = async () => {
        const result = await handleGetAllImgUrl()
        console.log('result :>> handleSubmitReq', result)
        state.ajaxLoading = true
        const params = {
          id: state.openData.id,
          channel_ids: state.formModel.channel_ids,
          cover: state.formModel.cover,
        }
        if (isSigleImg.value) {
          params.image = result[0]
        } else {
          params.slider_image = result
        }
        postRequest('/product/image', params)
          .then(() => {
            state.ajaxLoading = false
            closeDialogVisible()
            emit('success')
          })
          .catch(() => {
            state.ajaxLoading = false
          })
      }

      const handleSubmit = () => {
        formRef.value.validate((valid) => {
          if (valid) {
            handleSubmitReq()
          }
        })
      }

      return {
        formRef,
        dialogTitle,
        request,
        isSigleImg,
        ...toRefs(state),
        t,
        showDialogVisible,
        handleSubmit,
      }
    },
  })
</script>

<style scoped></style>
