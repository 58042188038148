<template>
  <div class="order">
    <vab-card class="order-card1" shadow="hover">
      <template #header>
        <vab-icon icon="shopping-bag-2-line" />
        {{ t('设备') }}
      </template>
      <el-row class="order-card1-content">
        <el-col :span="8">
          <p>总数</p>
          <h1>
            <jwt-count
              :decimals="countConfig.decimals"
              :duration="countConfig.duration"
              :end-val="device.total"
              :prefix="countConfig.prefix"
              :separator="countConfig.separator"
              :start-val="countConfig.startVal"
              :suffix="countConfig.suffix"
            />
          </h1>
        </el-col>
        <el-col :span="8">
          <p>在线</p>
          <h1>
            <jwt-count
              :decimals="countConfig.decimals"
              :duration="countConfig.duration"
              :end-val="device.online"
              :prefix="countConfig.prefix"
              :separator="countConfig.separator"
              :start-val="countConfig.startVal"
              :suffix="countConfig.suffix"
            />
          </h1>
        </el-col>
        <el-col :span="8">
          <p>离线</p>
          <h1>
            <jwt-count
              :decimals="countConfig.decimals"
              :duration="countConfig.duration"
              :end-val="device.offline"
              :prefix="countConfig.prefix"
              :separator="countConfig.separator"
              :start-val="countConfig.startVal"
              :suffix="countConfig.suffix"
            />
          </h1>
        </el-col>
        <el-col :span="8">
          <p>故障</p>
          <h1>
            <jwt-count
              :decimals="countConfig.decimals"
              :duration="countConfig.duration"
              end-val="0"
              :prefix="countConfig.prefix"
              :separator="countConfig.separator"
              :start-val="countConfig.startVal"
              :suffix="countConfig.suffix"
            />
          </h1>
        </el-col>
        <el-col :span="8">
          <p>在线率</p>
          <h1>
            <jwt-count
              :decimals="countConfig.decimals"
              :duration="countConfig.duration"
              :end-val="device.online_rate"
              :prefix="countConfig.prefix"
              :separator="countConfig.separator"
              :start-val="countConfig.startVal"
              suffix="%"
            />
          </h1>
        </el-col>
        <el-col :span="8">
          <p>故障率</p>
          <h1>
            <jwt-count
              :decimals="countConfig.decimals"
              :duration="countConfig.duration"
              :end-val="0"
              :prefix="countConfig.prefix"
              :separator="countConfig.separator"
              :start-val="countConfig.startVal"
              suffix="%"
            />
          </h1>
        </el-col>
      </el-row>
    </vab-card>
  </div>
</template>

<script>
  import { defineComponent, onBeforeMount, ref } from 'vue'
  import { random } from 'lodash-es'
  // import VabChart from '@/extra/VabChart'
  import request from '@/utils/request'

  export default defineComponent({
    components: {},
    setup() {
      // const colorList = ['#9E87FF', '#73DDFF', '#fe9a8b', '#F56948', '#9E87FF']
      const device = ref({})

      const getDeviceStatus = () => {
        request({
          url: '/statistics/deviceStatus',
          method: 'get',
        }).then((data) => {
          console.log(data)
          device.value = data
        })
      }

      onBeforeMount(() => {
        getDeviceStatus()
      })

      return {
        countConfig: {
          startVal: 0,
          endVal: random(1000, 6000),
          decimals: 0,
          prefix: '',
          suffix: '',
          separator: ',',
          duration: 3000,
        },
        device,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .order {
    margin-bottom: $base-margin;
    &-card1 {
      height: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        // height: 100%;
        .el-card {
          // height: 100%;
          &__header,
          &__body {
            // height: 156px;
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #60b2fb, #6485f6);
          }
          &__body {
            height: 256px;
          }
        }
      }
    }

    &-card2 {
      // height: 490px;
      margin-top: $base-margin;

      &-content {
        text-align: center;

        .order-chart {
          width: 100%;
          // height: 296px;
        }
      }
    }
  }
</style>
