<!--
 * @Author: WGL
 * @Date: 2023-11-17 10:10:34
 * @LastEditors: WGL
 * @LastEditTime: 2023-11-23 10:03:03
 * @Description: 
-->
<template>
  <div>
    <DesHeader class="mt15" title="产品供应商分布" />
    <div class="fxmc">
      <el-radio-group v-model="radio" @change="handleRadioChange">
        <el-radio-button
          v-for="(item, index) in radioList"
          :key="index"
          :label="item.value"
        >
          {{ item.label }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <el-row>
      <el-col>
        <ProductSupplierPie :data="detailData" :type="radio" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
    toRefs,
    onUnmounted,
    onMounted,
  } from 'vue'
  import ProductSupplierPie from './productSupplierPie.vue'
  import request from '@/utils/request'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'ProductSupplierStatistics',
    components: { ProductSupplierPie },
    props: {
      params: { type: Object, default: () => {} },
      info: { type: Object, default: () => {} },
    },
    setup(props) {
      console.log('props', props)
      const state = reactive({
        radio: 'count',
        radioList: [
          { label: t('采购次数'), value: 'count' },
          { label: t('采购数量'), value: 'num' },
          { label: t('采购金额'), value: 'amount' },
        ],
        ajaxLoading: false,
        detailData: [],
      })

      const handleGetAnalysis = (params = {}) => {
        state.ajaxLoading = true
        request({
          url: `/product/supplier/analyse`,
          method: 'get',
          params: {
            product_id: props?.info?.id,
            params,
          },
        })
          .then((res) => {
            console.log('res :>> ', res)
            state.detailData = res
            state.ajaxLoading = false
          })
          .catch(() => {
            state.ajaxLoading = false
          })
      }
      const handleRadioChange = (val) => {
        console.log('val handleRadioChange', val)
      }

      onUnmounted(() => {})
      onMounted(() => {
        handleGetAnalysis()
      })
      return {
        ...toRefs(state),
        handleRadioChange,
      }
    },
  })
</script>
