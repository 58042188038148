<!--
 * @Author: WGL
 * @Date: 2022-02-15 18:40:13
 * @LastEditors: WGL
 * @LastEditTime: 2024-09-24 14:27:21
 * @Description: 
-->
<template>
  <el-row class="vab-query-form" :gutter="0">
    <slot />
  </el-row>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'VabQueryForm',
  })
</script>

<style lang="scss" scoped>
  @mixin panel {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    min-height: $base-input-height;
    margin: 0 0 #{math.div($base-margin, 2)} 0;
    > .el-button {
      margin: 0 10px #{math.div($base-margin, 2)} 0 !important;
    }
  }

  .vab-query-form {
    :deep() {
      .el-form-item:first-child {
        margin: 0 0 #{math.div($base-margin, 2)} 0 !important;
      }

      .el-form-item + .el-form-item {
        margin: 0 0 #{math.div($base-margin, 2)} 0 !important;

        .el-button {
          margin: 0 0 0 10px !important;
        }
      }

      .top-panel {
        @include panel;
      }

      .bottom-panel {
        border-top: 1px solid #dcdfe6;
        @include panel;
      }

      .left-panel {
        @include panel;
      }

      .right-panel {
        justify-content: flex-end;
        @include panel;
      }
    }
  }
</style>
