/*
 * @Author: WGL
 * @Date: 2023-04-03 15:28:54
 * @LastEditors: WGL
 * @LastEditTime: 2023-11-24 18:25:52
 * @Description:
 */
export default {
  vabI18n: {
    首页: 'Ana Sayfa',
    看板: 'Pano',
    工作台: 'Çalışma Alanı',
    个人中心: 'Kişisel Merkez',
    Excel: 'Excel',
    错误页: 'Hata Sayfaları',
    403: '403',
    404: '404',
    邮件: 'E-posta',
    更多: 'Daha Fazla',
    退出登录: 'Çıkış Yap',
    登录: 'Giriş',
    验证码: 'Doğrulama Kodu',
    注册: 'Kayıt Ol',
    国际化: 'Uluslararasılaştırma',
    刷新: 'Yenile',
    通知: 'Bildirimler',
    全屏: 'Tam Ekran',
    页面动画: 'Sayfa Animasyonları',
    搜索: 'Ara',
    重置: 'Sıfırla',
    开启: 'Aç',
    关闭: 'Kapat',
    保存: 'Kaydet',
    欢迎来到: 'Hoş Geldiniz',
    请输入用户名: 'Kullanıcı Adınızı Girin',
    请输入密码: 'Şifrenizi Girin',
    用户名不能为空: 'Kullanıcı Adı Boş Bırakılamaz',
    密码不能少于6位: 'Şifre En Az 6 Karakter Olmalıdır',
    请输入正确的手机号: 'Lütfen Geçerli Bir Telefon Numarası Girin',
    请输入手机号: 'Lütfen Telefon Numarasını Girin',
    请输入手机验证码: 'Lütfen Telefon Doğrulama Kodunu Girin',
    获取验证码: 'Doğrulama Kodunu Al',
    大: 'Büyük',
    中: 'Orta',
    小: 'Küçük',
    基本信息: 'Temel Bilgiler',
    分类名称: 'Kategori Adı',
    请输入分类名称: 'Lütfen Kategori Adını Girin',
    排序: 'Sıralama',
    状态: 'Durum',
    备注: 'Not',
    请输入备注: 'Lütfen Notunuzu Girin',
    序号: 'Sıra No',
    规格: 'Özellikler',
    '重量/长-宽-高/体积': 'Ağırlık/Uzunluk-Genişlik-Yükseklik/Hacim',
    价格: 'Fiyat',
    '价格(元)': 'Fiyat (TL)',
    销量: 'Satış Miktarı',
    封面图: 'Kapak Resmi',
    图片: 'Resim',
    图标: 'Sembol',
    编码: 'Kod',
    OEM编码: 'OEM Kodu',
    '产品编码/OEM编码': 'Ürün Kodu/OEM Kodu',
    '编码超级搜索，多个编码换行隔开':
      'Kodları Süper Ara, Birden Fazla Kodu Satır Atlatarak Girin',
    库存: 'Stok',
    调出仓库: 'Çıkış Deposu',
    调入仓库: 'Giriş Deposu',
    '真实库存(锁定库存)': 'Gerçek Stok (Kilitli Stok)',
    采购价: 'Satın Alma Fiyatı',
    毛利率: 'Brüt Kar Marjı',
    客户报价: 'Müşteri Teklifi',
    报价状态: 'Teklif Durumu',
    来源: 'Kaynak',
    创建时间: 'Oluşturulma Tarihi',
    更新时间: 'Güncelleme Tarihi',
    使用状态: 'Kullanım Durumu',
    总价: 'Toplam Fiyat',
    操作: 'İşlem',
    名称: 'Ad',
    加载数据中: 'Veriler Yükleniyor...',
    系统: 'Sistem',
    确定: 'Onayla',
    确认: 'Onayla',
    取消: 'İptal',
    取消操作: 'İptal Et',
    '此操作将会永久删除数据，是否继续?':
      'Bu işlem verileri kalıcı olarak silecektir, devam etmek istiyor musunuz?',
    '确定要复制当前数据?':
      'Mevcut veriyi kopyalamak istediğinize emin misiniz?',
    请配置打印接口: 'Lütfen yazdırma arayüzünü yapılandırın!',
    暂无数据可导出: 'Dışa aktarılacak veri yok!',
    正在生成中: 'Oluşturuluyor...',
    请配置导出接口: 'Lütfen dışa aktarma arayüzünü yapılandırın!',
    '当前页面可能存在未保存内容，直接离开内容将不被保存':
      'Bu sayfada kaydedilmemiş içerikler olabilir, doğrudan ayrılırsanız içerik kaydedilmeyecektir',
    请配置上传的路径地址: 'Lütfen yükleme yolunu yapılandırın!',
    编辑: 'Düzenle',
    删除: 'Sil',
    新建: 'Yeni Ekle',
    提示: 'İpucu',
    '请配置导出接口！': 'Dışa Aktarma Arabirimini Yapılandırın!',
    '暂无数据可导出！': 'Dışa Aktarılabilir Veri Yok!',
    正在下载中: 'İndiriliyor...',
    文件: 'Dosya',
    '请配置文件上传接口！': 'Dosya Yükleme Arabirimini Yapılandırın!',
    '请配置编辑接口！': 'Düzenleme Arabirimini Yapılandırın!',
    '导出数据过多，已添加至异步导出队列生成':
      'Çok fazla veri dışa aktarıldı, asenkron dışa aktarma sırasına eklendi',
    请配置文件上传接口: 'Dosya Yükleme Arabirimini Yapılandırın!',
    请配置编辑接口: 'Düzenleme Arabirimini Yapılandırın!',
    请配置详情接口: 'Ayrıntılar Arabirimini Yapılandırın!',
    头像裁剪: 'Avatar Kırpma',
    选择图片: 'Resim Seç',
    上传并保存: 'Yükle ve Kaydet',
    返回: 'Geri',
    产品详情: 'Ürün Detayları',
    总计: 'Toplam',
    查询: 'Sorgula',
    单位: 'Birim',
    附件: 'Eklenti',
    仓库: 'Depo',
    手机号: 'Telefon Numarası',
    角色: 'Rol',
    权限: 'İzinler',
    是否负责人: 'Sorumlu Mu?',
    固定号码: 'Sabit Numara',
    上传文件: 'Dosya Yükle',
    '附件名称(点击预览)': 'Eklenti Adı (Önizlemek için Tıklayın)',
    附件类型: 'Eklenti Türü',
    附件大小: 'Eklenti Boyutu',
    上传人: 'Yükleyen',
    上传图片大小不能超过: 'Resim Yükleme Boyutu Aşmamalıdır:',
    最多上传: 'En Fazla Yükle:',
    您选择了: 'Seçtiniz:',
    '个文件,请重新选择': 'Dosya, lütfen yeniden seçin.',
    你确定要删除: 'Silmek istediğinizden emin misiniz?',
    上传时间: 'Yükleme Zamanı',
    明细: 'Detaylar',
    文件名称: 'Dosya Adı',
    请输入分类排序: 'Sıralamayı Girin',
    展开: 'Aç',
    合并: 'Birleştir',
    紧急程度: 'Aciliyet Derecesi',
    添加: 'Ekle',
    请选择: 'Lütfen Seçin',
    供应商: 'Tedarikçi',
    最近报价: 'Son Teklif',
    品牌: 'Marka',
    车型: 'Araç Modeli',
    请选择车型: 'Araç Modeli Seçin',
    部件: 'Parça',
    请选择部件: 'Parça Seçin',
    年份: 'Yıl',
    请选择年份: 'Yıl Seçin',
    发表评论: 'Yorum Yap',
    修改: 'Düzenle',
    '暂无评论~': 'Henüz yorum yok~',
    评论人员: 'Yorum Yapanlar',
    '暂无信息~': 'Henüz bilgi yok~',
    姓名: 'Ad',
    电话: 'Telefon',
    请输入电话: 'Telefon Numarası Girin',
    邮箱: 'E-posta',
    部门: 'Departman',
    职务: 'Görev',
    图表显示: 'Grafik Görünümü',
    指标值: 'Gösterge Değeri',
    日: 'Gün',
    月: 'Ay',
    年: 'Yıl',
    已选择: 'Seçilenler',
    请输入关键词: 'Anahtar Kelime Girin',
    使用选择: 'Seçimi Kullan',
    暂无内容: 'Henüz İçerik Yok',
    选择成功: 'Başarıyla Seçildi',
    跟进: 'Takip',
    已添加: 'Eklenen',
    库存不足: 'Stok Yetersiz',
    是: 'Evet',
    否: 'Hayır',
    请填写: 'Lütfen doldurun',
    币种: 'Para Birimi',
    创建人: 'Oluşturan Kişi',
    最近更新: 'Son Güncelleme',
    从素材库选择: 'Materyal Kütüphanesinden Seç',
    从素材库选: 'Materyal Kütüphanesinden Seç',
    在此处粘贴图片即上传: 'Buraya resim yapıştırın veya yükleyin',
    '上传图片只能是JPG,PNG,GIF,WEBP格式':
      'Sadece JPG, PNG, GIF, WEBP formatında resim yüklenebilir',
    只能上传一张图片: 'Sadece bir resim yüklenebilir',
    当前浏览器不支持本地图片粘贴:
      'Mevcut tarayıcı yerel resim yapıştırmayı desteklemiyor',
    粘贴内容非图片: 'Yapıştırılan içerik bir resim değil',
    上传文件大小不能超过: 'Yükleme dosya boyutu aşılamaz',
    正在上传中: 'Yükleniyor...',
    上传: 'Yükle',
    上传完成: 'Yükleme Tamamlandı',
    上传失败请重新上传: 'Yükleme Başarısız, Lütfen Tekrar Deneyin',
    正在导入中: 'Veri İçeri Aktarılıyor...',
    导入: 'İçeri Aktar',
    导出: 'Dışa Aktar',
    全选: 'Tümünü Seç',
    使用选中: 'Seçileni Kullan',
    一键抠图: 'Tek Tıkla Kes',
    抠图: 'Kes',
    不抠图: 'Kesme',
    完成: 'Tamamla',
    下载: 'İndir',
    查看: 'Görüntüle',
    暂无数据: 'Veri Yok',
    上级分类: 'Üst Kategori',
    添加分类: 'Kategori Ekle',
    编辑分类: 'Kategori Düzenle',
    删除分类: 'Kategori Sil',
    分类名称不能为空: 'Kategori Adı Boş Bırakılamaz',
    音频: 'Ses',
    只能选择一张图片: 'Yalnızca bir resim seçebilirsiniz',
    请配置分类接口: 'Kategori Arabirimini Yapılandırın!',
    全部: 'Tümü',
    请配置素材接口: 'Malzeme Arabirimini Yapılandırın!',
    请添加分类: 'Lütfen Kategori Ekleyin',
    请配置分类添加接口: 'Kategori Ekleme Arabirimini Yapılandırın',
    请配置分类编辑接口: 'Kategori Düzenleme Arabirimini Yapılandırın',
    请配置分类删除接口: 'Kategori Silme Arabirimini Yapılandırın',
    文件类型格式不正确: 'Dosya Türü Biçimi Doğru Değil!',
    大小不能超过: 'Büyüklüğü Aşamaz:',
    个文件请重新选择: 'Dosya, lütfen yeniden seçin.',
    请配置素材分类导出接口:
      'Malzeme Kategori Dışa Aktarma Arabirimini Yapılandırın!',
    立即新增: 'Hemen Ekle',
    上传视频大小不能超过: 'Video Yükleme Boyutu Aşamaz:',
    图标选择器: 'Simge Seçici',
    最后编辑: 'Son Düzenleme',
    步骤: 'Adım',
    标题: 'Başlık',
    审核: 'Denetleme',
    审核状态: 'Denetleme Durumu',
    请选择类型: 'Lütfen Tür Seçin',
    请选择审核状态: 'Lütfen Denetleme Durumu Seçin',
    通过: 'Onayla',
    不通过: 'Onaylama',
    付款状态: 'Ödeme Durumu',
    请选择付款状态: 'Lütfen Ödeme Durumu Seçin',
    发货状态: 'Sevkiyat Durumu',
    请选择发货状态: 'Lütfen Sevkiyat Durumu Seçin',
    提成金额: 'Komisyon Miktarı',
    请输入提成金额: 'Lütfen Komisyon Miktarını Girin',
    回款方式: 'Ödeme Yöntemi',
    请选择回款方式: 'Lütfen Ödeme Yöntemi Seçin',
    回款日期: 'Ödeme Tarihi',
    请输入回款日期: 'Lütfen Ödeme Tarihini Girin',
    流水单号: 'Hesap Özeti Numarası',
    请输入支付流水单号: 'Lütfen Ödeme Hesap Numarasını Girin',
    支付凭证: 'Ödeme Belgeleri',
    付款方式: 'Ödeme Yöntemi',
    请选择付款方式: 'Lütfen Ödeme Yöntemi Seçin',
    付款日期: 'Ödeme Tarihi',
    请输入付款日期: 'Lütfen Ödeme Tarihini Girin',
    下单日期: 'Sipariş Tarihi',
    请选择下单日期: 'Lütfen Sipariş Tarihi Seçin',
    请输入名称: 'Lütfen Adı Girin',
    保存并新增: 'Kaydet ve Yeni Ekle',
    您确定要对当前数据进行提审:
      'Mevcut veriyi denetlemek istediğinizden emin misiniz?',
    您确定要对当前数据进行提审并通过:
      'Mevcut veriyi denetlemek ve onaylamak istediğinizden emin misiniz?',
    '您确定要对当前状态进行撤销？撤销后可重新编辑提审':
      'Mevcut durumu geri almak istediğinizden emin misiniz? Geri aldıktan sonra yeniden düzenleme yapabilirsiniz.',
    您确定要对当前订单进行续单:
      'Mevcut siparişi yenilemek istediğinizden emin misiniz?',
    您确定要对当前订单生成出库单:
      'Mevcut siparişten çıkış belgesi oluşturmak istediğinizden emin misiniz?',
    邮件模版: 'E-posta Şablonu',
    生成预览: 'Önizleme Oluştur',
    表格: 'Tablo',
    取消附件: 'Eklemeyi İptal Et',
    单选: 'Tek Seçim',
    只看我的: 'Sadece Benimkini Gör',
    先发给自己: 'Önce Kendime Gönder',
    主题: 'Konu',
    请输入主题: 'Lütfen Konu Girin',
    请输入标题: 'Lütfen Başlık Girin',
    收件人: 'Alıcı',
    请选择收件人: 'Lütfen Alıcı Seçin',
    头部: 'Üst Kısım',
    尾部: 'Alt Kısım',
    发送: 'Gönder',
    下次联系时间: 'Sonraki İletişim Zamanı',
    跟进内容: 'İletişim İçeriği',
    上传附件: 'Ek Dosya Yükle',
    '是否删除这条跟进记录?': 'Bu iletişim kaydını silmek istiyor musunuz?',
    确定进入: 'Giriş Yap',
    阶段: 'Aşama',
    成员管理: 'Üye Yönetimi',
    成员: 'Üye',
    请选择成员: 'Lütfen Üye Seçin',
    只读: 'Sadece Okuma',
    读写: 'Okuma ve Yazma',
    负责人: 'Sorumlu Kişi',
    更换负责人: 'Sorumlu Kişiyi Değiştir',
    设置为负责人: 'Sorumlu Kişi Olarak Ayarla',
    请选择负责人: 'Lütfen Sorumlu Kişi Seçin',
    产品: 'Ürün',
    数量: 'Miktar',
    邮箱模版: 'E-posta Şablonu',
    表格模版: 'Tablo Şablonu',
    去添加: 'Ekleme Git',
    产品名称: 'Ürün Adı',
    请输入产品名称: 'Lütfen Ürün Adı Girin',
    产品分类: 'Ürün Kategorisi',
    请选择产品分类: 'Lütfen Ürün Kategorisi Seçin',
    产品类型: 'Ürün Türü',
    请选择产品类型: 'Lütfen Ürün Türü Seçin',
    请输入排序: 'Sıralama Girin',
    产品编码: 'Ürün Kodu',
    请输入产品编码: 'Lütfen Ürün Kodu Girin',
    请输入OEM编码: 'Lütfen OEM Kodu Girin',
    适用车型: 'Uygun Araç Modelleri',
    请选择适用车型: 'Lütfen Uygun Araç Modelleri Seçin',
    车身部件: 'Araç Gövde Parçası',
    请选择车身部件: 'Lütfen Araç Gövde Parçası Seçin',
    产品货币: 'Ürün Para Birimi',
    请选择产品货币单位: 'Lütfen Ürün Para Birimi Birimini Seçin',
    预警库存: 'Uyarı Stoku',
    '建议售价/最低售价/成本': 'Önerilen satış fiyatı/minimal satış fiyatı/maliyeti',
    外贸: 'Dış Ticaret',
    零售: 'Perakende',
    成本: 'Maliyet',
    '名称/编码/OEM编码': 'Ad/Kod/OEM Kodu',
    '重量/体积/长-宽-高': 'Ağırlık/Hacim/Uzunluk-Genişlik-Yükseklik',
    上下架: 'Yayınla/Kaldır',
    上架: 'Yayınla',
    下架: 'Kaldır',
    请选择单位: 'Lütfen Birim Seçin',
    建议售价: 'Satış fiyatı önerildi',
    最低售价: 'En düşük satış fiyatı',
    成本价: 'Maliyet Fiyatı',
    装箱量: 'Ambalaj Miktarı',
    箱规: 'Ambalaj Boyutu',
    '箱规-长(cm)': 'Ambalaj Boyutu - Uzunluk (cm)',
    '箱规-宽(cm)': 'Ambalaj Boyutu - Genişlik (cm)',
    '箱规-高(cm)': 'Ambalaj Boyutu - Yükseklik (cm)',
    '箱规-装箱量': 'Ambalaj Boyutu - Ambalaj Miktarı',
    '箱规-净重(kg)': 'Ambalaj Boyutu - Net Ağırlık (kg)',
    '箱规-毛重(kg)': 'Ambalaj Boyutu - Brüt Ağırlık (kg)',
    '箱规-体积(m³)': 'Ambalaj Boyutu - Hacim (m³)',
    '重量(kg)': 'Ağırlık (kg)',
    '单个-长(cm)': 'Tekil Ürün - Uzunluk (cm)',
    '单个-宽(cm)': 'Tekil Ürün - Genişlik (cm)',
    '单个-高(cm)': 'Tekil Ürün - Yükseklik (cm)',
    '单个-体积(m³)': 'Tekil Ürün - Hacim (m³)',
    '单个-净重(kg)': 'Tekil Ürün - Net Ağırlık (kg)',
    '单个-毛重(kg)': 'Tekil Ürün - Brüt Ağırlık (kg)',
    证书: 'Sertifika',
    建议尺寸比例: 'Tavsiye Edilen Boyut Oranı 1*1',
    重量: 'Ağırlık',
    体积: 'Hacim',
    分类: 'Kategori',
    '体积(m³)': 'Hacim (m³)',
    描述: 'Açıklama',
    请输入描述: 'Lütfen Açıklama Girin',
    图集: 'Resim Koleksiyonu',
    实施服务: 'Uygulama Hizmeti',
    请选择实施服务: 'Lütfen Uygulama Hizmeti Seçin',
    扩展信息: 'Genişletilmiş Bilgi',
    请选择供应商: 'Lütfen Tedarikçi Seçin',
    图片信息: 'Resim Bilgisi',
    请选择仓库: 'Lütfen Depo Seçin',
    请选择产品: 'Lütfen Ürün Seçin',
    入库: 'Depo Girişi',
    产品图片: 'Ürün Resmi',
    我方编码: 'Kendi Kodumuz',
    对方编码: 'Karşı Taraf Kodu',
    历史最低: 'Tarihsel En Düşük',
    近期价格: 'Son Dönem Fiyatları',
    历史最高: 'Tarihsel En Yüksek',
    联系人: 'İletişim Kişisi',
    详细地址: 'Ayrıntılı Adres',
    请选择车型品牌: 'Lütfen Araç Modeli Markası Seçin',
    请选择配置: 'Lütfen Yapılandırmayı Seçin',
    货源: 'Tedarikçi',
    产品概览: 'Ürün Genel Bakışı',
    配套产品: 'Eşleştirilmiş Ürünler',
    配套产品详情: 'Eşleştirilmiş Ürün Ayrıntıları',
    平替产品: 'Eş Değiştirme Ürünleri',
    价格策略: 'Fiyat Stratejisi',
    策略条件: 'Strateji Koşulları',
    数量大于等于: 'Miktar Büyük veya Eşit',
    单价为: 'Birim Fiyatı',
    类型: 'Tür',
    策略方式: 'Strateji Yöntemi',
    策略类型: 'Strateji Türü',
    请选择策略类型: 'Lütfen Strateji Türü Seçin',
    库存提示: 'Geçerli Stok Miktarı (Toplam Stok/Uyarı Stok)',
    包含产品: 'Ürünü İçerir',
    配置: 'Yapılandırma',
    导出原图: 'Orijinal Resmi Dışa Aktar',
    视频信息: 'Video Bilgisi',
    视频: 'Video',
    目标市场: 'Hedef Pazar',
    请选择目标市场: 'Lütfen Hedef Pazarı Seçin',
    产品货源: 'Ürün Kaynağı',
    请选择产品货源: 'Lütfen Ürün Kaynağını Seçin',
    上下架状态: 'Yayın Durumu',
    请选择产品上下架状态: 'Lütfen Ürün Yayın Durumunu Seçin',
    需要以字母开头: 'Başlangıçta Harf Olmalı',
    显示名称: 'Görünen Ad',
    请输入显示名称: 'Lütfen Görünen Adı Girin',
    用于生成电商网站时显示的分类标题:
      'E-ticaret Web Sitesinde Gösterilen Kategori Başlığı',
    分类url: 'Kategori URL',
    请输入字母或数字: 'Lütfen Harf veya Sayı Girin',
    '请输入分类url,如lights': `Lütfen Kategori URL'sini Girin, Örneğin "ışıklar"`,
    用于生成电商网站的链接: 'E-ticaret Web Sitesi Oluşturmak İçin Bağlantı',
    分类描述: 'Kategori Açıklaması',
    用于生成电商网站时SEO: 'E-ticaret Web Sitesi Oluştururken SEO',
    分类关键词: 'Kategori Anahtar Kelimeler',
    请输入分类: 'Lütfen Kategori Girin',
    // ==================================== 销售模块 ==============================
    内容: 'İçerik',
    跟进人: 'Takipçi',
    跟进时间: 'Takip Zamanı',
    添加成员: 'Üye Ekle',
    删除成员: 'Üye Sil',
    '请选择成员(可多选)': 'Üyeleri Seçin (Çoklu Seçim)',
    客户: 'Müşteri',
    客户名称: 'Müşteri Adı',
    请输入客户名称: 'Lütfen Müşteri Adı Girin',
    客户编码: 'Müşteri Kodu',
    成交单数: 'Tamamlanan Sipariş Sayısı',
    成交金额: 'Tamamlanan Tutar',
    回款金额: 'Alınan Tutar',
    客户来源: 'Müşteri Kaynağı',
    请选择客户来源: 'Lütfen Müşteri Kaynağı Seçin',
    客户行业: 'Müşteri Endüstrisi',
    请选择客户行业: 'Lütfen Müşteri Endüstrisi Seçin',
    客户等级: 'Müşteri Derecesi',
    请选择客户等级: 'Lütfen Müşteri Derecesi Seçin',
    下次跟进时间: 'Sonraki Takip Zamanı',
    地区: 'Bölge',
    请选择地区: 'Lütfen Bölge Seçin',
    请输入客户手机号: 'Lütfen Müşteri Telefon Numarası Girin',
    请输入邮箱地址: 'Lütfen E-posta Adresi Girin',
    地址: 'Adres',
    请输入客户地址: 'Lütfen Müşteri Adresi Girin',
    是否成交: 'Anlaşma Durumu',
    请选择是否成交: 'Lütfen Anlaşma Durumunu Seçin',
    客户详情: 'Müşteri Detayları',
    最后跟进时间: 'Son Takip Zamanı',
    微信: 'WeChat',
    请输入微信: 'Lütfen WeChat Numarası Girin',
    商机: 'İş Fırsatı',
    范围: 'Kapsam',
    请选择数据范围: 'Lütfen Veri Kapsamını Seçin',
    客户地区: 'Müşteri Bölgesi',
    团队成员: 'Takım Üyeleri',
    发票信息: 'Fatura Bilgileri',
    客户分析: 'Müşteri Analizi',
    客户订单分析: 'Müşteri Sipariş Analizi',
    请选择开始日期: 'Lütfen Başlangıç Tarihi Seçin',
    开始日期: 'Başlangıç Tarihi',
    结束日期: 'Bitiş Tarihi',
    退货产品: 'İade Ürünü',
    结束时间: 'Bitiş Zamanı',
    开始时间: 'Başlangıç Zamanı',
    核心数据: 'Anahtar Veriler',
    成交趋势: 'Anlaşma Eğilimi',
    产品分布: 'Ürün Dağılımı',
    产品排行: 'Ürün Sıralaması',
    销售前20产品: 'Satışta İlk 20 Ürün',
    历史订单: 'Geçmiş Siparişler',
    订单金额: 'Sipariş Tutarı',
    订单总金额: 'Toplam Sipariş Tutarı',
    订单笔数: 'Sipariş Sayısı',
    下单数量: 'Sipariş Miktarı',
    实收金额: 'Toplam Tahsilat',
    '当前订单收款总和，扣除退款': 'Toplam Tahsilat (İadeler Düşüldü)',
    退款金额: 'İade Tutarı',
    当前订单退款金额: 'Geçerli Sipariş İade Tutarı',
    未收回款: 'Tahsil Edilmemiş Tutar',
    '订单金额-已收金额': 'Sipariş Tutarı - Tahsil Edilen Tutar',
    已开票金额: 'Faturalandırılan Tutar',
    订单已开票的金额: 'Faturalandırılan Sipariş Tutarı',
    平均单价: 'Ortalama Fiyat',
    每笔订单平均单价: 'Her Siparişin Ortalama Fiyatı',
    排名: 'Sıralama',
    渠道来源: 'Kanal Kaynağı',
    金额占比: 'Tutar Oranı',
    订单占比: 'Sipariş Oranı',
    公司名称: 'Şirket Adı',
    请输入公司名称: 'Lütfen Şirket Adı Girin',
    税号: 'Vergi Numarası',
    请输入公司税号: 'Lütfen Şirket Vergi Numarası Girin',
    开户行: 'Açık Hesap Bankası',
    银行账号: 'Banka Hesap Numarası',
    账户信息: 'Hesap Bilgileri',
    发票抬头: 'Fatura Ünvanı',
    关联客户: 'İlişkilendirilmiş Müşteri',
    请选择客户: 'Lütfen Müşteriyi Seçin',
    货币币种: 'Para Birimi',
    开户银行: 'Açık Hesap Bankası',
    请输入开户银行全称: 'Lütfen Açık Hesap Bankası Tam Adını Girin',
    银行卡号: 'Banka Kartı Numarası',
    请输入银行卡号: 'Lütfen Banka Kartı Numarası Girin',
    请输入地址: 'Lütfen Adres Girin',
    退货商品为空表示只退款: 'İade Ürünü Boşsa Sadece İade Anlamına Gelir',
    请输入退货数量: 'Lütfen İade Miktarını Girin',
    产品明细: 'Ürün Detayları',
    请至少添加一个产品: 'Lütfen En Az Bir Ürün Ekleyin',
    // TODO: 2023-11-14 start
    商机名称: 'Fırsat Adı',
    请输入商机名称: 'Lütfen Fırsat Adı Girin',
    商机阶段: 'Fırsat Aşaması',
    请选择商机阶段: 'Lütfen Fırsat Aşamasını Seçin',
    商机属性: 'Fırsat Özelliği',
    请选择商机属性: 'Lütfen Fırsat Özelliğini Seçin',
    商机状态: 'Fırsat Durumu',
    请选择商机状态: 'Lütfen Fırsat Durumunu Seçin',
    预计金额: 'Tahmini Tutar',
    请输入预计成交金额: 'Lütfen Tahmini Anlaşma Tutarını Girin',
    请选择货币: 'Lütfen Para Birimini Seçin',
    预计成交: 'Tahmini Anlaşma',
    请输入预计成交日期: 'Lütfen Tahmini Anlaşma Tarihini Girin',
    结束: 'Sonlandır',
    商机详情: 'Fırsat Detayları',
    预计成交金额: 'Tahmini Anlaşma Tutarı',
    预计成交时间: 'Tahmini Anlaşma Zamanı',
    // ==================================== 报价单模块 ==============================
    报价单: 'Teklif Formu',
    请选择报价单: 'Lütfen Teklif Formu Seçin',
    报价详情: 'Teklif Detayları',
    单号: 'Sipariş Numarası',
    复用价格: 'Fiyatı Kopyala',
    时间: 'Zaman',
    我的报价: 'Benim Tekliflerim',
    '标题/编号': 'Başlık/Numara',
    订单Id: 'Sipariş ID',
    客户名: 'Müşteri Adı',
    单价: 'Birim Fiyat',
    请选择出入库类型: 'Lütfen Giriş/Çıkış Türünü Seçin',
    所有客户: 'Tüm Müşteriler',
    当前客户: 'Geçerli Müşteri',
    时间范围: 'Zaman Aralığı',
    价格范围: 'Fiyat Aralığı',
    请选择状态: 'Lütfen Durumu Seçin',
    关联商机: 'Fırsatı İlişkilendir',
    请选择商机: 'Lütfen Fırsatı Seçin',
    请选择币种: 'Lütfen Para Birimini Seçin',
    生成订单: 'Sipariş Oluştur',
    交付日期: 'Teslimat Tarihi',
    '订单交付/发货日期': 'Sipariş Teslim/Teslimat Tarihi',
    请选择交付日期: 'Lütfen Teslimat Tarihini Seçin',
    报价单名称: 'Teklif Formu Adı',
    报价单编码: 'Teklif Formu Kodu',
    产品数量: 'Ürün Miktarı',
    订单货币: 'Sipariş Para Birimi',
    编号: 'Numara',
    金额: 'Tutar',
    销售金额: 'Satış Tutarı',
    销售订单: 'Satış Siparişi',
    订单数量: 'Sipariş Miktarı',
    产品列表: 'Ürün Listesi',
    订单详情: 'Sipariş Detayları',
    发货计划: 'Teslimat Planı',
    回款计划: 'Tahsilat Planı',
    回款明细: 'Tahsilat Detayları',
    出入库记录: 'Stok Giriş/Çıkış Kayıtları',
    服务明细: 'Hizmet Detayları',
    绩效明细: 'Performans Detayları',
    开票计划: 'Fatura Planı',
    '退/换货': 'İade/Değişim',
    合同: 'Sözleşme',
    应收款: 'Tahsil Edilecek Tutar',
    未收款: 'Tahsil Edilmemiş Tutar',
    已收金额: 'Tahsil Edilen Tutar',
    '当前订单收款总和，不扣除退款':
      'Geçerli Siparişin Toplam Tahsilatı (İade Düşülmeden)',
    已退金额: 'İade Edilen Tutar',
    实收回款: 'Gerçekleşen Tahsilat',
    退款中金额: 'İade Edilmeyi Bekleyen Tutar',
    订单退款审批中金额: 'Sipariş İade Onay Bekleyen Tutar',
    '金额(应收/实收)': 'Tutar (Tahsil Edilecek/Gerçekleşen)',
    下单时间: 'Sipariş Tarihi',
    发货时间: 'Teslimat Tarihi',
    请选择发货时间: 'Lütfen Teslimat Tarihini Seçin',
    订单: 'Sipariş',
    签单类型: 'Anlaşma Türü',
    生成任务时的开始日期: 'Görev Oluşturulduğunda Başlangıç Tarihi',
    单据日期: 'Belge Tarihi',
    订单成交时日期: 'Siparişin Tamamlanma Tarihi',
    '订单退/换货': 'Sipariş İade/Değişim',
    请输入退款金额: 'Lütfen İade Tutarını Girin',
    退款金额为0表示只退货:
      'İade Tutarı 0 İse Sadece Ürün İade Edildi Anlamına Gelir',
    退款方式: 'İade Yöntemi',
    请选择退款方式: 'Lütfen İade Yöntemi Seçin',
    退款日期: 'İade Tarihi',
    请选择退款日期: 'Lütfen İade Tarihini Seçin',
    补差金额: 'Eksik Tutar',
    请输入补差金额: 'Lütfen Eksik Tutarı Girin',
    补差金额为0表示只换货:
      'Eksik Tutarı 0 İse Sadece Ürün Değiştirildi Anlamına Gelir',
    补差方式: 'Eksik Tutar Yöntemi',
    请选择补差方式: 'Lütfen Eksik Tutar Yöntemi Seçin',
    补差日期: 'Eksik Tutar Tarihi',
    请选择补差计划日期: 'Lütfen Eksik Tutar Planı Tarihini Seçin',
    换货商品为空表示只补差金额:
      'Değişim Ürünü Boşsa Sadece Eksik Tutar Anlamına Gelir',
    '退/换货备注': 'İade/Değişim Notu',
    请输入退货备注: 'Lütfen İade Notunu Girin',
    订单名称: 'Sipariş Adı',
    订单编码: 'Sipariş Kodu',
    发货日期: 'Teslimat Tarihi',
    包装要求: 'Paketleme Gereksinimleri',
    规格说明: 'Teknik Özellikler',
    价格条款: 'Fiyat Koşulları',
    订单进度: 'Sipariş İlerlemesi',
    // ==================================== 仓库模块 ==============================
    备货中: 'Stok Hazırlanıyor',
    备货完成: 'Stok Hazır',
    业务员: 'Satış Temsilcisi',
    计划时间: 'Planlanan Zaman',
    备货状态: 'Stok Durumu',
    发货计划详情: 'Sevkiyat Planı Detayları',
    发货进度: 'Sevkiyat İlerlemesi',
    计划日期: 'Planlanan Tarih',
    采购计划: 'Satın Alma Planı',
    箱单: 'Kutu Listesi',
    发货单: 'Sevkiyat Siparişi',
    请选择销售订单: 'Satış Siparişi Seçiniz',
    交货日期: 'Teslimat Tarihi',
    采购状态: 'Satın Alma Durumu',
    请选择采购状态: 'Satın Alma Durumu Seçiniz',
    添加采购计划: 'Satın Alma Planı Ekle',
    请输入采购数量: 'Satın Alma Miktarını Giriniz',
    添加到采购单: 'Satın Alma Siparişine Ekle',
    换货产品: 'Değiştirme Ürünleri',
    换货商品为空表示只补差额:
      'Değiştirme Ürünü Boşsa Sadece Farkı Karşıla Anlamına Gelir',
    出入库: 'Stok Giriş/Çıkış',
    出库: 'Stok Çıkışı',
    日期: 'Tarih',
    入库类型: 'Stok Giriş Türü',
    出库类型: 'Stok Çıkış Türü',
    出入库类型: 'Stok Giriş/Çıkış Türü',
    供应商名称: 'Tedarikçi Adı',
    '供应商/客户': 'Tedarikçi/Müşteri',
    盘点状态: 'Stok Sayım Durumu',
    '未作废数据删除，库存将不会恢复是否删除':
      'İptal Edilmemiş Veriler Silinirse, Stok Geri Yüklenmeyecektir. Silmek İstediğinize Emin Misiniz?',
    '此操作将会永久删除数据，是否继续':
      'Bu İşlem Verileri Kalıcı Olarak Silecek, Devam Etmek İstiyor Musunuz?',
    您确定要作废当前数据:
      'Şu Anki Veriyi İptal Etmek İstediğinizden Emin Misiniz?',
    // TODO: 2023-11-15 start
    '金额(应付/实付)': 'Tutar (Ödenmesi Gereken/Gerçekleşen)',
    实付金额: 'Gerçekleşen Tutar',
    '当前订单付款总和，扣除退款':
      'Geçerli Siparişin Toplam Ödemesi (İade Düşülmeden)',
    未付金额: 'Ödenmemiş Tutar',
    采购: 'Satın Alma',
    采购单详情: 'Satın Alma Siparişi Detayları',
    采购进度: 'Satın Alma İlerlemesi',
    付款计划: 'Ödeme Planı',
    付款明细: 'Ödeme Detayları',
    采购数量: 'Satın Alma Miktarı',
    // ==================================== 供应商模块 ==============================
    供应商详情: 'Tedarikçi Detayları',
    '确认删除？': 'Silme işlemini onaylayın?',
    消息: 'Mesaj',
    暂无评论: 'Henüz yorum yok',
    暂无信息: 'Henüz bilgi yok',
    素材选择: 'Malzeme Seçimi',
    '文件已存在下载队列中！': 'Dosya indirme sırasında zaten var!',
    新增故事: 'Yeni Hikaye Ekle',
    请输入内容: 'Lütfen içerik girin',
    只能选择一张: 'Yalnızca bir tane seçebilirsiniz',
    请选择邮件模板: 'Lütfen e-posta şablonunu seçin',
    '正在生成中...': 'Oluşturuluyor...',
    '该附件格式不支持预览，请下载后查看':
      'Bu ek formatı önizlemeyi desteklemiyor, lütfen indirdikten sonra görüntüleyin',
    设备: 'Cihaz',
    总数: 'Toplam',
    在线: 'Çevrimiçi',
    离线: 'Çevrimdışı',
    故障: 'Arızalı',
    在线率: 'Çevrimiçi Oranı',
    故障率: 'Arıza Oranı',
    '确认要离开本页面吗？': 'Bu sayfadan ayrılmak istediğinizden emin misiniz?',
    历史报价: 'Geçmiş Teklifler',
    '产品名称/编码': 'Ürün Adı/Kodu',
    '智慧商贸-ERP': 'Akıllı Ticaret-ERP',
    '正在加载中...': 'Yükleniyor...',
    简体中文: '简体中文',
    繁體中文: '繁體中文',
    已创建: 'Oluşturuldu',
    进行中: 'Devam Ediyor',
    已完成: 'Tamamlandı',
    折线图: 'Çizgi Grafiği',
    柱状图: 'Sütun Grafiği',
    周: 'Hafta',
    已作废: 'İptal Edildi',
    已过期: 'Süresi Dolmuş',
    编辑中: 'Düzenleniyor',
    待支付: 'Ödeme Bekleniyor',
    已付定金: 'Peşinat Ödendi',
    已支付: 'Ödendi',
    已发货: 'Gönderildi',
    已签收: 'Teslim Alındı',
    '报价单-出口': 'Teklif Formu - İhracat',
    '报价单-内销': 'Teklif Formu - İç Satış',
    '报价单-门市': 'Teklif Formu - Mağaza',
    '报价单-淘宝': 'Teklif Formu - Taobao',
    '报价单-云工厂': 'Teklif Formu - Cloud Factory',
    '报价单-智能工厂': 'Teklif Formu - Akıllı Fabrika',
    禁用: 'Devre Dışı',
    启用: 'Etkin',
    隐藏: 'Gizle',
    显示: 'Göster',
    未知: 'Bilinmiyor',
    抖音: 'TikTok',
    快手: 'Kwai',
    待确定: 'Onay Bekliyor',
    有效: 'Geçerli',
    无效: 'Geçersiz',
    推广微信公众号: 'WeChat Hesabını Tanıt',
    推广抖音账号: 'TikTok Hesabını Tanıt',
    推广抖音视频: 'TikTok Videosunu Tanıt',
    推广快手账号: 'Kwai Hesabını Tanıt',
    推广快手视频: 'Kwai Videosunu Tanıt',
    销售收入: 'Satış Geliri',
    押金收入: 'Teminat Geliri',
    租赁收入: 'Kiralama Geliri',
    购买支出: 'Satın Alma Harcamaları',
    押金退还: 'Teminat İadesi',
    男: 'Erkek',
    女: 'Kadın',
    保密: 'Gizli',
    待处理: 'İşlem Bekliyor',
    处理中: 'İşlemde',
    已处理: 'İşlendi',
    文本框: 'Metin Kutusu',
    多行文本框: 'Çoklu Satır Metin Kutusu',
    数字文本框: 'Sayısal Metin Kutusu',
    日期选择框: 'Tarih Seçim Kutusu',
    状态选择: 'Durum Seçimi',
    单选框: 'Tekli Seçenek Kutusu',
    多选框: 'Çoklu Seçenek Kutusu',
    下拉单选择: 'Açılır Tekli Seçim',
    下拉多选择: 'Açılır Çoklu Seçim',
    单图片: 'Tekli Resim',
    多图片: 'Çoklu Resim',
    日期选择: 'Tarih Seçimi',
    未读: 'Okunmamış',
    已读: 'Okunmuş',
    待审核: 'Onay Bekliyor',
    私有: 'Özel',
    公海: 'Ortak Sahil',
    无: 'Yok',
    低: 'Düşük',
    高: 'Yüksek',
    离职: 'Ayrıldı',
    在职: 'Görevde',
    销售: 'Satış',
    按数量区间设置: 'Miktar Aralığına Göre Ayarla',
    '按客户/供应商设置': 'Müşteri/Tedarikçiye Göre Ayarla',
    待接受: 'Kabul Bekliyor',
    已接受: 'Kabul Edildi',
    不接受: 'Kabul Edilmedi',
    公司: 'Şirket',
    银行账户: 'Banka Hesabı',
    单位地址: 'Birim Adresi',
    '千美元)': 'Bin Amerikan Doları)',
    宽松默认: 'Varsayılan Gevşek',
    严格模式: 'Katı Mod',
    '支持jpg、jpeg、png格式，单次可最多选择':
      'jpg, jpeg, png Formatını Destekler, Tek Seferde En Fazla Seçebilirsiniz',
    '张图片，每张不可大于': 'resim, her biri büyük olmamalıdır',
    如果大于: 'Büyükse',
    会自动为您过滤: 'Otomatik olarak filtreler',
    查看大图: 'Büyük Resmi Görüntüle',
    '正在上传中...': 'Yükleniyor...',
    当前上传成功数: 'Şu Anda Başarıyla Yüklenen',
    张: 'resim',
    当前上传失败数: 'Şu Anda Başarısız Yüklenen',
    开始上传: 'Yüklemeye Başla',
    '上传完成!': 'Yükleme Tamamlandı!',
    共上传: 'Toplam Yükleme',
    张图片: 'resim',
    '上传失败,文件大小为': 'Yükleme Başarısız, Dosya Boyutu',
    当前限制选择: 'Şu Anda Seçim Sınırı',
    '个文件，本次选择了': 'Dosya, bu sefer seçildi',
    个文件: 'dosya',
    密码不能少于: 'Şifre En Az Olmalıdır',
    位: 'karakter',
    销售前: 'Satış Öncesi',
    更新日志: 'Güncelleme Günlüğü',
    用户管理: 'Kullanıcı Yönetimi',
    角色管理: 'Rol Yönetimi',
    部门管理: 'Departman Yönetimi',
    菜单管理: 'Menü Yönetimi',
    系统日志: 'Sistem Günlüğü',
    组件: 'Bileşenler',
    其他: 'Diğer',
    角色权限: 'Rol İzinleri',
    外链: 'Harici Bağlantı',
    常规图标: 'Normal İkonlar',
    小清新图标: 'Yeni Temiz İkonlar',
    综合表格: 'Genel Tablo',
    行内编辑表格: 'Satır İçi Düzenleme Tablosu',
    自定义表格: 'Özel Tablo',
    表单: 'Form',
    综合表单: 'Genel Form',
    分步表单: 'Adım Adım Form',
    按钮: 'Düğme',
    文字链接: 'Metin Bağlantısı',
    输入框: 'Giriş Kutusu',
    计数器: 'Sayıcı',
    选择器: 'Seçici',
    开关: 'Anahtar',
    滑块: 'Kaydırıcı',
    时间选择器: 'Zaman Seçici',
    日期选择器: 'Tarih Seçici',
    日期时间选择器: 'Tarih ve Zaman Seçici',
    评分: 'Puan Verme',
    工作流: 'İş Akışı',
    图表: 'Grafikler',
    打印: 'Yazdır',
    手机预览: 'Mobil Önizleme',
    时间线: 'Zaman Çizgisi',
    数字自增长: 'Sayısal Artış',
    多标签: 'Çoklu Etiketler',
    动态Meta: 'Dinamik Meta',
    动态路径参数: 'Dinamik Yol Parametreleri',
    列表: 'Liste',
    多级路由缓存: 'Çoklu Düzeyli Yol Önbelleği',
    多级路由: 'Çoklu Düzeyli Yollar',
    拖拽: 'Sürükle ve Bırak',
    卡片拖拽: 'Kart Sürükle ve Bırak',
    加载: 'Yükleme',
    视频播放器: 'Video Oynatıcı',
    编辑器: 'Editör',
    富文本编辑器: 'Zengin Metin Editörü',
    错误日志模拟: 'Hata Günlüğü Simülasyonu',
    导出Excel: 'Excel Dışa Aktar',
    导出选中行Excel: "Seçili Satırları Excel'e Aktar",
    导出合并Excel: "Birleştirilmiş Excel'i Aktar",
    动画: 'Animasyonlar',
    第三方登录: 'Üçüncü Taraf Girişi',
    物料源: 'Malzeme Kaynağı',
    物料市场: 'Malzeme Pazarı',
    清空消息: 'Mesajları Temizle',
    关闭其他: 'Diğerlerini Kapat',
    关闭左侧: 'Sol Tarafı Kapat',
    关闭右侧: 'Sağ Tarafı Kapat',
    关闭全部: 'Tümünü Kapat',
    主题配置: 'Tema Ayarları',
    常用设置: 'Genel Ayarlar',
    其它设置: 'Diğer Ayarlar',
    分栏布局时生效: 'Sütun Düzeni Kullanıldığında Geçerlidir',
    标签开启时生效: 'Etiketler Açık Olduğunda Geçerlidir',
    '布局配置仅在电脑视窗下生效，手机视窗时将默认锁定为纵向布局':
      'Düzen Ayarları Yalnızca Bilgisayar Ekranında Geçerlidir, Telefon Ekranında Otomatik Olarak Dikey Düzen Olarak Kilitlenir',
    默认: 'Varsayılan',
    海洋之心: 'Deniz Kalbi',
    海洋之心sss: 'Deniz Kalbi SSS',
    绿荫草场: 'Yeşil Çayır',
    碰触纯白: 'Saf Beyaz',
    月上重火: 'Ayın Üstünde Ateş',
    蓝黑: 'Mavi Siyah',
    蓝白: 'Mavi Beyaz',
    绿黑: 'Yeşil Siyah',
    绿白: 'Yeşil Beyaz',
    红黑: 'Kırmızı Siyah',
    红白: 'Kırmızı Beyaz',
    渐变: 'Gradyan',
    布局: 'Düzen',
    分栏: 'Sütunlar',
    纵向: 'Dikey',
    横向: 'Yatay',
    综合: 'Genel',
    常规: 'Standart',
    浮动: 'Sürekli',
    菜单背景: 'Menü Arkaplanı',
    分栏风格: 'Sütun Stili',
    箭头: 'Oklar',
    头部固定: 'Üst Sabit',
    固定: 'Sabit',
    不固定: 'Sabit Değil',
    标签: 'Etiketler',
    标签风格: 'Etiket Stili',
    标签图标: 'Etiket İkonları',
    卡片: 'Kartlar',
    灵动: 'Canlı',
    圆滑: 'Yumuşak',
    进度条: 'İlerleme Çubuğu',
    随机换肤: 'Rastgele Tema Değiştirme',
    购买源码: 'Kaynak Kodu Satın Al',
    拷贝源码: 'Kaynak Kodunu Kopyala',
    清理缓存: 'Önbelleği Temizle',
    恢复默认: 'Varsayılana Geri Dön',
    '检测到新版本，正在下载中，请稍后...':
      'Yeni bir sürüm bulundu, indiriliyor, lütfen bekleyin...',
    温馨提示: 'Dikkat',
    更新版本完成: 'Güncelleme Tamamlandı',
    后刷新项目: 'Proje Sonra Yeniden Yüklenecektir',
    我的待办: 'Bekleyen İşlerim',
    已办事项: 'Tamamlanan İşler',
    经营分析: 'İşletme Analizi',
    决策辅助: 'Karar Destek',
    采购订单: 'Satın Alma Siparişleri',
    供应管理: 'Tedarik Yönetimi',
    客户管理: 'Müşteri Yönetimi',
    商机管理: 'Fırsat Yönetimi',
    对外报价: 'Dışa Fiyatlandırma',
    财务: 'Finans',
    仓库管理: 'Depo Yönetimi',
    收款计划: 'Tahsilat Planı',
    收款明细: 'Tahsilat Detayları',
    开票详情: 'Fatura Detayları',
    库存盘点: 'Stok Sayımı',
    库存报表: 'Stok Raporları',
    库存余额: 'Stok Bakiyesi',
    收发明细: 'Alım ve Gönderim Detayları',
    收发汇总: 'Alım ve Gönderim Toplamı',
    单据列表: 'Belge Listesi',
    产品入库: 'Ürün Girişi',
    产品出库: 'Ürün Çıkışı',
    产品调拨: 'Ürün Transferi',
    产品组装: 'Ürün Montajı',
    产品拆卸: 'Ürün Demontajı',
    绩效: 'Performans',
    我的绩效: 'Benim Performansım',
    团队绩效: 'Takım Performansı',
    绩效核定: 'Performans Onayı',
    绩效规则: 'Performans Kuralları',
    任务: 'Görevler',
    任务列表: 'Görev Listesi',
    电商: 'E-Ticaret',
    首页设置: 'Ana Sayfa Ayarları',
    商品: 'Ürünler',
    轮播图: 'Slider',
    文章管理: 'Makale Yönetimi',
    设置: 'Ayarlar',
    公司信息: 'Şirket Bilgileri',
    销售配置: 'Satış Ayarları',
    产品配置: 'Ürün Ayarları',
    产品素材: 'Ürün Materyali',
    模版管理: 'Şablon Yönetimi',
    扩展字段: 'Genişletilmiş Alanlar',
    系统设置: 'Sistem Ayarları',
    操作日志: 'İşlem Kayıtları',
    公司列表: 'Şirket Listesi',
    用户列表: 'Kullanıcı Listesi',
    权限管理: 'İzin Yönetimi',
    行业管理: 'Sektör Yönetimi',
    资源: 'Kaynaklar',
    店铺选址: 'Mağaza Seçimi',
    教程管理: 'Eğitim Yönetimi',
    超级管理员: 'Süper Yönetici',
    '没有菜单权限！': 'Menü Yetkiniz Yok!',
    '路由格式返回有误！': 'Rota Formatı Yanlış Döndü!',
    运行中: 'Çalışıyor',
    已下线: 'Çevrimdışı',
    游客: 'Ziyaretçi',
    '未开启登录拦截)': 'Giriş Engellemesi Açılmadı)',
    早上好: 'Günaydın',
    上午好: 'İyi sabahlar',
    中午好: 'İyi öğlenler',
    下午好: 'İyi günler',
    晚上好: 'İyi akşamlar',
    欢迎登录: 'Hoş geldiniz',
    '登录接口异常，未正确返回': 'Giriş Arayüzü Anormal, Doğru Dönüş Yapmadı',
    '核心接口异常，请检查返回JSON格式是否正确，是否正确返回':
      'Çekirdek Arayüz Anormal, JSON Formatı Doğru Mu Kontrol Edin, Doğru Mu Dönüş Yaptı',
    '核心接口异常，请检查返回JSON格式是否正确':
      'Çekirdek Arayüz Anormal, JSON Formatı Doğru Mu Kontrol Edin',
    复制: 'Kopyala',
    成功: 'Başarılı',
    失败: 'Başarısız',
    '重量/毛重': 'Ağırlık/Brüt Ağırlık',
    产品证书: 'Ürün Sertifikası',
    推荐价格: 'Tavsiye Edilen Fiyat',
    邮编: 'Posta Kodu',
    传真: 'Faks',
    官网: 'Resmi Web Sitesi',
    企业状态: 'Şirket Durumu',
    营收: 'Gelir',
    员工人数: 'Çalışan Sayısı',
    公司类型: 'Şirket Türü',
    所属国家: 'Ülke',
    所属城市: 'Şehir',
    简介: 'Hakkında',
    产品服务: 'Ürün Hizmetleri',
    请输入: 'Lütfen girin',
    产品价格策略: 'Ürün Fiyatlandırma Stratejisi',
    起始价格: 'Başlangıç Fiyatı',
    分类logo: 'Kategori Logosu',
    建议尺寸: 'Önerilen Boyut',
    分类banner: 'Kategori Bannerı',
    请输入分类描述: 'Kategori Açıklamasını Girin',
    退款金额为: 'İade Tutarı',
    表示只退货: 'yalnızca ürün iadesi',
    补差金额为: 'Düzeltme Tutarı',
    表示只换货: 'yalnızca ürün değişimi',
    请输入客户编码: 'Müşteri Kodunu Girin',
    请输入联系人: 'Lütfen kişiyi girin',
    请输入客户Whats: "Müşteri Whats'i girin",
    规则名称: 'Kurallar Adı',
    请输入规则名称: 'Kurallar Adını Girin',
    绩效类型: 'Performans Türü',
    请选择绩效类型: 'Performans Türünü Seçin',
    优先级: 'Öncelik',
    请输入优先级: 'Önceliği Girin',
    请输入编码: 'Kodu Girin',
    请选择分类: 'Kategoriyi Seçin',
    请输入详细地址: 'Detaylı Adresi Girin',
    固定电话: 'Sabit Telefon',
    请输入固定电话: 'Sabit Telefonu Girin',
    请输入邮箱: 'Lütfen e-postayı girin',
    请输入QQ: "Lütfen QQ'yu girin",
    网址: 'Web Sitesi',
    请输入网址: 'Web Sitesini Girin',
    添加到采购计划: 'Satın Alma Planına Ekle',
    请输入数量: 'Lütfen miktarı girin',
    选择负责人: 'Sorumlu kişiyi seçin',
    服务年限: 'Hizmet Süresi',
    '请输入服务年限(报告年限)': 'Hizmet süresini girin (rapor süresi)',
    任务日期: 'Görev Tarihi',
    请选择任务日期: 'Görev tarihini seçin',
    请输入任务金额: 'Görev tutarını girin',
    请选择关联销售订单: 'İlgili satış siparişini seçin',
    请输入回款金额: 'Ödeme miktarını girin',
    请选择回款日期: 'Ödeme tarihini seçin',
    批量生成回款计划: 'Toplu ödeme planı oluştur',
    首次回款日期: 'İlk ödeme tarihini seçin',
    请选择首次回款日期: 'İlk ödeme tarihini seçin',
    回款间隔时间: 'Ödeme aralığı süresi',
    请输入回款间隔时间: 'Ödeme aralığı süresini girin',
    总回款期数: 'Toplam ödeme dönemi sayısı',
    请输入总回款期数: 'Toplam ödeme dönemi sayısını girin',
    请选择关联回款计划: 'İlgili ödeme planını seçin',
    渠道: 'Kanal',
    请选择渠道: 'Kanalı seçin',
    凭证: 'Belge',
    请选择采购订单: 'Satın alma siparişini seçin',
    请选择关联采购订单: 'İlgili satın alma siparişini seçin',
    付款金额: 'Ödeme tutarı',
    请输入付款金额: 'Ödeme tutarını girin',
    请选择付款日期: 'Ödeme tarihini seçin',
    批量生成付款计划: 'Toplu ödeme planı oluştur',
    首次付款日期: 'İlk ödeme tarihini seçin',
    请选择首次付款日期: 'İlk ödeme tarihini seçin',
    付款间隔时间: 'Ödeme aralığı süresi',
    请输入付款间隔时间: 'Ödeme aralığı süresini girin',
    总付款期数: 'Toplam ödeme dönemi sayısı',
    请输入总付款期数: 'Toplam ödeme dönemi sayısını girin',
    请选择关联付款计划: 'İlgili ödeme planını seçin',
    请选择发票抬头: 'Fatura başlığını seçin',
    开票金额: 'Fatura tutarı',
    请输入开票金额: 'Fatura tutarını girin',
    含税金额: 'Vergi dahil tutar',
    商品名称: 'Ürün adı',
    请输入商品名称: 'Ürün adını girin',
    商品编号: 'Ürün numarası',
    请输入商品编号: 'Ürün numarasını girin',
    规格型号: 'Ürün özellikleri ve modeli',
    请输入商品规格型号: 'Ürün özellikleri ve modelini girin',
    商品单位: 'Ürün birimi',
    请输入商品单位: 'Ürün birimini girin',
    发票类型: 'Fatura türü',
    请选择发票类型: 'Fatura türünü seçin',
    税率: 'Vergi oranı',
    请输入税率: 'Vergi oranını girin',
    商品数量: 'Ürün miktarı',
    请输入商品数量: 'Ürün miktarını girin',
    开票日期: 'Fatura tarihi',
    请选择开票日期: 'Fatura tarihini seçin',
    开票明细: 'Fatura detayları',
    员工离职交接: 'Personel istifası devir teslimi',
    交接人: 'Devralan kişi',
    请选择交接人: 'Devralan kişiyi seçin',
    单据时间: 'Belge tarihi',
    请选择单据时间: 'Belge tarihini seçin',
    箱数: 'Koli sayısı',
    请输入预估箱数: 'Tahmini koli sayısını girin',
    装箱预估数量: 'Tahmini ambalaj miktarı',
    出库单: 'Çıkış belgesi',
    请选择出库单: 'Çıkış belgesini seçin',
    正: 'Düz',
    侧: 'Yan',
    唛信息: 'Marka bilgisi',
    目的地: 'Varış yeri',
    请输入收货目的地: 'Teslimat yerini girin',
    箱号标识: 'Koli numarası işareti',
    '如M、B': 'Örneğin M, B',
    产地: 'Menşe',
    请输入产地: 'Menşe bilgisini girin',
    订单号: 'Sipariş numarası',
    将自动填充: 'Otomatik doldurulacak',
    净重: 'Net ağırlık',
    毛重: 'Brüt ağırlık',
    产品盘点: 'Ürün envanteri',
    请选择单据日期: 'Belge tarihini seçin',
    组装: 'Montaj',
    拆卸: 'Sökme',
    请输选择: 'Lütfen seçin',
    入库仓库: 'Giriş deposu',
    出库仓库: 'Çıkış deposu',
    费用: 'Maliyet',
    存为模版: 'Şablona kaydet',
    拆卸产品: 'Sökülen ürün',
    请选择拆卸产品: 'Sökülen ürünü seçin',
    图片名称: 'Resim adı',
    请输入图片: 'Resim adını girin',
    链接地址: 'Bağlantı adresi',
    请输入图片链接地址: 'Resim bağlantı adresini girin',
    文章: 'Makale',
    文章标题: 'Makale başlığı',
    请输入文章标题: 'Makale başlığını girin',
    文章类型: 'Makale türü',
    请选择文章类型: 'Makale türünü seçin',
    文章内容: 'Makale içeriği',
    请输入文章内容: 'Makale içeriğini girin',
    提单详情: 'Taşıma detayı',
    采购商: 'Alıcı',
    产品信息: 'Ürün Bilgisi',
    总金额: 'Toplam Tutar',
    产品描述: 'Ürün Açıklaması',
    货运信息: 'Nakliye Bilgisi',
    原产国: 'Menşe Ülke',
    目的国: 'Varış Ülke',
    运输方式: 'Nakliye Yolu',
    数据来源: 'Veri Kaynağı',
    请输入姓名: 'Lütfen adınızı girin',
    请输入部门: 'Lütfen bölümünüzü girin',
    请输入职务: 'Lütfen görevinizi girin',
    绩效月份: 'Performans Ayı',
    请选择绩效月份: 'Performans ayını seçin',
    计提人: 'Hesap Hesaplayan',
    请选计提人: 'Hesap hesaplayanı seçin',
    请选择调出仓库: 'Çıkış depoyu seçin',
    请选择调入仓库: 'Giriş depoyu seçin',
    回款类型: 'Ödeme Türü',
    请选择回款类型: 'Ödeme türünü seçin',
    退款: 'İade',
    回款: 'Ödeme',
    付款类型: 'Ödeme Türü',
    请选择付款类型: 'Ödeme türünü seçin',
    请选择优先级: 'Önceliği seçin',
    关键词: 'Anahtar Kelime',
    请选择上下架状态: 'Yayın durumunu seçin',
    只允许输入数字和字母: 'Yalnızca rakam ve harf girişi izin verilir',
    请输入产品关键词: 'Ürün anahtar kelimesini girin',
    请输入HS编码: 'HS kodunu girin',
    请输入供应商名称: 'Tedarikçi adını girin',
    请输入采购商名称: 'Alıcı adını girin',
    请选择原产国: 'Menşe ülkesini seçin',
    请选择目的国: 'Varış ülkesini seçin',
    起始时间: 'Başlangıç ​​Zamanı',
    请输入关键词搜索: 'Anahtar kelime araması yapın',
    请输入公司名称搜索: 'Şirket adı araması yapın',
    请输入产品名称搜索: 'Ürün adı araması yapın',
    请选择国家ISO编码: 'Ülke ISO kodunu seçin',
    请选择公司经营状态: 'Şirket işletme durumunu seçin',
    请选择公司营收范围: 'Şirket gelir aralığını seçin',
    请选择公司人数: 'Şirket çalışan sayısını seçin',
    一: 'Bir',
    二: 'İki',
    三: 'Üç',
    四: 'Dört',
    五: 'Beş',
    六: 'Altı',
    刚刚: 'Şimdi',
    分钟前: 'Dakika önce',
    小时前: 'Saat önce',
    天前: 'Gün önce',
    时: 'saat',
    分: 'dakika',
    是一个函数: 'Bir işlevdir',
    服务器成功返回请求数据: 'Sunucu isteği başarıyla veri döndürdü',
    新建或修改数据成功: 'Yeni oluşturulan veya güncellenen veriler başarıyla',
    '一个请求已经进入后台排队(异步任务)':
      'Bir istek zaten arka planda sıraya girdi (asyon görevi)',
    删除数据成功: 'Veri başarıyla silindi',
    发出信息有误: 'Bilgi yanlış gönderildi',
    '用户没有权限(令牌失效、用户名、密码错误、登录过期)':
      'Kullanıcının izni yok (jeton geçersiz, kullanıcı adı, şifre yanlış, oturum süresi doldu)',
    令牌过期: 'Jeton süresi doldu',
    '用户得到授权，但是访问是被禁止的':
      'Kullanıcı yetkilendirildi ancak erişim yasaklandı',
    访问资源不存在: 'Erişilen kaynak mevcut değil',
    请求格式不可得: 'İstek biçimi alınamaz',
    '请求资源被永久删除，且不会被看到':
      'İstek kaynağı kalıcı olarak silindi ve görüntülenmeyecek',
    服务器发生错误: 'Sunucuda bir hata oluştu',
    网关错误: 'Ağ geçidi hatası',
    '服务不可用，服务器暂时过载或维护':
      'Hizmet kullanılamaz, sunucu geçici olarak yük altında veya bakımda',
    网关超时: 'Ağ geçidi zaman aşımına uğradı',
    '无网络！,请连接网络！！': 'Ağ yok! Lütfen ağı bağlayın!',
    '网络不给力！,请检查网络！！': 'Ağ güçsüz! Lütfen ağı kontrol edin!',
    '服务端正在升级中，请稍后再试':
      'Sunucu şu anda güncelleniyor, lütfen daha sonra tekrar deneyin',
    提审并通过: 'İnceleme ve onayla',
    转: 'Devret',
    切换公司主体: 'Şirket anahtarını değiştir',
    '确定解绑企业微信?':
      'WeChat Enterprise bağlantısını çözmek istediğinizden emin misiniz?',
    主体未设置企业微信: 'Ana konu WeChat Enterprise ayarlanmadı',
    扫描二维码联系工作人员:
      'Personel ile iletişim kurmak için QR kodunu tarayın',
    正在导出的文件: 'Dışa aktarılan dosya',
    '版本)': 'Sürüm)',
    '异常捕获(温馨提示：错误必须解决)':
      'İstisna yakalanıyor (Unutmayın: Hata çözülmelidir)',
    报错路由: 'Hata Yolu',
    错误信息: 'Hata Mesajı',
    错误详情: 'Hata Detayı',
    暂不显示: 'Şu an gösterilmiyor',
    百度搜索: 'Baidu Arama',
    谷歌搜索: 'Google Arama',
    最近访问: 'Son Ziyaret',
    总览: 'Genel Bakış',
    开启全屏失败: 'Tam ekran açma başarısız oldu',
    服务将于: 'Hizmet sona erecek',
    天后到期: 'gün sonra sona erecek',
    服务永不到期: 'Hizmet hiç bitmez',
    站内信: 'İç Mesajlar',
    全部已读: 'Hepsini okundu olarak işaretle',
    消息状态: 'Mesaj Durumu',
    请选择消息类型: 'Mesaj türünü seçin',
    标记已读: 'Okundu olarak işaretle',
    没有更多了: 'Daha fazla yok',
    清空消息成功: 'Mesajları temizleme başarılı',
    自有产品: 'Kendi Ürünler',
    '已在当前产品！请换一个试试！':
      'Zaten mevcut bir üründür! Lütfen başka bir tane deneyin!',
    电商网站: 'E-Ticaret Web Sitesi',
    '支持纵向布局、分栏布局、综合布局、常规布局，不支持横向布局、浮动布局':
      'Dikey düzen, sütun düzeni, karma düzen ve normal düzeni destekler, yatay düzen ve kaydırma düzenini desteklemez',
    '请输入密码(密码在购买时获得，跳转后需登录购买时绑定的github账号)':
      'Lütfen şifrenizi girin (şifre, satın alırken alındı ve devam etmek için satın alma sırasında bağlı olan GitHub hesabına giriş yapmanız gerekiyor)',
    '秘钥不正确！': 'Anahtar geçersiz!',
    错误拦截: 'Hata Engellemesi',
    '检测到您当前浏览器使用的是IE内核，自':
      'Şu anda kullandığınız tarayıcının IE çekirdeğini kullandığınız tespit edildi,',
    '月起，微软已宣布弃用IE，且不再对IE提供任何更新维护，请':
      "Microsoft IE'yi terk etti ve IE'ye herhangi bir güncelleme veya bakım sunmayacak, lütfen",
    '基于admin-plus构建': 'admin-plus tabanlı oluşturulmuş',
    '抱歉!': 'Üzgünüz!',
    '您没有操作角色...': 'Şu anda bir işlem rolünüz yok...',
    '当前帐号没有操作角色,请联系管理员。':
      'Mevcut hesabınızda işlem rolü yok, lütfen yönetici ile iletişime geçin.',
    '当前页面不存在...': 'Mevcut sayfa mevcut değil...',
    '请检查您输入的网址是否正确，或点击下面的按钮返回首页。':
      'Lütfen girdiğiniz web adresini kontrol edin veya ana sayfaya dönmek için aşağıdaki düğmeye tıklayın.',
    返回首页: 'Ana sayfaya dön',
    '重量(KG)': 'Ağırlık (KG)',
    交易次数: 'İşlem Sayısı',
    交易重量: 'İşlem Ağırlığı',
    交易金额: 'İşlem Tutarı',
    出口: 'İhracat',
    期间采购共计: 'Dönemde Toplam Satın Alma',
    次: 'defa',
    采购次数最高: 'En Yüksek Satın Alma Sayısı',
    采购次数最低: 'En Düşük Satın Alma Sayısı',
    采购商数量: 'Alıcı Sayısı',
    供应商数量: 'Tedarikçi Sayısı',
    月度趋势: 'Aylık Trend',
    已添加为客户: 'Müşteri olarak eklendi',
    添加客户: 'Müşteri Ekle',
    进口详单: 'İthalat Detayları',
    出口详单: 'İhracat Detayları',
    贸易总览: 'Ticaret Genel Bakış',
    更新数据: 'Veriyi Güncelle',
    区域分布: 'Bölge Dağılımı',
    编码分布: 'Kod Dağılımı',
    货运历史: 'Nakliye Geçmişi',
    进口: 'İthalat',
    贸易伙伴: 'Ticaret Ortağı',
    搜索时间: 'Arama Zamanı',
    页码: 'Sayfa Numarası',
    查看数据: 'Veriyi Görüntüle',
    无搜索结果: 'Arama sonucu bulunamadı',
    国家: 'Ülke',
    经营状态: 'İşletme Durumu',
    营收范围: 'Gelir Aralığı',
    公司人数: 'Çalışan Sayısı',
    搜索历史: 'Arama Geçmişi',
    历史搜索: 'Geçmiş Aramalar',
    公司本地名称: 'Şirket Yerel Adı',
    城市: 'Şehir',
    已解锁: 'Kilit açıldı',
    未解锁: 'Kilitli',
    详情: 'Detaylar',
    '您确定要解锁当前客户数据?':
      'Mevcut müşteri verilerini kilitlemek istediğinizden emin misiniz?',
    产品搜索: 'Ürün Arama',
    公司搜索: 'Şirket Arama',
    交易分析: 'İşlem Analizi',
    分析报告: 'Analiz Raporu',
    回款详情: 'Ödeme Detayları',
    '您确定要对当前数据进行提审？':
      'Mevcut verileri incelemek istediğinizden emin misiniz?',
    '您确定要对当前数据进行提审并通过？':
      'Mevcut verileri incelemek ve onaylamak istediğinizden emin misiniz?',
    回款时间: 'Ödeme Tarihi',
    审核人: 'Denetçi',
    审核时间: 'Denetim Zamanı',
    回款计划详情: 'Ödeme Planı Detayları',
    '您确定要对当前数据生成发货单？':
      'Mevcut veriler için gönderim belgesi oluşturmak istediğinizden emin misiniz?',
    计划回款金额: 'Planlanan Ödeme Tutarı',
    付款详情: 'Ödeme Detayları',
    付款时间: 'Ödeme Tarihi',
    开票计划详情: 'Fatura Planı Detayları',
    开票公司: 'Fatura Şirketi',
    计划开票金额: 'Planlanan Fatura Tutarı',
    计划开票时间: 'Planlanan Fatura Tarihi',
    开票商品名称: 'Fatura Ürün Adı',
    开票商品编号: 'Fatura Ürün Kodu',
    商品规格型号: 'Ürün Özellik ve Modeli',
    开票商品单位: 'Fatura Ürün Birimi',
    开票税率: 'Fatura Vergi Oranı',
    开票商品数量: 'Fatura Ürün Miktarı',
    付款计划详情: 'Ödeme Planı Detayları',
    计划付款金额: 'Planlanan Ödeme Tutarı',
    需要数量: 'Gereken Miktar',
    仓库名称: 'Depo Adı',
    剩余数量: 'Kalan Miktar',
    分配库存: 'Stok Dağıtımı',
    关联订单: 'İlgili Siparişler',
    开票公司名称: 'Fatura Şirketi Adı',
    一键复制: 'Hızlı Kopyala',
    计划: 'Plan',
    计划开票占比: 'Planlanan Fatura Oranı',
    开票时间: 'Fatura Tarihi',
    付款编号: 'Ödeme Numarası',
    关联采购单: 'İlgili Satın Alma Siparişleri',
    计划编号: 'Plan Numarası',
    期数: 'Dönem',
    第: 'Bölüm',
    期: 'Bölüm',
    计划付款占比: 'Plan Ödeme Oranı',
    采购金额: 'Satın Alma Tutarı',
    已付款金额占比: 'Ödenen Tutar Oranı',
    '当前期数/总期数': 'Mevcut Dönem/Toplam Dönem',
    '您确定当前数据快速付款？':
      'Mevcut veriyi hızlı ödemek istediğinizden emin misiniz?',
    计划回款占比: 'Plan Geri Ödeme Oranı',
    '已回款金额/占比': 'Ödenen Tutar Oranı',
    '您确定当前数据快速回款？':
      'Mevcut veriyi hızlı geri almak istediğinizden emin misiniz?',
    回款编号: 'Geri Ödeme Numarası',
    客户排行: 'Müşteri Sıralaması',
    毛利分析: 'Kar Analizi',
    销量最高: 'En Yüksek Satış',
    毛利最高: 'En Yüksek Kar Marjı',
    采购数量最大: 'En Yüksek Satın Alma Miktarı',
    采购利润最高: 'En Yüksek Satın Alma Karı',
    采购额: 'Satın Alma Tutarı',
    销售分析: 'Satış Analizi',
    成交最多的销售: 'En Fazla İşlem Yapan Satış',
    销量最高的产品: 'En Yüksek Satışlı Ürün',
    毛利最多的销售: 'En Yüksek Kar Marjına Sahip Satış',
    毛利最高的产品: 'En Yüksek Kar Marjına Sahip Ürün',
    最近爆发的销售: 'Son Zamanlarda Yükselen Satışlar',
    上月增长最高的产品: 'Geçen Ay En Fazla Büyüyen Ürün',
    最近沉默的销售: 'Son Zamanlarda Düşen Satışlar',
    上月下降最多的产品: 'Geçen Ay En Fazla Düşen Ürün',
    销售额: 'Satış Tutarı',
    产品分析: 'Ürün Analizi',
    最畅销的产品: 'En Çok Satılan Ürünler',
    最赚钱的产品: 'En Karlı Ürünler',
    最近爆发产品: 'Son Zamanlarda Yükselen Ürünler',
    最近沉默产品: 'Son Zamanlarda Düşen Ürünler',
    产品销售量: 'Ürün Satış Miktarı',
    商机漏斗: 'Fırsat Hunisi',
    漏斗图: 'Huni Grafiği',
    销售排行: 'Satış Sıralaması',
    数据简报: 'Veri Özeti',
    环比: 'Dönemsel Değişim',
    新增客户: 'Yeni Müşteriler',
    新增商机: 'Yeni Fırsatlar',
    新增订单: 'Yeni Siparişler',
    累计回款: 'Toplam Geri Ödeme',
    累计退款: 'Toplam İade',
    前天: 'Önceki Gün',
    前周: 'Önceki Hafta',
    前月: 'Önceki Ay',
    前年: 'Önceki Yıl',
    用户趋势: 'Kullanıcı Trendleri',
    用户数量: 'Kullanıcı Sayısı',
    趋势: 'Trend',
    客户跟进: 'Müşteri Takibi',
    需跟进的客户: 'Takip Edilmesi Gereken Müşteriler',
    商机跟进: 'Fırsat Takibi',
    订单跟进: 'Sipariş Takibi',
    待办任务: 'Yapılacak İşler',
    任务名称: 'Görev Adı',
    计划完成时间: 'Tamamlanma Zamanı',
    占位符: 'Yer Tutucu',
    图形验证码: 'Grafik Doğrulama Kodu',
    账号登录: 'Hesap Girişi',
    快捷登录: 'Hızlı Giriş',
    扫码登录: 'QR Kod İle Giriş',
    '企业微信扫码登录失败，请先确认您的企业微信账号确认属于该企业':
      'QR Kod ile WeChat Girişi başarısız, lütfen WeChat hesabınızın ilgili şirkete ait olduğundan emin olun',
    验证码不能空: 'Doğrulama kodu boş olamaz',
    事项信息: 'Görev Bilgisi',
    事项标题: 'Görev Başlığı',
    事项类型: 'Görev Türü',
    所属目标: 'Hedef',
    重要程度: 'Önem Derecesi',
    事项参与人员: 'Görev Katılımcıları',
    请选择参与人员: 'Katılımcıları Seçin',
    创建者: 'Oluşturan',
    参与人员: 'Katılımcılar',
    事项: 'Görev',
    请输入事项标题: 'Görev Başlığı Girin',
    请输入重要程度: 'Önem Derecesi Girin',
    请选择紧急程度: 'Aciliyet Seviyesini Seçin',
    目标开始时间: 'Hedef Başlangıç Tarihi',
    目标结束时间: 'Hedef Bitiş Tarihi',
    目标时间: 'Hedef Zamanı',
    请输入所属目标: 'Hedefi Girin',
    请选择所属目标: 'Hedefi Seçin',
    数据已存在: 'Veri Zaten Mevcut',
    还有: 'Hala',
    '项...': ' Maddeler...',
    系统创建: 'Sistem Tarafından Oluşturuldu',
    事件已结束: 'Olay Tamamlandı',
    新建日程: 'Yeni Takvim Olayı',
    上一年: 'Geçen Yıl',
    上一月: 'Geçen Ay',
    今天: 'Bugün',
    下一月: 'Gelecek Ay',
    下一年: 'Gelecek Yıl',
    日程主题: 'Takvim Başlığı',
    请输入日程主题: 'Takvim Başlığı Girin',
    日程周期: 'Takvim Dönemi',
    日程描述: 'Takvim Açıklaması',
    请输入日程描述: 'Takvim Açıklaması Girin',
    产品英文名: 'Ürün İngilizce Adı',
    请输入产品英文名: 'Ürün İngilizce Adı Girin',
    产品主图: 'Ürün Ana Görseli',
    产品轮播图: 'Ürün Dönüş Görseli',
    请输入产品描述: 'Ürün açıklamasını girin',
    计量单位: 'Ölçü Birimi',
    请输入产品计量单位: 'Ürün ölçü birimini girin',
    产品库存: 'Ürün Stoğu',
    产品销量: 'Ürün Satışları',
    产品成本价: 'Ürün Maliyet Fiyatı',
    产品排序值: 'Ürün Sıralama Değeri',
    产品重量: 'Ürün Ağırlığı',
    采购建议: 'Satın Alma Önerisi',
    请输入采购建议: 'Satın alma önerisini girin',
    于: 'tarafından',
    创建者创建时间: 'Oluşturan Oluşturma Zamanı',
    最近操作时间: 'Son İşlem Zamanı',
    故事: 'Hikaye',
    数据周期: 'Veri Döngüsü',
    '请输入属性名：库存': 'Özellik adını girin: Stok',
    '属性名：库存': 'Özellik adı: Stok',
    请选择数据周期: 'Veri döngüsünü seçin',
    数据类型: 'Veri Türü',
    请选择数据类型: 'Veri türünü seçin',
    定时任务: 'Zamanlanmış Görev',
    请选择定时任务: 'Zamanlanmış görevi seçin',
    目标组名称: 'Hedef Grubu Adı',
    '开始时间-结束时间': 'Başlangıç Zamanı-Bitiş Zamanı',
    目标标题: 'Hedef Başlığı',
    指标标题: 'Gösterge Başlığı',
    图表类型: 'Grafik Türü',
    目标组: 'Hedef Grubu',
    目标组标题: 'Hedef Grubu Başlığı',
    请输入目标组标题: 'Hedef grup başlığını girin',
    目标组开始时间: 'Hedef grubu başlangıç zamanı',
    目标组结束时间: 'Hedef grubu bitiş zamanı',
    目标组时间: 'Hedef grubu zamanı',
    目标: 'Hedef',
    请输入目标标题: 'Hedef başlığını girin',
    所属目标组: 'Hedef grubuna ait',
    请选择所属目标组: 'Hedef grubunu seçin',
    指标: 'Gösterge',
    请输入指标标题: 'Gösterge başlığını girin',
    数据源: 'Veri Kaynağı',
    请选择数据源: 'Veri kaynağını seçin',
    选择出数据源: 'Veri kaynağını seçin',
    手动: 'Manuel',
    自动: 'Otomatik',
    指标数据: 'Gösterge Verileri',
    重要不紧急: 'Önemli Değil, Acil Değil',
    重要紧急: 'Önemli ve Acil',
    不重要不紧急: 'Önemli Değil, Acil Değil',
    不重要紧急: 'Önemli ve Acil Değil',
    所属指标: 'Göstergeye Ait',
    横坐标: 'X Ekseni',
    数值: 'Değer',
    显示近一周: 'Son Bir Hafta Göster',
    显示近一月: 'Son Bir Ay Göster',
    显示近一年: 'Son Bir Yıl Göster',
    显示全部: 'Tümünü Göster',
    个人: 'Bireysel',
    团队: 'Ekip',
    预计提成: 'Beklenen Komisyon',
    元: 'Yuan',
    '预计提成：团队比例': 'Beklenen Komisyon: Ekip Yüzdesi',
    '计提人(团队)': 'Komisyon Alan Kişi (Ekip)',
    当期金额: 'Mevcut Tutar',
    实际提成: 'Gerçek Komisyon',
    订单编号: 'Sipariş Numarası',
    团队提成: 'Ekip Komisyonu',
    服务金额: 'Hizmet Tutarı',
    合计: 'Toplam',
    条件: 'Koşul',
    符合以下条件时按本规则计提:
      'Aşağıdaki koşullar sağlandığında bu kurala göre komisyon hesapla',
    添加条件: 'Koşul Ekle',
    '元）': 'Yuan)',
    新建规则: 'Yeni Kural Oluştur',
    '规则ID、规则名称': 'Kural Kimliği, Kural Adı',
    编辑规则: 'Kuralı Düzenle',
    '负责人/提计人': 'Sorumlu Kişi / Komisyon Alan Kişi',
    产品分类下的扩展属性: 'Ürün Kategorisi Altındaki Genişletilmiş Özellikler',
    产品属性: 'Ürün Özellikleri',
    设置方式: 'Ayarları Yapın',
    销售价格: 'Satış Fiyatı',
    采购价格: 'Satın Alma Fiyatı',
    按客户设置: 'Müşteriye Göre Ayarla',
    价格为: 'Fiyatı:',
    价格币种: 'Para Birimi',
    标准单价: 'Standart Birim Fiyat',
    按客户类别设置: 'Müşteri Kategorisine Göre Ayarla',
    默认调价比例: 'Varsayılan Fiyat Ayarı Oranı',
    实际调价比例: 'Gerçek Fiyat Ayarı Oranı',
    起始年份: 'Başlangıç Yılı',
    截止年份: 'Bitiş Yılı',
    补充年份: 'Ek Yıl',
    已上传: 'Yüklendi',
    上传图片: 'Resim Yükle',
    共: 'Toplam',
    综述信息: 'Genel Bakış Bilgileri',
    跟进信息: 'Takip Bilgileri',
    详细跟进: 'Detaylı Takip',
    请填写跟进内容: 'Takip içeriğini girin',
    发布: 'Yayınla',
    下次跟进: 'Sonraki Takip',
    绩效比例: 'Performans Oranı',
    '个人绩效相对团队绩效占比，剩余部分归负责人':
      'Bireysel performans, takım performansına göre oranlanır, geriye kalan sorumluya aittir',
    最新价格: 'Son Fiyat',
    历史近期: 'Son Dönem',
    供应: 'Tedarik',
    已付金额: 'Ödenen Tutar',
    '当前订单付款总和，不扣除退款':
      'Şu anki siparişlerin toplam ödemesi, iade edilen tutarlar çıkarılmaz',
    '您确定要对当前数据生成入库单？':
      'Bu veri için giriş belgesi oluşturmak istediğinizden emin misiniz?',
    批量添加: 'Toplu Ekle',
    请输入企业名称: 'Lütfen firma adını girin',
    '您确定要对当前数据进行作废？':
      'Bu veriyi iptal etmek istediğinizden emin misiniz?',
    请选择组装拆卸产品明细: 'Montaj/Demontaj ürün detaylarını seçin',
    请选择组装拆卸产品: 'Montaj/Demontaj ürünü seçin',
    箱单详情: 'Kasa Detayları',
    '您确定要生成发货单？':
      'Teslimat belgesi oluşturmak istediğinizden emin misiniz?',
    装箱数: 'Kutu Sayısı',
    '未作废数据删除，库存将不会恢复是否删除？':
      'İptal edilmemiş veriler silinirse, stok geri yüklenmez, silmek istediğinizden emin misiniz?',
    '您确定要作废当前数据？':
      'Bu veriyi iptal etmek istediğinizden emin misiniz?',
    箱单数量: 'Kasa Sayısı',
    请输入预估所需箱单数: 'Tahmini kasa sayısını girin',
    拆分数量: 'Bölünmüş Miktar',
    装货箱编号: 'Yükleme Kutu Numarası',
    码: 'Kod',
    包装数量: 'Ambalaj Miktarı',
    包装的数量: 'Paketlenen Miktar',
    '单个重量(w/pc)': 'Tek Parça Ağırlığı (adet başına)',
    包装的重量: 'Paketlenen Ağırlık',
    '单个体积(v/pc)': 'Tek Parça Hacmi (adet başına)',
    包装的体积: 'Paketlenen Hacim',
    总重量: 'Toplam Ağırlık',
    总体积: 'Toplam Hacim',
    所属箱号: 'Ait Olduğu Kasa Numarası',
    盘点详情: 'Stok Sayım Detayları',
    系统库存: 'Sistem Stoku',
    系统统计当前仓库的数量: 'Sistem, mevcut depo stokunu hesaplar',
    盘点数量: 'Stok Sayım Miktarı',
    盘亏盘盈: 'Stok Zarar/Kazanç',
    添加后请到出入库单列表审核:
      'Ekledikten sonra giriş/çıkış belgeleri listesine gidip onaylayın',
    '剩余数量（冻结库存数量)': 'Kalan Miktar (Donmuş Stok Miktarı)',
    操作员: 'Operatör',
    出入库单详情: 'Giriş/Çıkış Belgesi Detayları',
    '您确定要对当前数据生成出入库单？':
      'Bu veri için giriş/çıkış belgesi oluşturmak istediğinizden emin misiniz?',
    出入库进度: 'Giriş/Çıkış İlerlemesi',
    备注说明: 'Açıklama Notu',
    产品调拨详情: 'Ürün Transfer Detayları',
    组合件名称: 'Birleşik Ürün Adı',
    单位成本: 'Birim Maliyet',
    组合成本: 'Birleşik Maliyet',
    操作时间: 'İşlem Zamanı',
    操作人: 'İşlem Yapan',
    业务类型: 'İş Türü',
    入库数量: 'Depo Giriş Miktarı',
    出库数量: 'Depo Çıkış Miktarı',
    结存数量: 'Kalan Miktar',
    库存低于预警库存: 'Stok Uyarı Stok Altında',
    各仓库数量: 'Tüm Depo Miktarları',
    总库存: 'Toplam Stok',
    盘点时间: 'Envanter Zamanı',
    盘点产品量: 'Envanter Ürün Sayısı',
    盘点人: 'Envanter Yapan Kişi',
    中文: 'Çince',
    父级: 'Üst Düzey',
    请选择父级: 'Üst Düzeyi Seçin',
    记录: 'Kayıt',
    租金: 'Kira',
    人流量: 'İnsan Trafiği',
    是否转让: 'Devredilir mi?',
    转让价格: 'Devir Fiyatı',
    流水: 'Hareket',
    利润: 'Kar',
    推荐星级: 'Önerilen Yıldız Sayısı',
    星: 'Yıldız',
    联系方式: 'İletişim Bilgileri',
    商家: 'Satıcı',
    是否显示: 'Görünür mü?',
    请输入店铺名称: 'Dükkan Adını Girin',
    选址类型: 'Lokasyon Türü',
    请选择选址类型: 'Lokasyon Türünü Seçin',
    请输入店铺地址: 'Dükkan Adresini Girin',
    经度: 'Boylam',
    请输入经度: 'Boylamı Girin',
    纬度: 'Enlem',
    请输入纬度: 'Enlemi Girin',
    '租金(月)': 'Kira (Aylık)',
    请输入租金: 'Kira Miktarını Girin',
    请输入人流量: 'İnsan Trafiğini Girin',
    请输入流水: 'Hareketi Girin',
    请输入利润: 'Karı Girin',
    请输入推荐星级: 'Önerilen Yıldız Sayısını Girin',
    请输入转让价格: 'Devir Fiyatını Girin',
    请输入联系方式: 'İletişim Bilgilerini Girin',
    竞品情况: 'Rakip Durumu',
    请输入竞品情况: 'Rakip Durumunu Girin',
    请输入教程名称: 'Eğitim Adını Girin',
    教程类型: 'Eğitim Türü',
    请选择教程类型: 'Eğitim Türünü Seçin',
    教程: 'Eğitim',
    教程名称: 'Eğitim Adı',
    前: 'Önce',
    小时内: 'Saat İçinde',
    分钟内: 'Dakika İçinde',
    销售趋势: 'Satış Eğilimi',
    销售数量: 'Satış Miktarı',
    客户最新报价: 'Müşteri Son Teklifi',
    头像: 'Profil Fotoğrafı',
    昵称: 'Takma Ad',
    真实姓名: 'Gerçek Adı',
    手机号码: 'Telefon Numarası',
    最后登录时间: 'Son Giriş Zamanı',
    电话号码: 'Telefon Numarası',
    请选择角色: 'Rolü Seçin',
    请选择公司: 'Şirketi Seçin',
    请选择昵称: 'Takma Adı Seçin',
    人员: 'Personel',
    公司简称: 'Şirket Kısaltması',
    请输入公司简称: 'Şirket Kısaltmasını Girin',
    统一社会信用代码: 'Kurumsal Kredi Kimlik Numarası',
    请输入统一社会信用代码: 'Kurumsal Kredi Kimlik Numarasını Girin',
    企业法人: 'Kurumsal Sorumlu',
    请输入企业法人: 'Kurumsal Sorumlusunu Girin',
    注册地址: 'Kayıt Adresi',
    请输入注册地址: 'Kayıt Adresini Girin',
    注册日期: 'Kayıt Tarihi',
    请输入注册日期: 'Kayıt Tarihini Girin',
    请选择注册日期: 'Kayıt Tarihini Seçin',
    联系电话: 'İletişim Telefonu',
    请输入联系电话: 'İletişim Telefonunu Girin',
    联系邮箱: 'İletişim E-Postası',
    请输入联系邮箱: 'İletişim E-Postasını Girin',
    站点域名: 'Site Alan Adı',
    请输入站点域名: 'Site Alan Adını Girin',
    请输入ICP: "ICP'yi Girin",
    公司代号: 'Şirket Kodu',
    请输入公司代号: 'Şirket Kodunu Girin',
    公司简介: 'Şirket Tanıtımı',
    请输入公司简介: 'Şirket Tanıtımını Girin',
    公司LOGO: 'Şirket Logosu',
    站点LOGO: 'Site Logosu',
    站点ICO: 'Site ICO',
    登录页背景图: 'Giriş Sayfası Arka Plan Resmi',
    部门名称: 'Bölüm Adı',
    手机: 'Cep Telefonu',
    请输入部门名称: 'Bölüm Adını Girin',
    部门人员: 'Bölüm Personeli',
    员工: 'Çalışan',
    请选择员工: 'Çalışanı Seçin',
    职位: 'Pozisyon',
    '您确定要对当前员工进行禁用？':
      'Şu anki çalışanı devre dışı bırakmak istediğinizden emin misiniz?',
    '您确定要对当前员工进行解除禁用？':
      'Şu anki çalışanın devre dışı bırakılmasını kaldırmak istediğinizden emin misiniz?',
    '您确定要对当前员工进行离职处理？':
      'Şu anki çalışanı işten çıkarmak istediğinizden emin misiniz?',
    员工状态: 'Çalışan Durumu',
    权限字段: 'İzin Alanı',
    编辑角色: 'Rolü Düzenle',
    添加角色: 'Rol Ekle',
    角色名称: 'Rol Adı',
    请输入角色名称: 'Rol Adını Girin',
    角色描述: 'Rol Açıklaması',
    数据权限: 'Veri İzni',
    模块权限: 'Modül İzni',
    字段权限: 'Alan İzni',
    请输入角色名: 'Rol Adını Girin',
    组织架构: 'Organizasyon Şeması',
    员工管理: 'Personel Yönetimi',
    标识: 'Kimlik',
    成员限制: 'Üye Sınırlaması',
    成员数量: 'Üye Sayısı',
    到期时间: 'Son Kullanma Tarihi',
    站点: 'Site',
    行业: 'Sektör',
    请输入标识: 'Kimlik Girin',
    管理员手机号: 'Yönetici Telefon Numarası',
    请输入管理员手机号: 'Yönetici Telefon Numarasını Girin',
    管理员姓名: 'Yönetici Adı',
    请输入管理员姓名: 'Yönetici Adını Girin',
    公司行业: 'Şirket Sektörü',
    请选择公司行业: 'Şirket Sektörünü Seçin',
    请选择到期时间: 'Son Kullanma Tarihini Seçin',
    键: 'Anahtar',
    必填: 'Zorunlu',
    默认值: 'Varsayılan Değer',
    占比: 'Oran',
    请输入正确key: 'Doğru anahtar girin',
    字段: 'Alan',
    只能是英文数字下划线的组合且以英文或者下划线开头:
      'Sadece İngilizce harf, rakam ve alt çizgi kombinasyonu, İngilizce veya alt çizgi ile başlamalıdır',
    请输入键: 'Anahtarı Girin',
    请输入默认值: 'Varsayılan Değeri Girin',
    输入提示: 'Giriş İpucu',
    请输入输入提示: 'Giriş İpucusunu Girin',
    客户类型: 'Müşteri Türü',
    请输入客户类型名: 'Müşteri Türü Adını Girin',
    请输入客户类型: 'Müşteri Türünü Girin',
    客户配置: 'Müşteri Ayarları',
    行业字段: 'Sektör Alanı',
    行业名称: 'Sektör Adı',
    行业权限: 'Sektör İzni',
    所属菜单: 'Menü Sahibi',
    字段名称: 'Alan Adı',
    字段标识: 'Alan Kimliği',
    列表宽度: 'Liste Genişliği',
    父级菜单: 'Ana Menü',
    请选择父级菜单: 'Lütfen Ana Menü Seçin',
    请输入字段名称: 'Lütfen Alan Adını Girin',
    请输入字段标识: 'Lütfen Alan Kimliğini Girin',
    请输入列表宽度: 'Lütfen Liste Genişliğini Girin',
    字段排序: 'Alan Sıralaması',
    请输入字段排序: 'Lütfen Alan Sıralamasını Girin',
    菜单名称: 'Menü Adı',
    菜单: 'Menü',
    请输入菜单名称: 'Lütfen Menü Adını Girin',
    菜单地址: 'Menü Adresi',
    请输入菜单地址: 'Lütfen Menü Adresini Girin',
    关联菜单: 'İlgili Menü',
    请选择关联菜单: 'Lütfen İlgili Menüyü Seçin',
    菜单图标: 'Menü İkonu',
    请输入菜单图标: 'Lütfen Menü İkonunu Girin',
    菜单排序: 'Menü Sıralaması',
    请输入菜单排序: 'Lütfen Menü Sıralamasını Girin',
    操作名称: 'İşlem Adı',
    操作代码: 'İşlem Kodu',
    操作地址: 'İşlem Adresi',
    表头操作: 'Tablo İşlemi',
    列表操作: 'Liste İşlemi',
    选项卡操作: 'Sekme İşlemi',
    请输入操作名称: 'Lütfen İşlem Adını Girin',
    不输入则与操作名称相同: 'İşlem Adı ile aynı ise boş bırakın',
    请输入操作代码: 'Lütfen İşlem Kodunu Girin',
    操作图标: 'İşlem İkonu',
    请输入操作图标: 'Lütfen İşlem İkonunu Girin',
    请输入操作地址: 'Lütfen İşlem Adresini Girin',
    操作排序: 'İşlem Sıralaması',
    请输入操作排序: 'Lütfen İşlem Sıralamasını Girin',
    显示在表头: 'Tabloda Göster',
    显示在列表: 'Listede Göster',
    显示在选项卡: 'Sekmelerde Göster',
    所属企业: 'Şirket Sahibi',
    企业: 'Şirket',
    选择企业: 'Şirket Seçin',
    操作管理: 'İşlem Yönetimi',
    字段管理: 'Alan Yönetimi',
    设备调试: 'Cihaz Hata Ayıklama',
    门锁状态: 'Kilit Durumu',
    物品状态: 'Nesne Durumu',
    门锁编号: 'Kilit Numarası',
    开锁: 'Aç',
    检测: 'Kontrol Et',
    通电: 'Enerji Ver',
    断电: 'Enerji Kes',
    门锁操作: 'Kilit İşlemi',
    门锁打开: 'Kapı Açık',
    门锁已开启: 'Kapı Açık',
    门锁关闭: 'Kapı Kapalı',
    门锁已关闭: 'Kapı Kapalı',
    发信昵称: 'Gönderen İsmi',
    邮箱服务商: 'E-posta Sağlayıcı',
    默认发信邮箱: 'Varsayılan Gönderen E-posta',
    请输入发信昵称: 'Lütfen Gönderen İsmini Girin',
    请选择邮箱服务商: 'Lütfen E-posta Sağlayıcısını Seçin',
    密码: 'Şifre',
    语种: 'Dil',
    请选择语种: 'Lütfen Dil Seçin',
    '内容-头部': 'Başlık İçeriği',
    '内容-尾部': 'Altbilgi İçeriği',
    用户名: 'Kullanıcı Adı',
    性别: 'Cinsiyet',
    个人简介: 'Kişisel Tanıtım',
    我的邮箱: 'E-posta Adresim',
    重置密码: 'Şifre Sıfırla',
    短信验证码: 'SMS Doğrulama Kodu',
    新密码: 'Yeni Şifre',
    供应商分类: 'Tedarikçi Kategorisi',
    车型配置: 'Araç Modeli Yapılandırma',
    部件配置: 'Parça Yapılandırma',
    行业模板: 'Endüstri Şablonu',
    '请选择行业模板(非必填)': 'Lütfen Endüstri Şablonunu Seçin (Zorunlu Değil)',
    分类图片: 'Kategori Resmi',
    英文名称: 'İngilizce İsim',
    顶级分类: 'Üst Kategori',
    请输入搜索内容: 'Lütfen Arama İçeriğini Girin',
    父级分类: 'Üst Kategori',
    请选择父级分类: 'Lütfen Üst Kategoriyi Seçin',
    '分类名称（英文）': 'Kategori Adı (İngilizce)',
    '请输入分类名称（英文）': 'Lütfen Kategori Adını Girin (İngilizce)',
    分类排序: 'Kategori Sıralaması',
    类型标题: 'Tür Başlığı',
    类型名称: 'Tür Adı',
    请输入类型名称: 'Lütfen Tür Adını Girin',
    仓库设置: 'Depo Ayarları',
    图片数量: 'Resim Sayısı',
    宽度: 'Genişlik',
    高度: 'Yükseklik',
    素材名称: 'Malzeme Adı',
    分类标题: 'Kategori Başlığı',
    事项分类: 'Olay Kategorisi',
    故事分类: 'Hikaye Kategorisi',
    汇率设置: 'Döviz Kuru Ayarları',
    人民币: 'Çin Yuanı',
    前缀: 'Ön Ek',
    请选择中间规则: 'Lütfen Orta Kuralı Seçin',
    请选择后缀规则: 'Lütfen Son Ek Kuralını Seçin',
    产品编号规则: 'Ürün Numarası Kuralı',
    '前缀如CP，类型代码需要在类型信息里面增加':
      'Ön ek olarak CP gibi, tür kodu Tür Bilgileri içinde eklenmelidir',
    空: 'Boş',
    类型id: 'Tür Kimliği',
    类型代码: 'Tür Kodu',
    自增ID: 'Otomatik Artan Kimlik',
    客户编号规则: 'Müşteri Numarası Kuralı',
    前缀如KH: 'Ön ek olarak KH gibi',
    国家ID: 'Ülke Kimliği',
    国家代码: 'Ülke Kodu',
    销售单名称规则: 'Satış Faturası Adı Kuralı',
    前缀如XSDD: 'Ön ek olarak XSDD gibi',
    客户ID: 'Müşteri Kimliği',
    年月日: 'Yıl Ay Gün',
    采购单名称规则: 'Satın Alma Siparişi Adı Kuralı',
    前缀如CGD: 'Ön ek olarak CGD gibi',
    供应商ID: 'Tedarikçi Kimliği',
    销售报价权限: 'Satış Teklifleri İzni',
    '严格模式下，报价低于产品最低售价不可提交，轻松模式下可以提交，会给出明显警告':
      'Sıkı Modda, teklif ürünün en düşük satış fiyatının altındaysa gönderilemez, Gevşek Modda gönderilebilir, ancak açık bir uyarı alacaksınız',
    宽松模式: 'Gevşek Mod',
    采购报价权限: 'Satın Alma Teklifleri İzni',
    '严格模式下，采购高于供应商报价不可提交，轻松模式下可以提交，会给出明显警告':
      'Sıkı Modda, satın alma teklifi tedarikçi teklifinden yüksekse gönderilemez, Gevşek Modda gönderilebilir, ancak açık bir uyarı alacaksınız',
    基本配置: 'Temel Ayarlar',
    '用户ID/用户名': 'Kullanıcı Kimliği/Kullanıcı Adı',
    路径: 'Yol',
    请求ID: 'İstek Kimliği',
    规则: 'Kural',
    执行类: 'Yürütme Sınıfı',
    执行方法: 'Yürütme Metodu',
    是否启用: 'Etkinleştirilsin mi',
    任务标题: 'Görev Başlığı',
    请输入任务标题: 'Lütfen Görev Başlığını Girin',
    任务规则: 'Görev Kuralı',
    请输入任务规则: 'Lütfen Görev Kuralını Girin',
    如: 'Örneğin',
    则代表每: 'şu anlama gelir: her',
    分钟执行一次: 'dakika aralıklarla çalıştır',
    请输入执行类: 'Lütfen Yürütme Sınıfını Girin',
    请输入执行方法: 'Lütfen Yürütme Metodunu Girin',
    任务类型: 'Görev Türü',
    请选择任务类型: 'Lütfen Görev Türünü Seçin',
    参数格式: 'Parametre Biçimi',
    '请输入参数格式(必须为json格式),可不填写':
      'Lütfen Parametre Biçimini Girin (Zorunlu olarak JSON biçiminde olmalıdır), boş bırakabilirsiniz',
    数据组类型: 'Veri Grubu Türü',
    请选择数据组类型: 'Lütfen Veri Grubu Türünü Seçin',
    属性: 'Özellik',
    '请输入属性key(唯一)：name':
      'Lütfen Benzersiz Özellik Anahtarını Girin: name',
    '属性key(唯一)：name': 'Ad özelliği (benzersiz): ad',
    属性类型: 'Özellik türü',
    排序值: 'Sıralama değeri',
    请填写选项配置值: 'Seçenek yapılandırma değerini doldurun',
    参数方式例如: 'Örnek parametre',
    白色: 'Beyaz',
    红色: 'Kırmızı',
    黑色: 'Siyah',
    添加属性: 'Özellik ekle',
    数据组名称: 'Veri grubu adı',
    数据组描述: 'Veri grubu açıklaması',
    请输入关键字: 'Anahtar kelime girin',
    扩展属性: 'Genişletilmiş özellikler',
    请输入数据组名称: 'Veri grubu adını girin',
    请输入数据组描述: 'Veri grubu açıklamasını girin',
    请上传: 'Yükle',
    主体名称: 'Ana başlık',
    域名: 'Alan adı',
    站点标题: 'Site başlığı',
    团队名称: 'Takım adı',
    主体: 'Sahip',
    所属: 'Sahip',
    请输入所属: 'Sahip girin',
    请输入主体名称: 'Ana başlık girin',
    请输入税号: 'Vergi numarasını girin',
    法人: 'Yasal temsilci',
    请输入法人: 'Yasal temsilci girin',
    请输入站点标题: 'Site başlığını girin',
    主体简介: 'Alan adı özeti',
    请输入主体简介: 'Alan adı özetini girin',
    主体LOGO: 'Alan adı logosu',
    站点背景: 'Site arka planı',
    数据配置: 'Veri yapılandırması',
    主体管理: 'Ana başlık yönetimi',
    账号: 'Hesap',
    请输入账号: 'Hesap girin',
    周期: 'Döngü',
    日志类型: 'Günlük türü',
    执行结果: 'Yürütme sonucu',
    登录成功: 'Giriş başarılı',
    访问时间: 'Erişim zamanı',
    登录IP: 'Giriş IP',
    下载模版: 'Şablon indir',
    枚举变量: 'Numaralandırma değişkeni',
    模版文件: 'Şablon dosyası',
    仅支持Xlsx: 'Yalnızca Xlsx desteklenir',
    若为Xls文件请先在Excel中另存为Xlsx: `Xls dosyasıysa lütfen önce Excel'de Xlsx olarak kaydedin`,
    '添加完成后，请点击字段集编辑完善模版填充配置信息':
      'Eklemeyi tamamladıktan sonra, şablon doldurma yapılandırması bilgilerini düzenlemek için alan kümesini tıklayın',
    变量: 'Değişken',
    为单一数据占位: 'Tek veri için yer tutucu oluştur',
    为列数据占位: 'Sütun verisi için yer tutucu oluştur',
    字段集: 'Alan kümesi',
    '文件名称(点击预览)': 'Dosya adı (önizlemek için tıklayın)',
    文件大小: 'Dosya boyutu',
    数据填充起始行: 'Veri doldurma başlangıç satırı',
    模板选择: 'Şablon seçimi',
    无模板导出: 'Şablon olmadan dışa aktar',
    模板导出: 'Şablon dışa aktar',
    无模板打印: 'Şablon olmadan yazdır',
    模板打印: 'Şablon yazdır',
    请选择模板: 'Bir şablon seçin',
    '正在导出中...': 'Dışa aktarılıyor...',
    '正在打印中...': 'Yazdırılıyor...',
    单元格: 'Hücre',
    多行项: 'Çoklu satır öğesi',
    是否求和: 'Toplamı hesapla',
    是否图片: 'Resim mi?',
    是否合并: 'Birleştirme mi?',
    图片宽度: 'Resim genişliği',
    图片高度: 'Resim yüksekliği',
    关联合并: 'Bağlantı birleştirme',
    请选择字段: 'Bir alan seçin',
    链接: 'Bağlantı',
    轮播图设置: 'Slayt gösterisi ayarları',
    首页分类商品设置: 'Ana sayfa kategori ürün ayarları',
    '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页f分类商品中。':
      'Bu içerik ürün kategorisine bağlıdır: Ürünler> Ürün kategorisi, göstermek istediğiniz kategorileri işaretleyebilirsiniz, işaretledikten sonra kategoriler ana sayfa kategori ürünlerinde görüntülenecektir.',
    首页分类导航设置: 'Ana sayfa kategori gezinme ayarları',
    '此内容依赖于：产品》产品分类，您可以勾选你需要展示的分类，勾选后分类将出现在首页导航栏中。':
      'Bu içerik ürün kategorisine bağlıdır: Ürünler> Ürün kategorisi, göstermek istediğiniz kategorileri işaretleyebilirsiniz, işaretledikten sonra kategoriler ana sayfa gezinme çubuğunda görüntülenecektir.',
    导航设置: 'Gezinme ayarları',
    站点名称: 'Site adı',
    请输入站点名称: 'Site adınızı girin',
    '对SEO很重要,不要堆砌关键字':
      'SEO için önemlidir, anahtar kelimeleri yığınlamayın',
    站点描述: 'Site açıklaması',
    请输入站点描述: 'Site açıklamanızı girin',
    '站点的描述，用于展示在搜素结果':
      'Site açıklamanızı girin, arama sonuçlarında görüntülenecektir',
    站点关键词: 'Site anahtar kelimeleri',
    请输入站点关键词: 'Site anahtar kelimelerini girin',
    '搜索时会匹配对应的关键词，不要重复':
      'Arama sırasında ilgili anahtar kelimeleri eşleştirir, tekrarlamayın',
    请输入公司地址: 'Şirket adresinizi girin',
    社媒设置: 'Sosyal medya ayarları',
    联系我们: 'Bize ulaşın',
    请输入正确的网址信息: 'Lütfen doğru web sitesi bilgilerini girin',
    请输入facebook主页地址: 'Facebook ana sayfa adresinizi girin',
    请输入twitter主页地址: 'Twitter ana sayfa adresinizi girin',
    请输入youtube主页地址: 'YouTube ana sayfa adresinizi girin',
    请输入linkedin主页地址: 'LinkedIn ana sayfa adresinizi girin',
    请输入instagram主页地址: 'Instagram ana sayfa adresinizi girin',
    页尾设置: 'Altbilgi ayarları',
    '您确定要作废当前数据?':
      'Geçerli verileri iptal etmek istediğinizden emin misiniz?',
    实施明细: 'Uygulama ayrıntıları',
    任务状态: 'Görev durumu',
    参与人: 'Katılımcılar',
    未开始: 'Başlamadı',
    任务详情: 'Görev detayları',
    任务概况: 'Görev özeti',
    完成时间: 'Tamamlama zamanı',
    '您确定要作废此任务？':
      'Bu görevi iptal etmek istediğinizden emin misiniz?',
    '您确定已完成此任务？': 'Bu görevi tamamladığınızdan emin misiniz?',
    请选择团队成员: 'Bir takım üyesi seçin',
    请填写比列: 'Oranı doldurun',
    请填写分成比列: 'Paylaşım oranını doldurun',
    进度: 'İlerleme',
    分成比例不能低于: 'Bölüm oranı en az olmamalıdır',
    或高于: 'veya daha yüksek',
    钱包金额: 'Cüzdan tutarı',
    用户角色: 'Kullanıcı rolü',
    注册时间: 'Kayıt zamanı',
    用户: 'Kullanıcı',
    请输入真实姓名: 'Gerçek adınızı girin',
    用户邮箱: 'Kullanıcı e-postası',
    请输入用户邮箱: 'Kullanıcı e-postasını girin',
    请输入正确的用户邮箱地址: 'Lütfen doğru kullanıcı e-posta adresini girin',
    性别选择: 'Cinsiyet seçimi',
    所属团队: 'Ait olduğunuz takım',
    请选择团队: 'Bir takım seçin',
    所属角色: 'Ait olduğunuz rol',
    用户密码: 'Kullanıcı şifresi',
    请输入用户密码: 'Kullanıcı şifresini girin',
    用户头像: 'Kullanıcı profil resmi',
    用户昵称: 'Kullanıcı takma adı',
    回复: 'Yanıtla',
    反馈: 'Geribildirim',
    请输入回复: 'Yanıtınızı girin',
    启用状态: 'Etkinlik durumu',
    用户来源: 'Kullanıcı kaynağı',
    积分: 'Puan',
    请输入用户昵称: 'Kullanıcı takma adınızı girin',
    请选择用户角色: 'Kullanıcı rolünü seçin',
    未知功能: 'Bilinmeyen işlev',
    成员头像: 'Üye profil resmi',
    成员名称: 'Üye adı',
    成员角色: 'Üye rolü',
    管理产品: 'Ürün yönetimi',
    请输入团队名称: 'Takım adınızı girin',
    设备区域: 'Cihaz bölgesi',
    请选择设备区域: 'Cihaz bölgesini seçin',
    团队简介: 'Takım özeti',
    请输入团队简介: 'Takım özetinizi girin',
    团队头像: 'Takım profil resmi',
    HS编码: 'HS Kodu',
    // TODO: 2023-11-21
    "产品组成：产品A由B/C/D组成,组合的产品A在销售和库存环节跟其他产品一样。在箱单处理环节可以自动拆分": "Ürün bileşimi: Ürün A, B/C/D tarafından oluşturulur ve birleştirilen Ürün A, satış ve envanter süreçlerinde diğer ürünlerle aynı şekilde işlenir. Paketleme sürecinde otomatik olarak ayrılabilir.",
    "M会自动为您过滤": "M otomatik olarak sizin için filtreleyecektir.",
    "S后刷新项目": "S saniye sonra proje yenilenecektir.",
    "客户投诉": "Müşteri şikayeti",
    "配置项": "Yapılandırma öğesi",
    "login核心接口异常": "Giriş ana arabirim istisnası",
    "请检查返回JSON格式是否正确，是否正确返回": "Lütfen dönen JSON formatının doğru olup olmadığını ve doğru bir şekilde dönerse kontrol edin.",
    "getUserInfo核心接口异常": "getUserInfo ana arabirim istisnası",
    "请检查返回JSON格式是否正确": "Lütfen dönen JSON formatının doğru olup olmadığını kontrol edin.",
    "不含税": "Vergisiz",
    "产品质量": "Ürün kalitesi",
    "发货速度": "Sevkiyat hızı",
    "售后服务": "Satış sonrası hizmet",
    "客诉": "Müşteri şikayeti",
    "客诉标题": "Müşteri şikayeti başlığı",
    "请输入客诉标题": "Lütfen müşteri şikayeti başlığını girin",
    "客诉类型": "Müşteri şikayeti türü",
    "关联信息": "İlgili bilgi",
    "请选择关联信息": "Lütfen ilişkili bilgiyi seçin",
    "客诉原因": "Müşteri şikayet nedeni",
    "请输入客诉原因": "Lütfen müşteri şikayet nedenini girin",
    "valFun是一个函数": "valFun bir işlemdir",
    "产品组成": "Ürün bileşimi",
    "最近销售时间": "Son satış zamanı",
    "EXW价格": "EXW fiyatı",
    "FOB价格": "FOB fiyatı",
    "厂商税率": "Üretici vergi oranı",
    "最近采购时间": "Son satın alma zamanı",
    "采购次数": "Satın alma sıklığı",
    "产品供应商分布": "Ürün tedarikçi dağılımı",
    "OEM码": "OEM kodu",
    "附件信息": "Ek bilgi",
    "原因": "Neden",
    "URL地址": "URL adresi",
    // TODO: 2023-11-22
    "含税": "Vergi dahil",
    "请输入采购税率": "Lütfen satın alma vergi oranını girin",
    "是否含税": "Vergili mi?",
    "交货方式": "Nakliye ücreti",
    "请选择客诉类型": "Lütfen şikayet türünü seçin",
    "客诉信息": "Şikayet bilgisi",
    "请输入客户WhatsApp": "Lütfen müşteriye WhatsApp girin",
    "仅支持Xlsx,若为Xls文件请先在Excel中另存为Xlsx": "Sadece Xlsx destekleniyor. Eğer Xls dosyasıysa, lütfen önce Excel'de Xlsx olarak kaydet",
    // TODO: 2023-11-24
    "设为主图": "Ana resim olarak ayarla",
    "包邮": "Ücretsiz kargo",
    "不包邮": "Kargo ücretsiz değil",
    "邮费": "Kargo ücreti",
    "减少": "Azalt",
    "增加": "Artır",
    "产品图文": "Ürün resim ve metin",
    "图文列表": "Resim ve metin listesi",
    "图文生成": "Resim ve metin oluşturma",
    "金豆记录": "Altın fasulye kaydı",
    "是否包邮": "Ücretsiz kargo olup olmadığı",
    "抠图图集": "Kesme resim koleksiyonu",
    "金豆变更": "Altın fasulye değişikliği",
    "选择产品型号": "Ürün modeli seç",
    "预览图文": "Resim ve metni önizle",
    "生成PDF": "PDF oluştur",
    "当前只可选择生成三级分类产品，若需生成完整产品图文，请先完善三级分类": "Şu anda sadece üçüncü seviye kategorili ürünler seçilebilir, tam ürün resim ve metni oluşturmak için önce üçüncü seviye kategoriyi tamamlayın",
    "生成请求已提交": "Oluşturma isteği gönderildi",
    "可前往产品图文列表页查看进度": "İlerlemeyi görmek için ürün resim ve metin listesi sayfasına gidin",
    "返回上页": "Önceki sayfaya dön",
    "开始生成": "Oluşturmaya başla",
    "重新生成": "Yeniden oluştur",
    "前往列表页": "Liste sayfasına git",
    "金豆剩余": "Kalan altın fasulye",
    "消费类型": "Harcama türü",
    "消费金豆": "Altın fasulye harca",
    "消费前": "Harcamadan önce",
    "消费后": "Harcamadan sonra",
    "消费时间": "Harcama zamanı",
    "请选择消费类型": "Harcama türünü seçiniz",
    金豆使用规则: 'Golden Bean Usage Rules',
  },
}
