<template>
  <el-upload
    v-model:file-list="fileList"
    :accept="accept"
    :action="uploadFileUrl"
    :before-remove="beforeRemove"
    :before-upload="handleBeforeUpload"
    :data="extraParams"
    :drag="isDrag"
    :limit="limit"
    multiple
    name="filedata"
    :on-exceed="handleExceed"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :on-success="onSuccess"
    with-credentials
  >
    <slot>
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text" style="min-width: 300px">
        <em>{{ t('上传文件') }}</em>
      </div>
    </slot>
  </el-upload>
</template>
<script>
  import { computed, defineComponent, reactive, toRefs } from 'vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { UploadFilled } from '@element-plus/icons-vue'
  import { uploadFileUrl } from '@/config/index'
  import request from '@/utils/request'
  import { t } from '@/utils/i18n'

  export default defineComponent({
    name: 'FileUpload',
    components: {
      UploadFilled,
    },
    props: {
      module: { type: String, default: '/' },
      id: { type: String, default: '' },
      limit: { type: Number, default: 5 },
      modelValue: { type: Array, default: () => [] },
      accept: { type: String, default: '' },
      // 限制默认上传图片大小以KB为单位(默认为500M)
      limitSize: { type: [String, Number], default: 1024 * 500 },
      isDrag: { type: Boolean, default: true },
    },
    emits: ['on-success', 'update:modelValue'],
    setup(props, { emit }) {
      const state = reactive({
        fileName: '',
        fileList: computed({
          get: () => props.modelValue || '',
          set: (value) => {
            emit('update:modelValue', value)
          },
        }),
        // 额外的参数
        extraParams: {
          module: props.module,
          name: '',
          id: props.id,
        },
      })

      const handleRemove = (file, uploadFiles) => {
        console.log(file, uploadFiles)
      }

      const handlePreview = (uploadFile) => {
        console.log(uploadFile)
      }
      const onSuccess = (uploadFile, UploadFiles) => {
        console.log(uploadFile, UploadFiles)
        emit('on-success', UploadFiles)
      }

      const handleBeforeUpload = (file) => {
        state.extraParams.name = file.name
        const limitSize = parseInt(props.limitSize)
        const size = limitSize / 1024
        const isLt500M = file.size / 1024 < limitSize
        if (!isLt500M) {
          if (size > 1) {
            ElMessage.error(`${t('上传图片大小不能超过')} ${size}MB!`)
          } else {
            ElMessage.error(`${t('上传图片大小不能超过')} ${limitSize}KB!`)
          }
        }
        const result = isLt500M
        return result
      } 

      const handleExceed = (files, uploadFiles) => {
        ElMessage.warning(
          `${t('最多上传')}
            ${props.limit}${t('文件')},${t('您选择了')}${uploadFiles.length}${t(
            '个文件,请重新选择'
          )}`
        )
      }

      const beforeRemove = (uploadFile, uploadFiles) => {
        console.log('uploadFiles :>> ', uploadFiles)
        return ElMessageBox.confirm(
          `${t('你确定要删除')} ${uploadFile.name} ?`
        ).then(
          () => {
            request({
              url: `/file/delete`,
              method: 'post',
              data: {
                id: uploadFile.id,
              },
            })
            return true
          },
          () => false
        )
      }
      return {
        uploadFileUrl,
        UploadFilled,
        ...toRefs(state),
        handleRemove,
        handlePreview,
        handleExceed,
        beforeRemove,
        handleBeforeUpload,
        onSuccess,
        t,
      }
    },
  })
</script>
