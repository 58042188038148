<template>
  <el-drawer
    v-model="visible"
    destroy-on-close
    direction="ltr"
    :modal="false"
    modal-class="add-suppiler-modal-class"
    :show-close="true"
    size="100%"
    :with-header="false"
  >
    <div class="system-user-container">
      <base-table
        ref="refTable"
        :config="tableConfig"
        table-height="78vh"
        @selection-change="handleSelectionChange"
      >
        <template #header-button-before>
          <el-button
            class="mr10"
            :disabled="!selectList.length"
            size="default"
            type="primary"
            @click="handleAddBatch"
          >
            {{ t('批量添加') }}
          </el-button>
        </template>
        <template #code="{ scope }">
          <div>
            <div>
              {{ scope.code }}
            </div>
            <div>{{ scope.oem_code }}</div>
          </div>
        </template>
        <template #operation="{ scope }">
          <el-button
            :disabled="scope.added"
            size="default"
            type="text"
            @click="handleAddSupplierToProduct(scope)"
          >
            {{ scope.added ? t('已添加') : t('添加') }}
          </el-button>
        </template>
      </base-table>
      <el-button
        class="add-suppiler-close-btn"
        :icon="Close"
        link
        @click.stop="handleCloseDrawer"
      />
    </div>
  </el-drawer>
</template>

<script>
  import { defineComponent, reactive, ref, markRaw, toRefs } from 'vue'
  import { Close } from '@element-plus/icons-vue'

  import FileList from '@/views/publicModule/fileList.vue' // 附件
  import request from '@/utils/request'
  import { supplierAddDialog } from '@/utils/dialogBoxConfig'
  import { t } from '@/utils/i18n'
  const MODULE = 'supplier' // 模块

  // 表格数据
  const tableColumns = [
    { type: 'selection', width: 50, fixed: 'left' },
    {
      label: '名称',
      prop: 'title',
      align: 'left',
      // class: 'cp cb tdu',
      // drawerConfig: {
      //   // tabPaneName: 'productPanel',
      //   attrs: {
      //     size: '75%',
      //     withHeader: false,
      //     destroyOnClose: true,
      //   },
      //   api: {
      //     detail: `/${MODULE}/detail`,
      //     update: `/${MODULE}/edit`,
      //   },
      // },
    },
    {
      label: '编码',
      prop: 'code',
      align: 'left',
    },
    {
      width: 80,
      prop: 'operation',
      label: '操作',
      fixed: 'right',
    },
  ]

  export default defineComponent({
    name: 'SupplierDrawer',
    components: {},
    props: {
      module: {
        require: true,
        type: String,
        default: 'quote',
      },
      refId: {
        require: true,
        type: Number,
        default: 0,
      },
    },
    emits: ['add-success', 'close'],
    setup(props, { emit }) {
      const state = reactive({
        selectList: [],
      })
      let tableConfig = reactive({
        headerActions: {
          AddSupplier: {
            type: 'editBox',
          },
          list: ['AddSupplier'],
        },
        tableColumn: tableColumns,
        useField: false,
        tableListApi: `/${MODULE}/index`,
        tableUpdateApi: `/${MODULE}/edit`,
        tableDeleteApi: `/${MODULE}/delete`,
        tableExportApi: `/${MODULE}/export`,
        tableListParams: {
          sort: 'id',
          order: 'desc',
          __product_id: props.module === 'product' ? props.refId : '',
        },
        tableDeleteParams: {},
        tableButtonNum: 3,
        tableSearch: {
          formAttrs: {
            inline: true,
          },
          formList: [
            {
              type: 'input',
              key: 'search',
              value: '',
              labelAttrs: {
                label: '查询',
              },
              formAttrs: {
                type: 'text',
                placeholder: '',
                clearable: true,
              },
            },
            {
              type: 'selectServer',
              key: 'category_id',
              labelAttrs: {
                label: '分类',
              },
              formAttrs: {
                listApi: `/supplier-category/index`,
                valueType: 'number',
                multiple: false,
                placeholder: '请选择分类',
              },
            },
            {
              type: 'submit',
              submit: {
                title: '搜索',
                attrs: {
                  type: 'primary',
                },
              },
              reset: {
                title: '重置',
              },
              layoutAttrs: {
                span: 10,
              },
            },
          ],
        },
        tableDrawer: {
          api: {
            detail: `/${MODULE}/detail`,
          },
          productPanel: {
            tabsAttrs: {
              type: 'border-card',
            },
            activeName: 'first',
            tabPaneList: [
              {
                label: '附件',
                name: 'first',
                lazy: true,
                component: markRaw(FileList),
                params: {
                  module: 'supplier',
                },
              },
            ],
          },
        },
        editBox: supplierAddDialog(MODULE),
      })

      const visible = ref(false)
      const refTable = ref(null)

      const addSupplierToProduct = (ids) => {
        request({
          url: `supplier-product/add`,
          method: 'post',
          data: { product_id: props.refId, supplier_id: ids },
        }).then(() => {
          addSuccess()
        })
      }

      const addSuccess = () => {
        console.log('refTable.value', refTable.value)
        refTable.value.initSearch()
        emit('add-success')
      }

      const handleSelectionChange = (val) => {
        state.selectList = val
      }

      // 添加
      const handleAddSupplierToProduct = (item) => {
        if (!item.added) {
          addSupplierToProduct(item.id)
        }
      }
      // 批量
      const handleAddBatch = () => {
        const productIds = []
        state.selectList.forEach((item) => {
          if (!item.added) {
            productIds.push(item.id)
          }
        })
        if (productIds.length) {
          addSupplierToProduct(productIds)
        }
      }

      const handleCloseDrawer = () => {
        visible.value = false
        emit('close')
      }
      return {
        ...toRefs(state),
        refTable,
        Close,
        tableConfig,
        visible,
        handleSelectionChange,
        handleAddSupplierToProduct,
        handleAddBatch,
        handleCloseDrawer,
        t,
      }
    },
  })
</script>

<style lang="scss">
  .add-suppiler-close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 10;
    font-size: 20px !important;
    font-weight: lighter;
  }
  .add-suppiler-modal-class {
    top: 0;
    left: 0;
    bottom: 0;
    width: 38%;
  }
</style>
