/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import Layout from '@/vab/layouts'
import { authentication, isHashRouterMode, publicPath } from '@/config'

export const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: {
      noKeepAlive: true,
      hidden: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: 'Page403',
    component: () => import('@/views/403'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: 'Page404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/stripePayResult',
    name: 'StripePayResult',
    component: () => import('@/views/externalPage/stripePayResult.vue'),
    meta: {
      noKeepAlive: true,
      hidden: true,
    },
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    meta: {
      title: '首页',
      breadcrumbHidden: true,
    },
    children: [

      {
        path: 'operating',
        name: 'OperatingPage',
        component: () => import('@/views/index/operating'),
        meta: {
          title: '运营报表',
          // noClosable: true,
        },
      },
      {
        path: 'work',
        name: 'Work',
        component: () => import('@/views/index/workbench'),
        meta: {
          title: '我的待办',
          noClosable: true,
        },
      },
      {
        path: 'workdone',
        name: 'WorkDone',
        component: () => import('@/views/index/workDone'),
        meta: {
          title: '已办事项',
          // noClosable: true,
        },
      },
      {
        path: 'business-analysis',
        name: 'BusinessAnalysis',
        component: () => import('@/views/index/businessAnalysis'),
        meta: {
          title: '经营分析',
        },
      },
      {
        path: 'decision-aids',
        name: 'DecisionAids',
        component: () => import('@/views/customs/decisionAids'),
        meta: {
          title: '决策辅助',
        },
      },
      {
        path: 'monitor',
        name: 'Monitor',
        component: () => import('@/views/customs/monitor'),
        meta: {
          title: '经营分析',
        },
      },
    ],
  },
  // 产品管理
  {
    path: '/product',
    name: 'ProductManage',
    component: Layout,
    meta: {
      title: '产品',
    },
    children: [
      {
        path: 'statistics',
        name: 'OperatingPage',
        component: () => import('@/views/index/operating'),
        meta: {
          title: '产品面板',
          // noClosable: true,
        },
      },
      {
        path: 'productList',
        name: 'ProductList',
        component: () => import('@/views/product/productList'),
        meta: {
          title: '产品列表',
        },
      },
      {
        path: 'productShopList',
        name: 'ProductShopList',
        component: () => import('@/views/product/thirdShopProductList.vue'),
        meta: {
          title: '电商产品',
        },
      },
      {
        path: 'productCategory',
        name: 'ProductCategoryList',
        component: () => import('@/views/product/productCategory.vue'),
        meta: {
          title: '产品分类',
        },
      },
      {
        path: 'productPackageList',
        name: 'ProductPackageList',
        component: () => import('@/views/product/productPackageList'),
        meta: {
          title: '配套产品',
        },
      },
      {
        path: 'graphics',
        name: 'graphics',
        component: () => import('@/views/product/graphics'),
        meta: {
          title: '产品图文',
        },
        children: [
          {
            path: 'list',
            name: 'ProductGraphicsList',
            component: () => import('@/views/product/graphics/list'),
            meta: {
              title: '图文列表',
            },
          },
          {
            path: 'generate',
            name: 'ProductGraphicsGenerate',
            component: () => import('@/views/product/graphics/index'),
            meta: {
              title: '图文生成',
            },
          },
          {
            path: 'quotation',
            name: 'ProductGraphicsQuotation',
            component: () => import('@/views/product/quotation/index'),
            meta: {
              title: '报价单表',
            },
          },
        ],
      },
      {
        path: 'atlas',
        name: 'ProductAtlasList',
        component: () => import('@/views/product/productAtlas/index.vue'),
        meta: {
          title: '产品图册',
        },
      },
      {
        path: 'carton',
        name: 'ProductCartonList',
        component: () => import('@/views/product/cartonSize/index.vue'),
        meta: {
          title: '产品箱规',
        },
      },
      {
        path: 'comment',
        name: 'ProductCommentList',
        component: () => import('@/views/product/commentList.vue'),
        meta: {
          title: '产品评论',
        },
      },
    ],
  },
  // 采购
  {
    path: '/purchase',
    name: 'Purchase',
    component: Layout,
    meta: {
      title: '采购',
    },
    children: [
      {
        path: 'plan',
        name: 'PurchasePlanList',
        component: () => import('@/views/purchase/purchasePlanList'),
        meta: {
          title: '采购计划',
        },
      },
      {
        path: 'purchaseOrder',
        name: 'PurchaseOrderList',
        component: () => import('@/views/purchase/purchaseOrderList'),
        meta: {
          title: '采购订单',
        },
      },
      {
        path: 'furnitureOrder',
        name: 'PurchaseFurnitureOrder',
        component: () => import('@/views/purchase/purchaseFurnitureOrder'),
        meta: {
          title: '采购订单',
        },
      },
      {
        path: 'supplierList',
        name: 'SupplierList',
        component: () => import('@/views/purchase/supplierList'),
        meta: {
          title: '供应管理',
        },
      },
    ],
  },
  // 客户
  {
    path: '/customer',
    name: 'Customer',
    component: Layout,
    meta: {
      title: '客户',
    },
    children: [
      {
        path: 'clue-private',
        name: 'CustomerClueListPrivate',
        component: () => import('@/views/customer/customerClueList'),
        meta: {
          title: '私有线索',
        },
      },
      {
        path: 'clue-public',
        name: 'CustomerClueListPublic',
        component: () => import('@/views/customer/customerClueList'),
        meta: {
          title: '公海线索',
        },
      },
      {
        path: 'list',
        name: 'CustomerList',
        component: () => import('@/views/customer/customerList'),
        meta: {
          title: '客户管理',
        },
      },
    ],
  },
  // 销售
  {
    path: '/sale',
    name: 'Sale',
    component: Layout,
    meta: {
      title: '销售',
    },
    children: [
      {
        path: 'statistics',
        name: 'IndexPage',
        component: () => import('@/views/index'),
        meta: {
          title: '销售面板',
          // noClosable: true,
        },
      },
      {
        path: 'businessOpportunityList',
        name: 'BusinessOpportunityList',
        component: () => import('@/views/sale/businessOpportunityList'),
        meta: {
          title: '商机管理',
        },
      },
      {
        path: 'quoteList',
        name: 'QuoteList',
        component: () => import('@/views/sale/quoteList'),
        meta: {
          title: '对外报价',
        },
      },
      {
        path: 'saleOrder',
        name: 'SaleOrderList',
        component: () => import('@/views/sale/saleOrderList'),
        meta: {
          title: '销售订单',
        },
      },
      {
        path: 'dyOrder',
        name: 'DyOrderList',
        component: () => import('@/views/sale/dyOrderList'),
        meta: {
          title: '抖音订单',
        },
      },
      {
        path: 'feedback',
        name: 'CustomerFeedbackList',
        component: () => import('@/views/sale/feedbackList'),
        meta: {
          title: '客户投诉',
        },
      },
      {
        path: 'deleteOrder',
        name: 'DeleteOrderList',
        component: () => import('@/views/sale/deleteOrderList'),
        meta: {
          title: '订单回收',
        },
      },
    ],
  },
  // 营销
  {
    path: '/marketing',
    name: 'Marketing',
    component: Layout,
    meta: {
      title: '营销',
    },
    children: [
      {
        path: 'productChannel',
        name: 'ProductChannelList',
        component: () => import('@/views/marketing/productChannelList.vue'),
        meta: {
          hidden: true,
          title: '我方渠道',
        },
      },
      {
        path: 'rivalchannel',
        name: 'ProductChannelRiavlList',
        component: () => import('@/views/marketing/productChannelRiavlList.vue'),
        meta: {
          hidden: true,
          title: '竞对渠道',
        },
      },
      {
        path: 'channel',
        name: 'ChannelList',
        component: () => import('@/views/marketing/channelList'),
        meta: {
          title: '我方店铺',
        },
      },
      {
        path: 'channelCompete',
        name: 'ChannelCompeteList',
        component: () => import('@/views/marketing/channelCompeteList'),
        meta: {
          title: '竞对店铺',
        },
      },
      {
        path: 'channelType',
        name: 'ChannelTypeList',
        component: () => import('@/views/marketing/channelType'),
        meta: {
          title: '渠道类型',
        },
      },
    ],
  },
  // 财务管理
  {
    path: '/finance',
    name: 'Finance',
    component: Layout,
    meta: {
      title: '财务',
    },
    children: [
      {
        path: 'plan',
        name: 'FinancePlanList',
        component: () => import('@/views/finance/planList'),
        meta: {
          title: '回款计划',
        },
      },
      {
        path: 'receivables',
        name: 'FinanceReceivablesDetail',
        component: () => import('@/views/finance/receivablesDetail'),
        meta: {
          hidden: true,
          title: '回款明细',
        },
      },
      {
        path: 'paymentPlan',
        name: 'FinancePaymentPlanList',
        component: () => import('@/views/finance/paymentPlan'),
        meta: {
          title: '收款计划',
        },
      },
      {
        path: 'paymentDetail',
        name: 'FinancePaymentDetail',
        component: () => import('@/views/finance/paymentDetail'),
        meta: {
          title: '收款明细',
        },
      },
      {
        path: 'invoicePlan',
        name: 'InvoicePlan',
        component: () => import('@/views/finance/invoicePlan'),
        meta: {
          title: '开票计划',
        },
      },
      {
        path: 'invoice',
        name: 'InvoiceDetail',
        component: () => import('@/views/finance/invoiceDetail'),
        meta: {
          title: '开票详情',
        },
      },
      {
        path: 'reconciliation',
        name: 'Reconciliation',
        component: () => import('@/views/finance/reconciliation'),
        meta: {
          title: '对账列表',
        },
      },
      {
        path: 'reconciliation-detail',
        name: 'ReconciliationDetail',
        component: () => import('@/views/finance/reconciliationDetail'),
        meta: {
          title: '账单明细',
        },
      },
      {
        path: 'recharge',
        name: 'rechargeList',
        component: () => import('@/views/finance/rechargeList'),
        meta: {
          title: '充值记录',
        },
      },
      {
        path: 'other-expense',
        name: 'OtherExpenseList',
        component: () => import('@/views/finance/otherExpense'),
        meta: {
          title: '其他费用',
        },
      },
    ],
  },
  // 仓库管理
  {
    path: '/repository',
    name: 'Repository',
    component: Layout,
    meta: {
      title: '仓库',
    },
    children: [
      {
        path: 'deliveryPlan',
        name: 'DeliveryPlanList',
        component: () => import('@/views/repository/deliveryPlanList'),
        meta: {
          title: '发货计划',
        },
      },
      {
        path: 'deliveryOrder',
        name: 'DeliveryFurnitureOrderList',
        component: () => import('@/views/repository/furnitureTrade/deliveryOrderList'),
        meta: {
          title: '发货单(家具)',
        },
      },
      {
        path: 'deliveryGoods',
        name: 'DeliveryGoodsList',
        component: () => import('@/views/repository/furnitureTrade/deliverGoods.vue'),
        meta: {
          title: '派货行程(家具)',
        },
      },
      {
        path: 'todayPlan',
        name: 'TodayPlanList',
        component: () => import('@/views/repository/todayPlanList'),
        meta: {
          title: '今日计划',
        },
      },
      {
        path: 'supplierDelivery',
        name: 'SupplierDeliveryList',
        component: () => import('@/views/repository/supplier/supplierDeliveryList.vue'),
        meta: {
          title: '发货单',
        },
      },
      {
        path: 'receiveDelivery',
        name: 'ReceiveDeliveryList',
        component: () => import('@/views/repository/receiveSupplier/index.vue'),
        meta: {
          title: '收货单',
        },
      },
      {
        path: 'stockOut',
        name: 'StockOutList',
        component: () => import('@/views/repository/supplier/stockOutList.vue'),
        meta: {
          title: '商品代发',
        },
      },
      {
        path: 'salesReturn',
        name: 'SalesReturnList',
        component: () => import('@/views/repository/supplier/salesReturnList.vue'),
        meta: {
          title: '商品退货',
        },
      },
      {
        path: 'salesClaim',
        name: 'SalesClaimList',
        component: () => import('@/views/repository/supplier/salesClaimList.vue'),
        meta: {
          title: '商品索赔',
        },
      },
      {
        path: 'repositoryRecord',
        name: 'repositoryRecordList',
        component: () => import('@/views/repository/repositoryRecord'),
        meta: {
          hidden: true,
          title: '出入库记录',
        },
      },
      {
        path: 'outin',
        name: 'OutInRecord',
        component: () => import('@/views/repository/furnitureTrade/outInRecord.vue'),
        meta: {
          hidden: true,
          title: '出入库记录',
        },
      },
      {
        path: 'repositoryInventory',
        name: 'repositoryInventory',
        component: () => import('@/views/repository/repositoryInventory'),
        meta: {
          hidden: true,
          title: '库存盘点',
        },
      },
      {
        path: 'repositoryList',
        name: 'RepositoryListPage',
        component: () => import('@/views/repository/repositoryList'),
        meta: {
          title: '仓库管理',
        },
      },
      {
        path: 'reportForms',
        name: 'ReportForms',
        component: Layout,
        meta: {
          title: '库存报表',
        },
        children: [
          {
            path: 'surplus',
            name: 'RepositorySurplus',
            component: () =>
              import('@/views/repository/reportForms/surplusList.vue'),
            meta: {
              title: '库存余额',
            },
          },
          {
            path: 'balance',
            name: 'StockBalanceList',
            component: () =>
              import('@/views/repository/furnitureTrade/stockBalance.vue'),
            meta: {
              title: '库存余额(家具)',
            },
          },
          {
            path: 'detailList',
            name: 'ReceiveDetailList',
            component: () =>
              import('@/views/repository/reportForms/detailList.vue'),
            meta: {
              title: '收发明细',
            },
          },
          {
            path: 'collectList',
            name: 'ReceiveCollectList',
            component: () =>
              import('@/views/repository/reportForms/collectList.vue'),
            meta: {
              title: '收发汇总',
            },
          },
        ],
      },
      {
        path: 'record',
        name: 'recordList',
        component: Layout,
        meta: {
          title: '单据列表',
        },
        children: [
          {
            path: 'storage',
            name: 'InboundOrderList',
            component: () =>
              import('@/views/repository/receiptList/InboundOrderList.vue'),
            meta: {
              title: '产品入库',
            },
          },
          {
            path: 'out',
            name: 'OutboundOrderList',
            component: () =>
              import('@/views/repository/receiptList/OutboundOrderList.vue'),
            meta: {
              title: '产品出库',
            },
          },
          {
            path: 'inventory',
            name: 'RepositoryInventory',
            component: () =>
              import('@/views/repository/repositoryInventory.vue'),
            meta: {
              hidden: true,
              title: '库存盘点',
            },
          },
          {
            path: 'transfer',
            name: 'RepositoryTransfer',
            component: () =>
              import('@/views/repository/receiptList/RepositoryTransfer.vue'),
            meta: {
              hidden: true,
              title: '产品调拨',
            },
          },
          {
            path: 'assemble',
            name: 'RepositoryAssemble',
            component: () =>
              import('@/views/repository/receiptList/RepositoryAssemble.vue'),
            meta: {
              hidden: true,
              title: '产品组装',
            },
          },
          {
            path: 'disassemble',
            name: 'RepositoryDisAssemble',
            component: () =>
              import(
                '@/views/repository/receiptList/RepositoryDisAssemble.vue'
              ),
            meta: {
              hidden: true,
              title: '产品拆卸',
            },
          },
        ],
      },
      {
        path: 'express',
        name: 'ExpressDeliveryList',
        component: () => import('@/views/repository/expressDelivery/index.vue'),
        meta: {
          hidden: true,
          title: '快递寄件',
        },
      },
      {
        path: 'rates',
        name: 'StorageRates',
        component: () => import('@/views/repository/storageRates.vue'),
        meta: {
          hidden: true,
          title: '收费标准',
        },
      },
    ],
  },
  // 绩效
  {
    path: '/performance',
    name: 'Performance',
    component: Layout,
    meta: {
      title: '绩效',
    },
    children: [
      {
        path: 'myList',
        name: 'MyPerformanceLog',
        component: () => import('@/views/performance/myList'),
        meta: {
          title: '我的绩效',
        },
      },
      {
        path: 'teamList',
        name: 'TeamPerformanceLog',
        component: () => import('@/views/performance/teamList'),
        meta: {
          title: '团队绩效',
        },
      },
      {
        path: 'checkList',
        name: 'CheckPerformanceLog',
        component: () => import('@/views/performance/checkList'),
        meta: {
          title: '绩效核定',
        },
      },
      {
        path: 'setting',
        name: 'PerformanceSetting',
        component: () => import('@/views/performance/setting'),
        meta: {
          title: '绩效规则',
        },
      },
    ],
  },
  // 任务
  {
    path: '/task',
    name: 'TaskIndex',
    component: Layout,
    meta: {
      title: '任务',
    },
    children: [
      {
        path: 'list',
        name: 'TaskList',
        component: () => import('@/views/task/list'),
        meta: {
          title: '任务列表',
        },
      },
    ],
  },
  // 工厂
  {
    path: '/factory',
    name: 'Factory',
    component: Layout,
    meta: {
      title: '工厂',
    },
    children: [
      {
        path: 'productionPlan',
        name: 'ProductionPlanList',
        component: () => import('@/views/factory/production/planList.vue'),
        meta: {
          title: '生产计划',
        },
      },
      {
        path: 'productionOrder',
        name: 'ProductionOrderList',
        component: () => import('@/views/factory/production/orderList.vue'),
        meta: {
          title: '生产工单',
        },
      },
      {
        path: 'entrust',
        name: 'Entrust',
        meta: {
          hidden: true,
          title: '委外管理',
        },
        children: [
          {
            path: 'index',
            name: 'EntrustList',
            component: () => import('@/views/factory/entrust/orderList.vue'),
            meta: {
              title: '委外订单',
            },
          },
          {
            path: 'order',
            name: 'EntrustOrderList',
            component: () => import('@/views/factory/entrust/orderList.vue'),
            meta: {
              title: '整单委外',
            },
          },
          {
            path: 'process',
            name: 'EntrustProcessList',
            component: () => import('@/views/factory/entrust/orderList.vue'),
            meta: {
              title: '工序委外',
            },
          },
        ]
      },
      {
        path: 'execute',
        name: 'Execute',
        meta: {
          hidden: true,
          title: '生产执行',
        },
        children: [
          {
            path: 'process',
            name: 'ProductExecuteProcess',
            component: () => import('@/views/factory/productionExecute/processList.vue'),
            meta: {
              hidden: true,
              title: '生产工序',
            },
          },
          {
            path: 'workticket',
            name: 'ProductWorkTicketList',
            component: () => import('@/views/factory/productionExecute/workTicketList.vue'),
            meta: {
              hidden: true,
              title: '报工单',
            },
          },
        ]
      },
      {
        path: 'materiel',
        name: 'Materiel',
        meta: {
          hidden: true,
          title: '物料管理',
        },
        children: [
          {
            path: 'picking',
            name: 'MaterielPickingList',
            component: () => import('@/views/factory/materiel/pickingList.vue'),
            meta: {
              hidden: true,
              title: '领料单',
            },
          },
          {
            path: 'return',
            name: 'MaterielReturnList',
            component: () => import('@/views/factory/materiel/returnList.vue'),
            meta: {
              hidden: true,
              title: '退料单',
            },
          },
        ]
      },
      {
        path: 'qc',
        name: 'QC',
        meta: {
          hidden: true,
          title: '质量管理',
        },
        children: [
          {
            path: 'process',
            name: 'QCProcess',
            component: () => import('@/views/factory/qualityControl/detailList.vue'),
            meta: {
              hidden: true,
              title: '工序质检',
            },
          },
          {
            path: 'entrust',
            name: 'QCEntrustList',
            component: () => import('@/views/factory/qualityControl/index.vue'),
            meta: {
              hidden: true,
              title: '委外质检',
            },
          },
        ]
      },
      {
        path: 'bom',
        name: 'Bom',
        meta: {
          hidden: true,
          title: 'BOM管理',
        },
        children: [
          {
            path: 'index',
            name: 'ProductBom',
            component: () => import('@/views/factory/bom/bom.vue'),
            meta: {
              hidden: true,
              title: '产品BOM',
            },
          },
          {
            path: 'list',
            name: 'ProductBomList',
            component: () => import('@/views/factory/bom/bomList.vue'),
            meta: {
              hidden: true,
              title: 'BOM清单',
            },
          },
        ]
      },
      {
        path: 'technology',
        name: 'Technology',
        meta: {
          hidden: true,
          title: '工序工艺',
        },
        children: [
          {
            path: 'index',
            name: 'ProductTechnologyIndex',
            component: () => import('@/views/factory/technology/index.vue'),
            meta: {
              hidden: true,
              title: '工艺',
            },
          },
          {
            path: 'process',
            name: 'ProductTechnologyProcess',
            component: () => import('@/views/factory/technology/process.vue'),
            meta: {
              hidden: true,
              title: '工序',
            },
          },
        ]
      },
      {
        path: 'product-materiel',
        name: 'ProductMaterielList',
        component: () => import('@/views/product/productList.vue'),
        meta: {
          title: '物料管理',
        },
      },
    ],
  },
  // 设备
  {
    path: '/device',
    name: 'Device',
    component: Layout,
    meta: {
      title: '设备',
    },
    children: [
      {
        path: 'index',
        name: 'DeviceList',
        component: () => import('@/views/device/deviceList.vue'),
        meta: {
          title: '设备列表',
        },
      },
      {
        path: 'type',
        name: 'DeviceTypeList',
        component: () => import('@/views/device/deviceType.vue'),
        meta: {
          title: '设备类型',
        },
      },
      {
        path: 'supplier',
        name: 'MouldSupplierList',
        component: () => import('@/views/device/mouldSupplier.vue'),
        meta: {
          title: '服务商',
        },
      },
      {
        path: 'group',
        name: 'MouldGroupList',
        component: () => import('@/views/device/mouldGroupList.vue'),
        meta: {
          title: '模具组',
        },
      },
    ],
  },
  // 电商管理
  {
    path: '/shop',
    name: 'Shop',
    component: Layout,
    meta: {
      title: '电商',
      // guard: ['Admin'],
    },
    children: [
      {
        path: 'index',
        name: 'IndexSetting',
        component: () => import('@/views/shop/index/index.vue'),
        meta: {
          title: '首页设置',
        },
      },
      {
        path: 'product',
        name: 'ShopProductList',
        component: () => import('@/views/product/productList'),
        meta: {
          title: '商品',
        },
      },
      {
        path: 'banner',
        name: 'BannerList',
        component: () => import('@/views/shop/bannerList.vue'),
        meta: {
          title: '轮播图',
        },
      },
      {
        path: 'article',
        name: 'ArticleList',
        component: () => import('@/views/shop/article/index.vue'),
        meta: {
          title: '文章管理',
        },
      },
      {
        path: 'setting',
        name: 'ShopSetting',
        component: () => import('@/views/shop/setting/index.vue'),
        meta: {
          title: '设置',
        },
      },
    ],
  },
  // 电商大市场管理
  {
    path: '/market',
    name: 'ShopMarket',
    component: Layout,
    meta: {
      title: '大市场',
      // guard: ['Admin'],
    },
    children: [
      {
        path: 'product',
        name: 'MarketGoodsList',
        component: () => import('@/views/shopMarket/goodsList.vue'),
        meta: {
          title: '在售商品',
        },
      },
      {
        path: 'grant-product',
        name: 'GrantGoodsList',
        component: () => import('@/views/shopMarket/grantGoodsList.vue'),
        meta: {
          title: '授权产品',
        },
      },
    ],
  },
  // 配置管理
  {
    path: '/setting',
    name: 'PersonnelManagement',
    component: Layout,
    hidden: true,
    meta: {
      title: '配置',
      // guard: ['Admin'],
    },
    children: [
      {
        path: 'personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/setting/personalCenter'),
        meta: {
          title: '个人中心',
        },
      },
      {
        path: 'companyInfo',
        name: 'CompanyInfo',
        component: () => import('@/views/setting/company/index'),
        meta: {
          title: '公司信息',
        },
      },
      {
        path: 'customerSetting',
        name: 'CustomerSetting',
        component: () => import('@/views/setting/customer/index'),
        meta: {
          title: '销售配置',
        },
      },
      {
        path: 'productSetting',
        name: 'ProductSetting',
        component: () => import('@/views/setting/product/index'),
        meta: {
          title: '产品配置',
        },
      },
      {
        path: 'productMaterial',
        name: 'ProductMaterial',
        component: () => import('@/views/setting/productMaterial/index'),
        meta: {
          title: '产品素材',
        },
      },
      {
        path: 'templateSetting',
        name: 'TemplateSetting',
        component: () => import('@/views/setting/templateSetting/index'),
        meta: {
          title: '模版管理',
        },
      },
      {
        path: 'extraFieldSetting',
        name: 'ExtraFieldSetting',
        component: () => import('@/views/setting/extraFieldSetting/index'),
        meta: {
          title: '扩展字段',
        },
      },
      {
        path: 'sysSetting',
        name: 'SysSetting',
        component: () => import('@/views/setting/sysSetting'),
        meta: {
          title: '系统设置',
        },
      },
      {
        path: 'third',
        name: 'ThirdSetting',
        component: () => import('@/views/setting/third/index.vue'),
        meta: {
          title: '三方应用',
        },
      },
      {
        path: 'hardware',
        name: 'HardwareSetting',
        component: () => import('@/views/setting/hardware/index.vue'),
        meta: {
          title: '硬件配置',
        },
      },
      {
        path: 'qa',
        name: 'QAList',
        component: () => import('@/views/setting/qa/index.vue'),
        meta: {
          title: '常见问题',
        },
      },
    ],
  },
  // 记录
  {
    path: '/logs',
    name: 'LogsManagement',
    component: Layout,
    hidden: true,
    meta: {
      title: '记录',
      // guard: ['Admin'],
    },
    children: [
      {
        path: 'expenseLog',
        name: 'ExpenseLog',
        component: () => import('@/views/logsList/expenseLog'),
        meta: {
          title: '费用记录',
        },
      },
      {
        path: 'storageVolume',
        name: 'StorageVolume',
        component: () => import('@/views/logsList/storageVolume/index'),
        meta: {
          title: '仓储体积',
        },
      },
      {
        path: 'goldenLog',
        name: 'GoldenLog',
        component: () => import('@/views/logsList/goldenLog'),
        meta: {
          title: '金豆记录',
        },
      },
      {
        path: 'export',
        name: 'ExportTask',
        component: () => import('@/views/logsList/exportHistory/index.vue'),
        meta: {
          title: '导出记录',
        },
      },
      {
        path: 'adminLog',
        name: 'AdminLog',
        component: () => import('@/views/logsList/adminLog'),
        meta: {
          title: '操作日志',
        },
      },
    ],
  },
  {
    path: '/system',
    name: 'System',
    component: Layout,
    hidden: true,
    meta: {
      title: '系统',
      // guard: ['Admin'],
    },
    children: [
      {
        path: 'companyList',
        name: 'CompanyList',
        component: () => import('@/views/setting/company/list'),
        meta: {
          title: '公司列表',
        },
      },
      {
        path: 'adminList',
        name: 'AdminManagement',
        component: () => import('@/views/setting/admin/index'),
        meta: {
          title: '用户列表',
        },
      },
      {
        path: 'menuManagement',
        name: 'MenuManagement',
        component: () => import('@/views/setting/menuManagement/index'),
        meta: {
          title: '权限管理',
        },
      },
      {
        path: 'industry',
        name: 'IndustryManagement',
        component: () => import('@/views/setting/industry/index'),
        meta: {
          title: '行业管理',
        },
      },
      {
        path: 'configField',
        name: 'ConfigFieldList',
        component: () => import('@/views/setting/configField/index'),
        meta: {
          title: '配置项',
        },
      },
    ],
  },
  {
    path: '/resource',
    name: 'Resource',
    component: Layout,
    hidden: true,
    meta: {
      title: '资源',
    },
    children: [
      {
        path: 'store',
        name: 'Store',
        component: () => import('@/views/resource/store'),
        meta: {
          title: '店铺选址',
        },
      },
      {
        path: 'tutorials',
        name: 'Tutorials',
        component: () => import('@/views/resource/tutorials'),
        meta: {
          title: '教程管理',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    name: 'Page404',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter({
  history: isHashRouterMode
    ? createWebHashHistory(publicPath)
    : createWebHistory(publicPath),
  routes: constantRoutes,
})

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

function addRouter(routes) {
  routes.forEach((route) => {
    if (!router.hasRoute(route.name)) router.addRoute(route)
    if (route.children) addRouter(route.children)
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.getRoutes().forEach((route) => {
    const routeName = route.name
    router.hasRoute(routeName) && router.removeRoute(routeName)
  })
  addRouter(routes)
}

export function setupRouter(app) {
  if (authentication === 'intelligence') addRouter(asyncRoutes)
  app.use(router)
  return router
}

export default router
